<div class="toaster_modal_response">    
    <div class="email_otp">
        <div class="otp_icon">
            <div *ngIf="status; else failed">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="170" height="124.535" viewBox="0 0 170 124.535"><defs><style>.a,.b,.c,.d,.f,.g,.i{fill:none;}.a{stroke:#afbefd;}.a,.b,.c,.g{stroke-linecap:round;}.a,.b,.c{stroke-width:3px;}.b{stroke:#ffd29d;}.c{stroke:#fdcc92;}.d{stroke:#d8dfe6;}.d,.f{stroke-width:2px;}.e{fill:#fff;}.f{stroke:#ff4545;opacity:0.3;}.g{stroke:#25b24a;stroke-width:8px;}.h{stroke:none;}.j{filter:url(#a);}</style><filter id="a" x="35" y="23.535" width="100" height="101" filterUnits="userSpaceOnUse"><feOffset dy="3" input="SourceAlpha"/><feGaussianBlur stdDeviation="3" result="b"/><feFlood flood-color="#d6e9f5"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g transform="translate(-0.251 1.5)"><g transform="translate(143.607 99.642)"><path class="a" d="M21210.631-1061.971v9.924" transform="translate(-21205.668 1061.971)"/><path class="a" d="M0,0V9.924" transform="translate(9.924 4.962) rotate(90)"/></g><g transform="translate(43.909 0)"><path class="b" d="M21210.631-1061.971v9.924" transform="translate(-21205.668 1061.971)"/><path class="c" d="M0,0V9.924" transform="translate(9.924 4.962) rotate(90)"/></g><g class="d" transform="translate(0.251 52.035)"><ellipse class="h" cx="6.5" cy="7" rx="6.5" ry="7"/><ellipse class="i" cx="6.5" cy="7" rx="5.5" ry="6"/></g><g class="j" transform="matrix(1, 0, 0, 1, 0.25, -1.5)"><rect class="e" width="82" height="83" rx="41" transform="translate(44 29.54)"/></g><g class="f" transform="translate(156.251 52.035)"><ellipse class="h" cx="7" cy="7.5" rx="7" ry="7.5"/><ellipse class="i" cx="7" cy="7.5" rx="6" ry="6.5"/></g><g transform="translate(68.084 61.55)"><path class="g" d="M373.818,163.936l10.923,11.216" transform="translate(-373.818 -155.349)"/><path class="g" d="M0,0,20.877,20.788" transform="translate(12.064 20.877) rotate(-90)"/></g></g></svg>
                <h2 [ngClass]="{'header': saveSuccess}">{{saveSuccess? headerTxt : 'success' | translate}}</h2>
                <h4>{{responseMsg}}</h4>
                <div class="font-12" *ngIf="isIssuance">{{responseMsgIssuance}}</div>
                <p *ngIf="saveSuccess">{{renewalResponseMsg1}} <br> {{renewalResponseMsg2}}</p>
            </div>
            <ng-template #failed>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="170" height="124.535" viewBox="0 0 170 124.535"><defs><style>.a,.b,.c,.d,.f,.i{fill:none;}.a{stroke:#afbefd;}.a,.b,.c{stroke-linecap:round;stroke-width:3px;}.b{stroke:#ffd29d;}.c{stroke:#fdcc92;}.d{stroke:#d8dfe6;}.d,.f{stroke-width:2px;}.e{fill:#fff;}.f{stroke:#ff4545;opacity:0.3;}.g{fill:#ff2747;stroke:#ff2747;}.h{stroke:none;}.j{filter:url(#a);}</style><filter id="a" x="35" y="23.535" width="100" height="101" filterUnits="userSpaceOnUse"><feOffset dy="3" input="SourceAlpha"/><feGaussianBlur stdDeviation="3" result="b"/><feFlood flood-color="#d6e9f5"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g transform="translate(-0.251 1.5)"><g transform="translate(143.607 99.642)"><path class="a" d="M21210.631-1061.971v9.924" transform="translate(-21205.668 1061.971)"/><path class="a" d="M0,0V9.924" transform="translate(9.924 4.962) rotate(90)"/></g><g transform="translate(43.909 0)"><path class="b" d="M21210.631-1061.971v9.924" transform="translate(-21205.668 1061.971)"/><path class="c" d="M0,0V9.924" transform="translate(9.924 4.962) rotate(90)"/></g><g class="d" transform="translate(0.251 52.035)"><ellipse class="h" cx="6.5" cy="7" rx="6.5" ry="7"/><ellipse class="i" cx="6.5" cy="7" rx="5.5" ry="6"/></g><g class="j" transform="matrix(1, 0, 0, 1, 0.25, -1.5)"><rect class="e" width="82" height="83" rx="41" transform="translate(44 29.54)"/></g><g class="f" transform="translate(156.251 52.035)"><ellipse class="h" cx="7" cy="7.5" rx="7" ry="7.5"/><ellipse class="i" cx="7" cy="7.5" rx="6" ry="6.5"/></g><path class="g" d="M38.673,22.7H27.26V11.283a2.283,2.283,0,1,0-4.565,0V22.7H11.283a2.283,2.283,0,1,0,0,4.565H22.7V38.673a2.283,2.283,0,1,0,4.565,0V27.26H38.673a2.283,2.283,0,1,0,0-4.565Z" transform="translate(85.017 105.125) rotate(-135)"/></g></svg>
                <h2 class="error">{{'error' | translate}}</h2>
                <h4>{{responseMsg}}</h4>
            </ng-template>
        </div>
        <div class="otp_box">
            <div class="otp_links" [ngClass]="{'close-button': saveSuccess}">
                <button type="button" class="secondary_button mt-2" (click)="close()">{{saveSuccess? closeTxt : 'customer.makePaymentPage.continue' | translate}}</button>
            </div>            
        </div>
    </div>
</div>
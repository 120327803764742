<app-loader *ngIf="showLoader"></app-loader>
<form autocomplete="off">
    <div class="row">
        <div class="col-xl-3 col-sm-3 pl-0" *ngFor="let displaycolor of colors; let i =index;">
            <div class="radio_custom flt-rt"
                *ngIf="displaycolor?.jpColorDisplayName === colorSelected?.jpColorDisplayName">
                <input id="radio{{i}}" name="packagePlan" type="radio" (click)="colorSelection(displaycolor)"
                    [value]="displaycolor.jpColorDisplayName"
                    [checked]="displaycolor?.jpColorDisplayName === colorSelected?.jpColorDisplayName" />
                <label for="radio{{i}}"></label>
            </div>
            <button
                [ngStyle]="{
                'background':displaycolor.backgroundColor,'color': displaycolor.textColor,'border':displaycolor.borderColor, 'text-shadow': '0px 2px 3px '+displaycolor.textShadowColor }"
                class="button-palette"
                [ngClass]="{'selected-palette':displaycolor?.jpColorDisplayName === colorSelected?.jpColorDisplayName}"
                (click)="colorSelection(displaycolor)">{{ currentLang === 'jp' ? displaycolor?.jpColorDisplayName : displaycolor?.enColorDisplayName}}</button>
        </div>
    </div>
    <div class="row" *ngIf="colorSelected && colorSelected?.jpColorDisplayName">
        <div class="col-xl-12 col-sm-12 pt-3 text-sel-color">
            {{'usedCar.selColor' | translate }}
        </div>
        <div class="col-xl-3 col-sm-3 pl-0" *ngIf="colorSelected?.jpColorDisplayName">
            <button
                [ngStyle]="{
            'background':colorSelected?.backgroundColor,'color': colorSelected?.textColor,'border':colorSelected?.borderColor, 'text-shadow': '0px 2px 3px '+colorSelected.textShadowColor}"
                class="button-palette selected-btn">{{ currentLang === 'jp' ? colorSelected?.jpColorDisplayName : colorSelected?.enColorDisplayName}}</button>
        </div>
    </div>
</form>

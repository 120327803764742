
    <section class="popup_title_grey sm">
        <h5 class="mb-0">{{ 'usedCar.planDetails' | translate }}</h5>
        <a href="javascript:void(0);" class="popup_close" aria-label="Close" (click)="dismiss()">
            <svg class="bi bi-x" width="30px" height="30px" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M5.646 5.646a.5.5 0 000 .708l8 8a.5.5 0 00.708-.708l-8-8a.5.5 0 00-.708 0z" clip-rule="evenodd"></path>
                <path fill-rule="evenodd" d="M14.354 5.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z" clip-rule="evenodd"></path>
            </svg>
        </a>
    </section>
    <section class="form_box_package_plan_wrp plan_details_poupup mt-0 no-shadow">
        <div class="package_box mt-0">
            <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 p-0 pl-md-3 customer_info_divider">
                    <div class="sel-plan">
                        <span>{{ 'usedCar.selectedPlan' | translate }}</span>
                        <p class="fw-bold"> {{ plan?.displayName }} </p>
                        <p class="plan_description_block" *ngIf="plan?.description">
                            {{ plan?.description }}
                        </p>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 p-0 pl-md-3">                                            
                    <div class="amount" *ngIf="plan?.price > 0 || plan?.adminFee > 0">
                        <span *ngIf="plan?.price > 0" class="plan-title-block"> {{ 'usedCar.amount' | translate }}  </span>
                        <div *ngIf="currentLang === 'en'">
                            <div *ngIf="plan?.price > 0">
                                <span>
                                    <!-- (Plan Price)  -->
                                    <p class="fw-bold pt-0 mr-1"> {{ plan?.price  | number: '2.' }} JPY/Year </p></span>
                                <span></span>
                            </div>
                            <div *ngIf="plan?.adminFee > 0">
                                <p class="plan-title-block">Administrative fees</p> 
                                <span><p class="fw-bold pt-0 mr-1">{{ plan?.adminFee | number: '2.' }} JPY </p></span>
                            </div>
                            <div>
                                <p class="plan-title-block">Total Amount</p> 
                                <span><p class="fw-bold pt-0 mr-1">{{ ( plan?.adminFee + plan?.price ) | number: '2.' }} JPY </p>(Include taxes)</span>
                            </div>
                        </div>
                        <div *ngIf="currentLang === 'jp'" class="font_15">
            
                            <div *ngIf="plan?.price > 0">
                                <span>
                                    <!-- (Plan Price)  -->
                                    <p class="fw-bold pt-0 mr-1"> {{ plan?.price | number: '2.' }} 円/年 </p></span>
                                <span></span>
                            </div>
                            <div *ngIf="plan?.adminFee > 0">
                                <p class="plan-title-block">事務手数料</p> 
                                <span><p class="fw-bold pt-0 mr-1">{{ plan?.adminFee | number: '2.' }} 円</p></span>
                            </div>
                            <div>
                                <p class="plan-title-block">{{ 'usedCar.totalAmtToPay' | translate }}</p> 
                                <span><p class="fw-bold pt-0 mr-1">{{ ( plan?.adminFee + plan?.price ) | number: '2.' }} 円 </p>(税込)</span>
                            </div>
                        </div>
                    </div>
                    <div class="amount" *ngIf="plan?.price === 0 && plan?.adminFee === 0">
                        <span> {{ 'usedCar.amount' | translate }}  </span>
                        <div class="pt-3">
                            <span *ngIf="currentLang === 'jp'"> 0 円 </span>
                            <span *ngIf="currentLang === 'en'"> 0 JPY </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="payment_description_section" id="standard_plan"  *ngIf="plan?.name == 'standard'">
            <div *ngIf="currentLang == 'en'">
                <p>Door to door navigation</p>
                <p>Air conditioner before getting in (Remote)</p>
                <p>Charging spot information on space availability in a parking lot  (EV only)</p>
                <p>Smart device coordination</p>
                <p>OTA automatic map update</p>
                <p>Remote charging</p>
                <p>Charging spot search (EV only)</p>
                <p>Software update</p>
                <p>Apple CarPlay</p>
                <p>Google search / Google Street View / Google aerial photography</p>
                <p>Fastest route search</p>
                <p>Car life management</p>
                <p>Remote door lock / door lock status display</p>
                <p>My car finder</p>
                <p>Operator service</p>
                <p>Drive limit alert</p>
                <p>etc</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>以下のサービスがご利用できます</p>
                <p>ドアtoドアナビ</p>
                <p>スマートデバイス連携</p>
                <p>OTA自動地図更新</p>
                <p>最速ルート探索</p>
                <p>カーライフマネージメント</p>
                <p>リモートドアロック / ドアロック状態表示</p>
                <p>マイカーファインダー</p>
                <p>オペレータサービス</p>
                <p>ドライブ制限アラート</p>
                <p>ソフトウェア更新</p>
                <p>Google検索 / Googleストリートビュー / Google航空写真</p>
                <p>充電スポット満空情報（EVのみ）</p>
                <p>充電スポット検索（EVのみ）</p>
                <p>リモート充電（EVのみ）</p>
                <p>乗る前エアコン（リモート）（EVのみ）</p>
                <p>Apple CarPlay / Android Auto※</p>
                <p>等 </p>
                <p>※NissanConnect加入なしで利用可能</p>
            </div>
            
        </div>
        <div class="payment_description_section" id="pro_pilot" *ngIf="plan?.name == 'propilot'">
            <div *ngIf="currentLang == 'en'">
                <p>The following services are available</p>
                <p>Apple CarPlay / Android Auto Navi</p>
                <p>OTA automatic map update</p>
                <p>Software update</p>
                <p>My car finder</p>
                <p>Remote door lock / door lock status display</p>
                <p>Door to door navigation</p>
                <p>Car life management</p>
                <p>Fastest route search</p>
                <p *ngIf="vehicleType === 'newcar'">SOS call at the time of emergency stop of ProPilot [Only for vehicles equipped with ProPilot 2.1]</p>
                <p *ngIf="vehicleType === 'usedcar'">SOS call at the time of emergency stop of ProPilot [Only for vehicles equipped with ProPilot 2.2]</p>
                <p>Operator service</p>
                <p>Drive limit alert</p>
                <p>etc</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>以下のサービスがご利用できます</p>
                <p>情報チャンネル</p>
                <p>ドアtoドアナビ</p>
                <p>OTA自動地図更新</p>
                <p>プロパイロット緊急停止時SOSコール［プロパイロット 2.1搭載車のみに対応］</p>
                <p>最速ルート探索</p>
                <p>警告灯通知</p>
                <p>メンテナンス通知（NissanConnect メッセージ）</p>
                <p>リモートドアロック / ドアロック状態表示</p>
                <p>マイカーファインダー</p>
                <p>オペレータサービス</p>
                <p>ドライブ制限アラート</p>
                <p>Apple CarPlay / Android Auto※</p>
                <p>等 </p>
                <p>※NissanConnect加入なしで利用可能</p>
            </div>
        </div>
        <div class="payment_description_section" id="standard_plan+plus" *ngIf="plan?.name == 'standard+'">
            <div *ngIf="currentLang == 'en'">
                <p>The following services are available</p>
                <p>Door to Door Navi</p>
                <p>Air conditioner before getting in (remote)</p>
                <p>Smart device cooperation</p>
                <p>OTA automatic map update</p>
                <p>Remote charging</p>
                <p>Software update</p>
                <p>Apple CarPlay</p>
                <p>Google search / Google Street View / Google aerial photography</p>
                <p>Fastest route search</p>
                <p>Car life management</p>
                <p>Remote door lock / door lock status display</p>
                <p>My car finder</p>
                <p>Operator service</p>
                <p>Drive limit alert</p>
                <p>SOS call</p>
                <p>etc</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>以下のサービスがご利用できます</p>
                <p>ドアtoドアナビ</p>
                <p>OTA自動地図更新</p>
                <p>OTAソフトウェア更新</p>
                <p>最速ルート探索</p>
                <p>Google検索 / Googleストリートビュー / Google航空写真</p>
                <p>オペレータサービス</p>
                <p>情報チャンネル</p>
                <p>SOSコール</p>
                <p>リモートドアロック</p>
                <p>リモートマイカーチェック</p>
                <p>ドライブ制限アラート</p>
                <p>警告灯通知</p>
                <p>メンテナンス通知（NissanConnect メッセージ）</p>
                <p>Apple CarPlay™、Apple CarPlay™ワイヤレス接続、Android Auto™※</p>
                <p>等 </p>
                <p>※NissanConnect加入なしで利用可能</p>
            </div>
        </div>
        <div class="payment_description_section" id="basic_service" *ngIf="plan?.name == 'basic' && (naviType === 'd-op' || naviType === 'd-op+s-os')">
            <div *ngIf="currentLang == 'en'">
                <p>The following services are available</p>
                <p>NissanConnect message</p>
                <p>Fastest route search</p>
                <p>Information channel</p>
                <p>Here is the car email</p>
                <p>Operator service</p>
                <p>My car finder</p>
                <p>Warning light notification</p>
                <p>Remote door</p>
                <p>My car data</p>
                <p>Notification</p>
                <p>Destination car email</p>
                <p>Gmail ™ integration function</p>
                <p>Google Calendar link coordination function</p>
                <p>SNS coordination function</p>
                <p *ngIf="vehicleType === 'newcar'">Includes operator service (1 year free) to the basic services for dealer option navigation </p>
                <p *ngIf="vehicleType === 'usedcar'">Includes operator service</p>
                <p>etc</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>以下のサービスがご利用できます</p>
                <p>NissanConnect メッセージ</p>
                <p>最速ルート探索</p>
                <p>情報チャンネル</p>
                <p>ここです車メール</p>
                <p>マイカーファインダー</p>
                <p>警告灯通知</p>                
                <p>リモートドア</p>
                <p>マイカーデータ</p>
                <p>行き先車メール</p>
                <p>オペレータサービス※</p>
                <p>等 </p>
                <p *ngIf="vehicleType === 'newcar'">※ディーラーオプションナビ向けの基本サービスにはオペレータサービス（1年無料）が含まれます</p>
                <p *ngIf="vehicleType === 'usedcar'">※オペレータサービスが含まれます</p>                
            </div>
        </div>
        <div class="payment_description_section" id="basic_service" *ngIf="plan?.name == 'basic' && naviType === 'm-op'">
            <div *ngIf="currentLang == 'en'">
                <p>The following services are available</p>
                <p>NissanConnect message</p>
                <p>Fastest route search</p>
                <p>Information channel</p>
                <p>Here is the car email</p>
                <p>Operator service</p>
                <p>My car finder</p>
                <p>Warning light notification</p>
                <p>Remote door</p>
                <p>My car data</p>
                <p>Notification</p>
                <p>Destination car email</p>
                <p>Gmail ™ integration function</p>
                <p>Google Calendar link coordination function</p>
                <p>SNS coordination function</p>
                <p *ngIf="vehicleType === 'usedcar'">Includes operator service</p>
                <p>etc</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>以下のサービスがご利用できます</p>
                <p>NissanConnect メッセージ</p>
                <p>最速ルート探索</p>
                <p>情報チャンネル</p>
                <p>ここです車メール</p>
                <p>マイカーファインダー</p>
                <p>警告灯通知</p>                
                <p>リモートドア</p>
                <p>マイカーデータ</p>
                <p>行き先車メール</p>
                <p *ngIf="vehicleType === 'usedcar'">オペレータサービス</p>
                <p>等 </p>
                <p *ngIf="vehicleType === 'usedcar'">※オペレータサービスが含まれます</p>                
            </div>
        </div>
        <div class="payment_description_section" id="basic_service+sos" *ngIf="plan?.name == 'basic-sos' && (naviType === 'd-op' || naviType === 'd-op+s-os')">
            <div *ngIf="currentLang == 'en'">
                <p>The following services are available</p>
                <p>NissanConnect message</p>
                <p>Fastest route search</p>
                <p>Information channel</p>
                <p>Here is the car email</p>
                <p>Operator service</p>
                <p>My car finder</p>
                <p>Warning light notification</p>
                <p>Remote door</p>
                <p>My car data</p>
                <p>Notification</p>
                <p>Destination car email</p>
                <p>Gmail ™ integration function</p>
                <p>Google Calendar ™ link coordination function</p>
                <p>SNScoordination function</p>
                <p>SOS call</p>
                <p *ngIf="vehicleType === 'newcar'">Includes operator service (1 year free) to the basic services for dealer option navigation </p>
                <p *ngIf="vehicleType === 'usedcar'">Includes operator service </p>
                <p>etc</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>以下のサービスがご利用できます</p>
                <p>NissanConnect メッセージ</p>
                <p>最速ルート探索</p>
                <p>情報チャンネル</p>
                <p>ここです車メール</p>
                <p>マイカーファインダー</p>
                <p>警告灯通知</p>
                <p>リモートドア</p>
                <p>マイカーデータ</p>
                <p>行き先車メール</p>
                <p *ngIf="vehicleType === 'newcar'">オペレータサービス※</p>
                <p>SOSコール（利用期間：車両初度登録より10年間）</p>
                <p *ngIf="vehicleType === 'usedcar'">オペレータサービス</p>
                <p>等 </p>
                <p *ngIf="vehicleType === 'newcar'">※ディーラーオプションナビ向けの基本サービスにはオペレータサービス（1年無料）が含まれます</p>
                <p *ngIf="vehicleType === 'usedcar'">※オペレータサービスが含まれます</p>
            </div>
        </div>
        <div class="payment_description_section" id="basic-operator-service" *ngIf="plan?.name == 'basic-operator-service' && vehicleType==='newcar' && naviType === 'm-op'">
            <div *ngIf="currentLang == 'en'">
                <p>The following services are available</p>
                <p>NissanConnect message</p>
                <p>Fastest route search</p>
                <p>Information channel</p>
                <p>Here is the car email</p>
                <p>Operator service</p>
                <p>My car finder</p>
                <p>Warning light notification</p>
                <p>Remote door</p>
                <p>My car data</p>
                <p>Destination car email</p>
                <p>Gmail ™ integration function</p>
                <p>Google Calendar ™ link coordination function</p>
                <p>SNScoordination function</p>
                <p>SOS call</p>
                <p>etc</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>以下のサービスがご利用できます</p>
                <p>NissanConnect メッセージ</p>
                <p>最速ルート探索</p>
                <p>情報チャンネル</p>
                <p>ここです車メール</p>
                <p>マイカーファインダー</p>
                <p>警告灯通知</p>
                <p>リモートドア</p>
                <p>マイカーデータ</p>
                <p>行き先車メール</p>
                <p>オペレータサービス</p>
                <p>等 </p>
            </div>
        </div>
        <div class="payment_description_section" id="sos_only" *ngIf="plan?.name == 'sos'">
            <div *ngIf="currentLang == 'en'">
                <p>In case of sudden illness or when you feel danger, you can press the SOS call switch to connect to a professional operator and support coordination with the police and fire department.In the unlikely event of an accident, an automatic notification will be sent in conjunction with airbag deployment."</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>急病時や危険を感じた時、SOSコールスイッチを押すと専門のオペレータに繋がって、警察や消防への連携をサポートしてくれます。<br>また、万が一の事故発生時には、エアバッグ展開と連動して自動通報します。<br>（利用期間：車両初度登録より10年間）</p>
            </div>
        </div>
        <div class="payment_description_section" id="operator_service" *ngIf="plan?.name == 'operator-service'">
            <div *ngIf="currentLang == 'en'">
                <p>When you press the operator button, you will be connected to the operator, search for the destination and surrounding POI, and have the remote destination setting and facility information list displayed.</p>
            </div>
            <div *ngIf="currentLang == 'jp'">
                <p>オペレータボタンを押すとオペレータに接続され、目的地や周辺POIを検索して、遠隔目的地設定や、施設情報リストを表示してもらうことができます。</p>
            </div>
        </div>
    </section>
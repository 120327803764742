import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html'
})
export class MenuCardComponent{

  @Input() cardDisplayItems: any;


}

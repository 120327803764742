import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutsModule } from './layouts/layouts.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LayoutsModule,
    TranslateModule
  ]
})
export class SharedModule { }

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-common-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  @Input() tncObj: any;
  @Input() formReset: boolean;
  @Output() selectCheckBox = new EventEmitter();
  public formTnc: FormGroup;
  public validForm: boolean = false;
  constructor(
    private formBuilder: FormBuilder) { }

  ngOnInit(): void { 
    this.buildTncForm();
  }

  ngOnChanges(){
    if(this.formReset){
      this.clearAll();
    }
  }

  /**
* @method buildTncForm
* @description function to intiate form
*/
  buildTncForm() {
    this.formTnc = this.formBuilder.group({});
    if(this.tncObj && this.tncObj.tncAccepted){
      this.formTnc.addControl('understandTermsAndConditions', new FormControl(true, Validators.required))
    }else{
      this.formTnc.addControl('understandTermsAndConditions', new FormControl(null, Validators.required))
    }
  }

  
  /**
 * @method clearAll
 * @description function to reset checkboxes
 */
  clearAll() {
    this.formTnc.reset();
  }

  /**
 * @method onCheckBoxSelection
 * @description function to enable diable check based on checkbox check
 */
  onCheckBoxSelection() {
    if(this.formTnc.value.understandTermsAndConditions === true){
      this.validForm = true;
    }else{
      this.validForm = false;
    }
    this.selectCheckBox.emit(this.validForm);
  }

}

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { regex } from '@shared/constants/regex.constants';

export interface Validator {
  [key: string]: boolean;
}

export class GlobalValidator {
  static validname(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (control.value && !regex.NAME_REGEX[lang].test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static validDepartmentname(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (control.value && !regex.DEPARTMENT_NAME_REGEX[lang].test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static validOffice(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (lang === 'jp' && control.value && !regex.JP_OFFICE_REGEX.test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static validHiranganaName(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (control.value && !regex.HIRANGANA_NAME_REGEX[lang].test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static halfWidthKatakana(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (lang === 'jp' && control.value && !regex.HALF_WIDTH_KATAKANA.test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static validnameKatakana(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (lang === 'jp' && control.value && !regex.KATAKANA_NAME_REGEX.test(control.value)) {
        return { invalid_katakana_name: true };
      }
      if (lang === 'en' && control.value && !regex.NAME_REGEX[lang].test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static validnameKatakanaWithSpace(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (lang === 'jp' && control.value && !regex.KATAKANA_NAME_REGEX_WITH_SPACE.test(control.value)) {
        return { invalid_katakana_name: true };
      }
      if (lang === 'en' && control.value && !regex.NAME_REGEX[lang].test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static validAddress(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (lang === 'jp' && control.value && !regex.JP_ADDRESS_REGEX.test(control.value)) {
        return { invalid_jp_address: true };
      }
      return null;
    };
  }
  static validateFullWidthName() {
    return (control: AbstractControl): Validator => {
      if (control.value && !regex.FULL_WIDTH_NAME_WITH_SPACE.test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static validateKanaHiragana(lang = 'en') {
    return (control: AbstractControl): Validator => {
      if (lang === 'jp' && control.value && !regex.KATAKANA_HIRAGANA_NAME_REGEX_WITH_SPACE.test(control.value)) {
        return { invalid_name: true };
      }
      return null;
    };
  }

  static number(control: AbstractControl): Validator {
    if (control.value && !regex.NUMBER_REGEX.test(control.value)) {
      return { invalid_number: true };
    }
    return null;
  }

  static email(control: AbstractControl) {
    if (GlobalValidator.isValidEmailLogic(control.value)) {
      return { invalid_email: true };
    }
    return null;
  }

  static isValidEmailLogic(email: string) {
    if (email === undefined || email.trim() === '' || email.length <= 5 || !regex.EMAIL_REGEX.test(email)) {
      return false;
    }
    return true;
  }

  static isvalidCardNumber(control: AbstractControl) {
    if (control.value) {
      if (!GlobalValidator.validateCard(control.value)) {
        return { invalid_card: true };
      }
    }
    return null;
  }

  static nonEmpty(control: AbstractControl) {
    if (control) {
      const isWhitespace = ((control.value || '').toString() || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { required: true };
    }
    return null;
  }

  static alphaNumeric(control: AbstractControl) {
    if (control.value && !regex.ALPHA_NUMERIC.test(control.value)) {
      return { invalid_alpha_numeric: true };
    }
    return null;
  }

  static ordernoAlphaNumeric(control: AbstractControl) {
    if (control.value && !regex.ORDERNO_ALPHA_NUMERIC.test(control.value)) {
      return { invalid_alpha_numeric: true };
    }
    return null;
  }

  static naviAlphaNumeric(control: AbstractControl) {
    if (control.value && !regex.NAVI_ALPHA_NUMERIC.test(control.value)) {
      return { invalid_alpha_numeric: true };
    }
    return null;
  }

  static isValidMobileNumber(control: AbstractControl): Validator {
    if (control.value && !regex.JP_MOBILE_REGEX.test(control.value.replace(/\D/g, ''))) {
      return { invalid_mobile: true };
    }
    return null;
  }

  static isValidZipCode(control: AbstractControl): Validator {
    if (control.value && !regex.JP_ZIP_REGEX.test(control.value.replace(/\D/g, ''))) {
      return { invalid_zip: true };
    }
    return null;
  }

  static requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    });
  }

  static conditionalValidator(condition: (() => boolean), validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!condition()) {
        return null;
      }
      return validator(control);
    };
  }

  static isValidNumberPlateTxtBoxTwo(control: AbstractControl): Validator {
    if (control.value && !regex.NUMBER_PLATE_TXTBOX_TWO.test(control.value)) {
      return { invalid: true };
    }
    return null;
  }

  static validateCard(cardNum) {
    const validDigits = '0123456789'; // Valid digits in a credit card number
    const cardLen = cardNum.length;  // The length of the submitted cc number
    let intCardNumber = cardNum.match(/\d{4,16}/g);  // Integer of ccNumb
    let strCardNumber = cardNum.toString();  // String of ccNumb
    strCardNumber = strCardNumber.replace(/^\s+|\s+$/g, '');  // Strip spaces
    // strCardNumber = strCardNumber.match(/\d{4,16}/g);
    let intTotal = 0;  // Integer total set at zero
    let defaultNumber = true;  // By default assume it is a number
    let defaultResult = false;  // By default assume it is NOT a valid cc
    let temp;  // Temporary variable for parsing string
    let calc;  // Used for calculation of each digit
    // Determine if the ccNumb is in fact all numbers
    for (let j = 0; j < cardLen; j++) {
      temp = '' + strCardNumber.substring(j, j + 1);
      if (validDigits.indexOf(temp) === -1) {
        defaultNumber = false;
      }
    }
    // If it is NOT a number, you can either alert to the fact, or just pass a failure
    if (!defaultNumber) {
      defaultResult = false;
    }
    // Determine if it is the proper length
    if ((cardLen === 0) && (defaultResult)) {  // Nothing, the field is blank AND passed above # check
      defaultResult = false;
    } else { // ccNumb is a number and the proper length - let's
      //  see if it is a valid card number
      if (cardLen >= 15) {  // 15 or 16 for Amex or V/MC
        let card =  this.cardPayment(cardLen , intCardNumber , calc, intTotal );
        console.log("cardValues", card.intCardNumber , card.intTotal, cardLen);
        if ((intTotal % 10) === 0) {  // Check to see if the sum Mod 10 is zero
          defaultResult = true;  // This IS (or could be) a valid credit card number.
        } else {
          defaultResult = false;  // This could NOT be a valid credit card number
        }
      }
    }
    // Change alert to on-page display or other indication as needed.
    if (!defaultResult) {
      return false;
    }
    return true;
  }

    static cardPayment(cardLen: any, intCardNumber: any, calc: any, intTotal: number) {
      for (let i = cardLen-1; i > 0; i-=2) {  // LOOP through the digits of the card
        calc = parseInt(intCardNumber, 10) % 10;  // Right most digit
        calc = parseInt(calc, 10);  // Assure it is an integer
        intTotal = intTotal + calc;  // Running total of the card number as we loop - Do Nothing to first digit
        intCardNumber = intCardNumber / 10; // Subtracts right most digit from ccNumb
        calc = parseInt(intCardNumber, 10) % 10;     // NEXT right most digit
        calc = calc * 2; 
        // Instead of some screwy method of converting 16 to a string
        // and then parsing 1 and 6 and then adding them to make 7,
        // I use a simple switch statement to change the value
        // of calc2 to 7 if 16 is the multiple.
        switch(calc) {
          case 10: calc = 1; break;  // 5*2=10 & 1+0 = 1
          case 12: calc = 3; break;  // 6*2=12 & 1+2 = 3
          case 14: calc = 5; break;  // 7*2=14 & 1+4 = 5
          case 16: calc = 7; break;  // 8*2=16 & 1+6 = 7
          case 18: calc = 9; break;  // 9*2=18 & 1+8 = 9
          default: calc = calc;      // 4*2= 8 &   8 = 8  - the same for all lower numbers
        }
        intCardNumber = intCardNumber / 10;  // Subtracts right most digit from ccNum
        intTotal += calc;  // Running total of the card number as we loop
      } // END OF LOOP
      let cardVariables = { intCardNumber , intTotal};
      return cardVariables;
    }

  static nonEmptyForDigital(control: AbstractControl) {
    if (control.value) {
      const isWhitespace = ((control.value || '').toString() || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { non_empty: true };
    }
    return null;
  }

  static requestPersonNumber(control: AbstractControl) {
    if (control.value && !regex.ALPHA_NUMERIC_WITHOUT_SPACES.test(control.value)) {
      return { invalid_alpha_numeric: true };
    }
    return null;
  }

  static numberWithDecimal(control: AbstractControl): Validator {
    if (control.value && !regex.NUMBER_WITH_DECIMAL.test(control.value)) {
      return { invalid_dec_number: true };
    }
    return null;
  }
  static validateCorpPhoneNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const phNo1 = control.get('corporateTelephone1');
    const phNo2 = control.get('corporateTelephone2');
    const phNo3 = control.get('corporateTelephone3');
    console.log(phNo1)
    if (phNo1.value && regex.NUMBER_REGEX.test(phNo1.value) && phNo2.value && regex.NUMBER_REGEX.test(phNo2.value) && phNo3.value && regex.NUMBER_REGEX.test(phNo3.value)) {
      let totalCount: string = phNo1.value + phNo2.value + phNo3.value;
      return totalCount.length < 10 || totalCount.length > 11 ? { inValid_corp_phoneNumber: true } : null;
    }
  }
  static validateRepPhoneNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const phNo1 = control.get('representativesTelephone1');
    const phNo2 = control.get('representativesTelephone2');
    const phNo3 = control.get('representativesTelephone3');
    if (phNo1.value && regex.NUMBER_REGEX.test(phNo1.value) && phNo2.value && regex.NUMBER_REGEX.test(phNo2.value) && phNo3.value && regex.NUMBER_REGEX.test(phNo3.value)) {
      let totalCount: string = phNo1.value + phNo2.value + phNo3.value;
      return totalCount.length < 10 || totalCount.length > 11 ? { inValid_rep_phoneNumber: true } : null;
    }
  }
  static validateDealerPhoneNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const phNo1 = control.get('dealerTelephone1');
    const phNo2 = control.get('dealerTelephone2');
    const phNo3 = control.get('dealerTelephone3');
    if (phNo1.value && regex.NUMBER_REGEX.test(phNo1.value) && phNo2.value && regex.NUMBER_REGEX.test(phNo2.value) && phNo3.value && regex.NUMBER_REGEX.test(phNo3.value)) {
      let totalCount: string = phNo1.value + phNo2.value + phNo3.value;
      return totalCount.length < 10 || totalCount.length > 11 ? { inValid_dealer_phoneNumber: true } : null;
    }
  }

  static validateNFSPhoneNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const phNo1 = control.get('NFSphoneNumber1');
    const phNo2 = control.get('NFSphoneNumber2');
    const phNo3 = control.get('NFSphoneNumber3');
    if (phNo1.value && regex.NUMBER_REGEX.test(phNo1.value) && phNo2.value && regex.NUMBER_REGEX.test(phNo2.value) && phNo3.value && regex.NUMBER_REGEX.test(phNo3.value)) {
      let totalCount: string = phNo1.value + phNo2.value + phNo3.value;
      return totalCount.length < 10 || totalCount.length > 11 ? { inValid_nfs_phoneNumber: true } : null;
    }
  }

  static validateContactPersonPhoneNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const phNo1 = control.get('phoneNumber1');
    const phNo2 = control.get('phoneNumber2');
    const phNo3 = control.get('phoneNumber3');
    if (phNo1.value && regex.NUMBER_REGEX.test(phNo1.value) && phNo2.value && regex.NUMBER_REGEX.test(phNo2.value) && phNo3.value && regex.NUMBER_REGEX.test(phNo3.value)) {
      let totalCount: string = phNo1.value + phNo2.value + phNo3.value;
      return totalCount.length < 10 || totalCount.length > 11 ? { inValidDealerPhoneNumber: true } : null;
    }
  }

  static validateCustomerPhoneNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const phNo1 = control.get('customerPhoneNumber1');
    const phNo2 = control.get('customerPhoneNumber2');
    const phNo3 = control.get('customerPhoneNumber3');
    if (phNo1.value && regex.NUMBER_REGEX.test(phNo1.value) && phNo2.value && regex.NUMBER_REGEX.test(phNo2.value) && phNo3.value && regex.NUMBER_REGEX.test(phNo3.value)) {
      let totalCount: string = phNo1.value + phNo2.value + phNo3.value;
      return totalCount.length < 10 || totalCount.length > 11 ? { inValidCustomerPhoneNumber: true } : null;
    }
  }

  static validateRepresentativePhoneNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const phNo1 = control.get('representativePhoneNumber1');
    const phNo2 = control.get('representativePhoneNumber2');
    const phNo3 = control.get('representativePhoneNumber3');
    if (phNo1.value && regex.NUMBER_REGEX.test(phNo1.value) && phNo2.value && regex.NUMBER_REGEX.test(phNo2.value) && phNo3.value && regex.NUMBER_REGEX.test(phNo3.value)) {
      let totalCount: string = phNo1.value + phNo2.value + phNo3.value;
      return totalCount.length < 10 || totalCount.length > 11 ? { inValidRepresentativePhoneNumber: true } : null;
    }
  }

  static validrepName(control: AbstractControl): Validator {
    if (control.value && !regex.REP_NAME_REGEX.test(control.value)) {
      return { invalid_rep_name: true };
    }
    return null;
  }
  static isFullWidthDeliveryCompanyAddr(control: AbstractControl): Validator {
    if (control.value && !regex.FULL_WIDTH_DELIVERY_COMPANY_ADDR.test(control.value)) {
      return { invalid_company_addr: true };
    }
    return null;
  }
  static isValidFirstNumberPlate(control: AbstractControl): Validator {
    if (control.value && !regex.FIRST_NUMBER_PLATE_REGEX.test(control.value)) {
      return { invalid_firstNp: true };
    }
    return null;
  }
  static isValidThirdNumberPlate(control: AbstractControl): Validator {
    if (control.value && !regex.THIRD_NUMBER_PLATE_REGEX.test(control.value)) {
      return { invalid_thirdNp: true };
    }
    return null;
  }
  static isValidEmailAddr(control: AbstractControl): Validator {
    if (control.value && !regex.VALID_EMAIL_REGEX.test(control.value)) {
      return { invalid_email: true };
    }
    return null;
  }
  static isFullWidthAddr(control: AbstractControl): Validator {
    if (control.value && !regex.FULL_WIDTH_ADDR_WITH_SPACE.test(control.value)) {
      return { invalid_addr: true };
    }
    return null;
  }

  static WithHyphenAlphaNumeric(control: AbstractControl) {
    if (control.value && !regex.HYPHEN_ALPHA_NUMERIC.test(control.value)) {
      return { invalid_alpha_numeric: true };
    }
    return null;
  }

  static confirmEmailCheck: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const email = control.get('email').value;
    const confirmEmail = control.get('confirmEmail').value;
    return (email?.toLowerCase() == confirmEmail?.toLowerCase()) ? null : { misMatch: true };
  }

  static alphaNumericSymbols(control: AbstractControl) {
    if (control.value && !regex.ALPHA_NUMERIC_SYMBOLS.test(control.value)) {
      return { invalid_alpha_numeric_symbol: true };
    }
    return null;
  }

  static alphaNumericSymbolsWithoutSpace(control: AbstractControl) {
    if (control.value && !regex.ALPHA_NUMERIC_SYMBOLS_WITHOUT_SPACE.test(control.value)) {
      return { invalid_alpha_numeric_symbol_without_space: true };
    }
    return null;
  }

  static alphaNumericSymbolsJpAllFullWidth(control: AbstractControl) {
    if (control.value && !regex.ALPHA_NUMERIC_SYMBOLS_JP_ALL_FULL_WIDTH.test(control.value)) {
      return { invalid_alpha_numeric_symbol_jp_all_full_width: true };
    }
    return null;
  }

  static alphaSymbolsJpAllFullWidth(control: AbstractControl) {
    if (control.value && !regex.ALPHA_SYMBOLS_JP_ALL_FULL_WIDTH.test(control.value)) {
      return { invalid_alpha_symbol_jp_all_full_width: true };
    }
    return null;
  }

  static alphaNumericHyphonJPAllFullWidthWithSpace(control: AbstractControl) {
    if (control.value && !regex.ALPHA_NUMERIC_HYPHON_JP_ALL_FULL_WIDTH_WITH_SPACE.test(control.value)) {
      return { invalid_alpha_numeric_hyphon_jp_all_full_width_with_space: true };
    }
    return null;
  }

  static alphaSymbolsJPAll(control: AbstractControl) {
    if (control.value && !regex.ALPHA_SYMBOLS_JP_ALL.test(control.value)) {
      return { invalid_alpha_symbol_jp_all: true };
    }
    return null;
  }

  static alphaNumericSymbolsJPAll(control: AbstractControl) {
    if (control.value && !regex.ALPHA_NUMERIC_SYMBOLS_JP_ALL.test(control.value)) {
      return { invalid_numeric_alpha_symbol_jp_all: true };
    }
    return null;
  }
  static alphaNumericSymbolsWithFullWidthHalfWidth(control: AbstractControl) {
    if (control.value && !regex.ALPHA_NUMERIC_SYMBOLS_FULLWIDTH_HALF_WIDTH.test(control.value)) {
      return { alpha_Numeric_Symbols_With_FullWidth_HalfWidth: true };
    }
    return null;
  }
static validNaviId(control: AbstractControl) {
  if (control.value && !regex.NAVI_ID_REGEX.test(control.value)) {
      return { invalid_navi_id: true };
  }
  return null;
}

static validCustomerAddress(control: AbstractControl) {
    if (control.value && !regex.CUSTOMER_ADDRESS_REGEX.test(control.value)) {
        return { invalid_cust_address: true };
    }
    return null;
}

static validCustomerName(control: AbstractControl) {
    if (control.value && !regex.CUSTOMER_NAME_REGEX.test(control.value)) {
        return { invalid_customer_name: true };
    }
    return null;
}

static validCustomerNameKatakana(control: AbstractControl) {
    if (control.value && !regex.CUSTOMER_NAME_KATAKANA_REGEX_WITH_SPACE.test(control.value)) {
        return { invalid_customer_katakana_name: true };
    }
    return null;
}

static isPhNumberStartsWithzero(control: AbstractControl) {
  if (control.value && regex.NUMBER_REGEX.test(control.value)) {
      if(control.value.toString().charAt(0) !== '0') {
        return {invalid_firstPhNumber:true};
      }
    }
  }
static isVlidVinSegmentOne(control: AbstractControl) {
  if (control.value && !regex.ALPHA_NUMERIC_NO_HYPHON.test(control.value)) {
    return { invalid_alpha_numeric: true };
  }
  return null;
}

static is6DigitValidIndividualNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let individualPhNoArray =  ['090','080','070','050'];
  const phNo1 = control.get('individualPhoneNumber1');
  const phNo2 = control.get('individualPhoneNumber2');
  if(phNo1.value && phNo2.value){
    if(!(individualPhNoArray.includes(phNo1.value.toString())) && phNo1.value.toString().charAt(0) === '0'){
    let totalCount:string = phNo1.value + phNo2.value ;
    return totalCount.length !== 6 ? { invalid6DigitIndiviualNumber: true } : null;
  }
  }
  }
  
  static is11DigitValidIndividualNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let individualPhNoArray =  ['090','080','070','050'];
  const phNo1 = control.get('individualPhoneNumber1');
  const phNo2 = control.get('individualPhoneNumber2');
  if(phNo1.value){
    if((individualPhNoArray.includes(phNo1.value.toString()) && phNo2.value && phNo2.value.length !== 4) || ( individualPhNoArray.includes(phNo1.value.toString()) && (phNo2.value === null || phNo2.value === ''))){
      return { invalid11DigitIndiviualNumber: true };
  }
  return null
  }
  }
  static is6DigitValidOptionalNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let individualPhNoArray =  ['090','080','070','050'];
    const phNo1 = control.get('optionalPhoneNumber1');
    const phNo2 = control.get('optionalPhoneNumber2');
    if(phNo1.value && phNo2.value){
      if(!(individualPhNoArray.includes(phNo1.value.toString())) && phNo1.value.toString().charAt(0) === '0'){
      let totalCount:string = phNo1.value + phNo2.value ;
      return totalCount.length !== 6 ? { invalid6DigitOptionalNumber: true } : null;
    }
    }
    }
    
    static is11DigitValidOptionalNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let individualPhNoArray =  ['090','080','070','050'];
    const phNo1 = control.get('optionalPhoneNumber1');
    const phNo2 = control.get('optionalPhoneNumber2');
    if(phNo1.value){
      if((individualPhNoArray.includes(phNo1.value.toString()) && phNo2.value && phNo2.value.length !== 4) || ( individualPhNoArray.includes(phNo1.value.toString()) && (phNo2.value === null || phNo2.value === ''))){
        return { invalid11DigitOptionalNumber: true };
    }
    return null
    }
    }

    static isOptionalPhNoRequired: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const phNo1 = control.get('optionalPhoneNumber1');
      const phNo2 = control.get('optionalPhoneNumber2');
      const phNo3 = control.get('optionalPhoneNumber3');
      if(phNo1.value || phNo2.value || phNo3.value){
        if(!(phNo1.value && phNo2.value && phNo3.value)){
          return { isOptionalPhNoRequired: true };
      }
      return null
      }
      }
}

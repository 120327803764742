<ng-template #searchBizConnectOrderPopup let-modal>
    <section class="popup_title_grey sm">
        <h1>{{'dealer.bizConnect.searchOrderInfo' | translate}}</h1>
        <a href="javascript:void(0);" class="close_icon" (click)="modal.dismiss(); dismiss()">
            <svg class="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clip-rule="evenodd"/>
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clip-rule="evenodd"/>
            </svg>
        </a>
    </section>
    <form [formGroup]="formSearchOrder" autocomplete="off">
        <section class="popup_cnt sm outerPop">
            <div class="row">        
                <div class="col-12"><h5 class="add_vin">{{'dealer.bizConnect.provideDetails' | translate}}</h5></div>    
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div *ngIf="dealerType !== 'CA'">
                        <div class="floating_label_wrp select_box" [class.error]="controls?.companyName?.touched && controls?.companyName?.errors?.required">
                            <select class="floating_label_field select customClass" id="dealer_company_name" formControlName="companyName">
                                <option [ngValue]="null"> {{ 'dealer.bizConnect.select' | translate }} </option>
                                <option *ngFor="let companyName of dropdowns?.companyNames; let i=index;" [ngValue]="companyName?.companyCode"> {{companyName?.companyName}} {{ i === 101 ? i : ''}} </option>
                            </select>
                            <label for="dealer_company_name" class="floating_label required"> {{ 'dealer.bizConnect.dealerCompanyName' | translate }} </label>
                            <div *ngIf="controls?.companyName?.touched && controls?.companyName?.errors" class="form-invalid">
                                <div *ngIf="controls?.companyName?.touched && controls?.companyName?.errors?.required" class="form-invalid"> {{'dealer.bizConnect.vMsg.addvin.fuelTypeValid' | translate}} </div>							
                            </div>                        
                        </div>
                        <div class="floating_label_wrp select_box" [class.error]="controls?.dealershipName?.touched && controls?.dealershipName?.errors?.required">
                            <select class="floating_label_field select" id="dealership_name" formControlName="dealershipName">
                                <option [ngValue]="null"> {{ 'dealer.bizConnect.select' | translate }} </option>
                                <option *ngFor="let dealershipName of dropdowns?.dealershipNames" [ngValue]="dealershipName?.dealerShipCode"> {{dealershipName?.dealerShipName}}  </option>
                            </select>
                            <label for="dealership_name" class="floating_label required"> {{ 'dealer.bizConnect.dealershipName' | translate }} </label>
                            <div *ngIf="controls?.dealershipName?.touched && controls?.dealershipName?.errors" class="form-invalid">
                                <div *ngIf="controls?.dealershipName?.touched && controls?.dealershipName?.errors?.required" class="form-invalid"> {{'dealer.bizConnect.vMsg.addvin.fuelTypeValid' | translate}} </div>
                            </div>                        
                        </div>
                    </div>
                    <div class="floating_label_wrp" [class.error]="controls?.tempOrderNumber?.touched && controls?.tempOrderNumber?.errors">
                        <input type="text" class="floating_label_field" id="tmp_order_number" placeholder="{'dealer.bizConnect.orderNumber' | translate}}" formControlName="tempOrderNumber">
                        <label class="floating_label required" for="tmp_order_number" >{{'dealer.bizConnect.orderNumber' | translate}}</label>
                        <div *ngIf="controls?.tempOrderNumber?.touched && controls?.tempOrderNumber?.errors" class="form-invalid">
                            <div *ngIf="controls?.tempOrderNumber?.errors?.required">{{'dealer.bizConnect.vMsg.orderNoReq' | translate}}</div>
                            <div *ngIf="controls?.tempOrderNumber?.errors?.maxlength">{{'dealer.bizConnect.vMsg.orderNoNotValid' | translate}}</div>
                            <div *ngIf="controls?.tempOrderNumber?.errors?.minlength">{{'dealer.bizConnect.vMsg.orderNoNotValid' | translate}}</div>
                            <div *ngIf="controls?.tempOrderNumber?.errors?.invalid_alpha_numeric">{{'dealer.bizConnect.vMsg.orderNoNotValid' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-11 col-sm-11 col-md-11 col-xl-11 description_col mt-4">
                    <div [innerHTML]="'dealer.bizConnect.orderSearchContents.description' | translate"></div>
                </div>
            </div>
        </section>    
        <section class="popup_button sm">    
            <hr class="border_line" />        
            <button class="primary_button ml-3" (click)="searchBizConnectOrderInfo()" [disabled]="formSearchOrder.invalid || submitted"> {{'dealer.bizConnect.search' | translate}} </button>
            <button type="button" class="outline_button" (click)="modal.dismiss(); dismiss()"> {{'dealer.bizConnect.cancel' | translate}} </button>
        </section>
    </form>
</ng-template>
<ng-template #showCapProfitDownPopup let-modal>
    <div class="toaster_modal_response">    
        <div class="email_otp">
            <div class="otp_icon">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="170" height="124.535" viewBox="0 0 170 124.535"><defs><style>.a,.b,.c,.d,.f,.i{fill:none;}.a{stroke:#afbefd;}.a,.b,.c{stroke-linecap:round;stroke-width:3px;}.b{stroke:#ffd29d;}.c{stroke:#fdcc92;}.d{stroke:#d8dfe6;}.d,.f{stroke-width:2px;}.e{fill:#fff;}.f{stroke:#ff4545;opacity:0.3;}.g{fill:#ff2747;stroke:#ff2747;}.h{stroke:none;}.j{filter:url(#a);}</style><filter id="a" x="35" y="23.535" width="100" height="101" filterUnits="userSpaceOnUse"><feOffset dy="3" input="SourceAlpha"/><feGaussianBlur stdDeviation="3" result="b"/><feFlood flood-color="#d6e9f5"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g transform="translate(-0.251 1.5)"><g transform="translate(143.607 99.642)"><path class="a" d="M21210.631-1061.971v9.924" transform="translate(-21205.668 1061.971)"/><path class="a" d="M0,0V9.924" transform="translate(9.924 4.962) rotate(90)"/></g><g transform="translate(43.909 0)"><path class="b" d="M21210.631-1061.971v9.924" transform="translate(-21205.668 1061.971)"/><path class="c" d="M0,0V9.924" transform="translate(9.924 4.962) rotate(90)"/></g><g class="d" transform="translate(0.251 52.035)"><ellipse class="h" cx="6.5" cy="7" rx="6.5" ry="7"/><ellipse class="i" cx="6.5" cy="7" rx="5.5" ry="6"/></g><g class="j" transform="matrix(1, 0, 0, 1, 0.25, -1.5)"><rect class="e" width="82" height="83" rx="41" transform="translate(44 29.54)"/></g><g class="f" transform="translate(156.251 52.035)"><ellipse class="h" cx="7" cy="7.5" rx="7" ry="7.5"/><ellipse class="i" cx="7" cy="7.5" rx="6" ry="6.5"/></g><path class="g" d="M38.673,22.7H27.26V11.283a2.283,2.283,0,1,0-4.565,0V22.7H11.283a2.283,2.283,0,1,0,0,4.565H22.7V38.673a2.283,2.283,0,1,0,4.565,0V27.26H38.673a2.283,2.283,0,1,0,0-4.565Z" transform="translate(85.017 105.125) rotate(-135)"/></g></svg>
                    <h2 class="error">{{'dealer.bizConnect.orderSearchContents.errorMsg' | translate}}</h2>
                    <div *ngIf="currentLang === 'en'" [innerHtml]="responseMsg"></div>
                    <div *ngIf="currentLang === 'jp'" class="resp_msg mt-4" [innerHtml]="responseMsg"></div>
                </div>
            </div>
            <div class="otp_box">
                <div class="otp_links">
                    <button type="button" class="secondary_button mt-2" (click)="closeCurrentPopup()">{{'dealer.bizConnect.orderSearchPopup.continue' | translate}}</button>
                </div>            
            </div>
        </div>
    </div>
</ng-template>

<ng-template #showErrorPopup let-modal>
    <div class="toaster_modal_response">    
        <div class="email_otp">
            <div class="otp_icon">
                <div #failed>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="170" height="124.535" viewBox="0 0 170 124.535"><defs><style>.a,.b,.c,.d,.f,.i{fill:none;}.a{stroke:#afbefd;}.a,.b,.c{stroke-linecap:round;stroke-width:3px;}.b{stroke:#ffd29d;}.c{stroke:#fdcc92;}.d{stroke:#d8dfe6;}.d,.f{stroke-width:2px;}.e{fill:#fff;}.f{stroke:#ff4545;opacity:0.3;}.g{fill:#ff2747;stroke:#ff2747;}.h{stroke:none;}.j{filter:url(#a);}</style><filter id="a" x="35" y="23.535" width="100" height="101" filterUnits="userSpaceOnUse"><feOffset dy="3" input="SourceAlpha"/><feGaussianBlur stdDeviation="3" result="b"/><feFlood flood-color="#d6e9f5"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g transform="translate(-0.251 1.5)"><g transform="translate(143.607 99.642)"><path class="a" d="M21210.631-1061.971v9.924" transform="translate(-21205.668 1061.971)"/><path class="a" d="M0,0V9.924" transform="translate(9.924 4.962) rotate(90)"/></g><g transform="translate(43.909 0)"><path class="b" d="M21210.631-1061.971v9.924" transform="translate(-21205.668 1061.971)"/><path class="c" d="M0,0V9.924" transform="translate(9.924 4.962) rotate(90)"/></g><g class="d" transform="translate(0.251 52.035)"><ellipse class="h" cx="6.5" cy="7" rx="6.5" ry="7"/><ellipse class="i" cx="6.5" cy="7" rx="5.5" ry="6"/></g><g class="j" transform="matrix(1, 0, 0, 1, 0.25, -1.5)"><rect class="e" width="82" height="83" rx="41" transform="translate(44 29.54)"/></g><g class="f" transform="translate(156.251 52.035)"><ellipse class="h" cx="7" cy="7.5" rx="7" ry="7.5"/><ellipse class="i" cx="7" cy="7.5" rx="6" ry="6.5"/></g><path class="g" d="M38.673,22.7H27.26V11.283a2.283,2.283,0,1,0-4.565,0V22.7H11.283a2.283,2.283,0,1,0,0,4.565H22.7V38.673a2.283,2.283,0,1,0,4.565,0V27.26H38.673a2.283,2.283,0,1,0,0-4.565Z" transform="translate(85.017 105.125) rotate(-135)"/></g></svg>
                    <h2 class="error">{{'dealer.bizConnect.orderSearchContents.errorMsg' | translate}}</h2>
                    <div *ngIf="currentLang === 'en'" [innerHtml]="responseMsg"></div>
                    <div *ngIf="currentLang === 'jp'" class="resp_msg mt-4" [innerHtml]="responseMsg"></div>
                </div>
            </div>
            <div class="otp_box">
                <div class="otp_links">
                    <button type="button" class="secondary_button mt-2" (click)="closeCurrentPopup()">{{'dealer.bizConnect.orderSearchPopup.continue' | translate}}</button>
                </div>            
            </div>
        </div>
    </div>
</ng-template>

<ng-template #showOrderConfirmationPopup let-modal>
    <app-order-confirmation [modal]="modal" [confirmationPopupData]="confirmationPopupData" (closeOrderConfirm)="closeOrderConfirmPopup()"></app-order-confirmation>
</ng-template>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-details-popup',
  templateUrl: './plan-details-popup.component.html',
  styleUrls: ['./plan-details-popup.component.scss']
})
export class PlanDetailsPopupComponent implements OnInit {
  @Input() plan;
  @Input() modal;
  @Input() vehicleType;
  @Input() naviType;
  @Input() mode: any;
  public currentLang: any;

  ngOnInit(): void {
    this.currentLang = (this.mode) ? (localStorage.getItem('cLang') ? localStorage.getItem('cLang')  : 'jp') : (localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp');
  }

  dismiss() {
    this.modal.dismiss();
  }
}

import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[app-minmax][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: MinMaxNumberValidator, multi: true }
      ]
})
export class MinMaxNumberValidator implements Validator {

    @Input() min: number;
    @Input() max: number;
    validate(control: AbstractControl): ValidationErrors {
        if (control.value < this.min) {
            return {
                minmax: {
                    valid: false
                }
            };
        } else if (control.value > this.max) {
            return {
                minmax: {
                    valid: false
                }
            };
        }
    }
}

<ng-template #iHaveVinPopup let-modal>
    <section class="popup_title_grey sm">
        <h1 *ngIf="vehicleType === 'newcar'">{{'dealer.chooseOption.newCar' | translate}}</h1>
        <h1 *ngIf="vehicleType === 'usedcar'">{{'dealer.chooseOption.usedCar' | translate}}</h1>
        <a href="javascript:void(0);" class="close_icon" (click)="modal.dismiss(); dismiss()">
            <svg class="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clip-rule="evenodd"/>
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clip-rule="evenodd"/>
            </svg>
        </a>
    </section>
    <form [formGroup]="formVinSearch" autocomplete="off">
        <section class="popup_cnt sm">
            <div class="row">        
                <div class="col-12"><h5 class="add_vin"> {{ 'usedCar.addVin' | translate }} </h5></div>    
                <div class="col-xl-5 col-md-5">
                    <div class="floating_label_wrp" [class.error]="controls?.addVinNumPrefix?.touched && controls?.addVinNumPrefix?.errors">
                        <input type="text" class="floating_label_field" (keyup)="prefixConvert()" formControlName="addVinNumPrefix" maxlength="9" placeholder="Enter Vin" id="vin_number_seg_one">
                        <div *ngIf="controls?.addVinNumPrefix?.touched && controls?.addVinNumPrefix?.errors" class="form-invalid">
                            <div *ngIf="controls?.addVinNumPrefix?.errors?.required"> {{ 'usedCar.vMsg.vinReq' | translate }} </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-md-2 guion maxw-20 px-0"><i class="fa fa-minus" aria-hidden="true"></i></div>
                <div class="col-xl-5 col-md-5">
                    <div class="floating_label_wrp" [class.error]="controls?.addVinNumPostfix?.touched && controls?.addVinNumPostfix?.errors">
                        <input type="tel" oninput="this.value=this.value.replace(/[^0-9]/g,'');" class="floating_label_field"  formControlName="addVinNumPostfix" maxlength="8" placeholder="Enter Vin" id="vin_number_seg_two">
                        <div *ngIf="controls?.addVinNumPostfix?.touched && controls?.addVinNumPostfix?.errors" class="form-invalid">
                            <div *ngIf="controls?.addVinNumPostfix?.errors?.required"> {{ 'usedCar.vMsg.vinReq' | translate }} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-xl-12">
                    <div class="floating_label_wrp naviId-field" [class.error]="controls?.naviId?.touched && controls?.naviId?.errors">
                        <input type="text" class="floating_label_field" id="navi_id" placeholder="NAVI ID" formControlName="naviId" oninput="this.value=this.value.toUpperCase();" maxlength="10">
                        <label class="floating_label" for="navi_id" > {{'usedCar.naviId' | translate}} </label>
                        <div *ngIf="controls?.naviId?.touched && controls?.naviId?.errors" class="form-invalid">
                            <div *ngIf="controls?.naviId?.errors?.maxlength || controls?.naviId?.errors?.minlength || controls?.naviId?.errors?.invalid_navi_id"> {{ 'usedCar.vMsg.naviIdMaxLength' | translate }} </div>
                        </div>
                    </div>
                    <div><a style="font-size: 12px;">{{ 'usedCar.nonSos' | translate }} <br>{{ 'usedCar.nonSos1' | translate }} </a><br></div>
                    <div>
                        <a class="link font-12"  href="javascript:void(0)" (click)="showConfirmationHelp()">{{ 'usedCar.confirmationMethodLink' | translate }}</a>
                        <br/>
                        <a class="link font-12"  href="javascript:void(0)" (click)="openNaviIDConfirmationLink()">{{ 'usedCar.mopConfirmationMethodLink' | translate }}</a>
                        </div>
                </div>
            </div>
        </section>    
        <section class="popup_button sm">    
            <hr class="border_line" />        
            <button class="primary_button ml-3" (click)="checkVIN()" [disabled]="formVinSearch.invalid || formSubmitted"> {{'usedCar.next' | translate}} </button>
            <button type="button" class="outline_button" (click)="modal.dismiss(); dismiss()"> {{'usedCar.cancel' | translate}} </button>
        </section>
    </form>
</ng-template>

<ng-template #vinSearchingPopup let-modal>
    <app-vin-checking-progress-bar [modal]="modal" [uploadResponse]="uploadResponse" (vinSearchCompleted)="dismiss()" [redirectURL]="'/dealer/used-car/vehicle-identification'"></app-vin-checking-progress-bar>
</ng-template>

<ng-template #showConfirmationMethodHelpDesc let-modal>
    <app-confirmation-method-popup [modal]="modal"></app-confirmation-method-popup>
</ng-template>
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionExpiredComponent } from '@shared/components/session-expired/session-expired.component';
import { UtilService } from '@shared/utils/utils.service';
import { throwError } from 'rxjs';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

    constructor(private modalService: NgbModal, private utilService: UtilService, private sanitizer: DomSanitizer) {}
    private modalRef;
  /**
   * @method handleError Hooks into when any of the API returns an error response.
   * @param error error object returned from the interceptor.
   * @description Error message to be displayed or logged depending on the error returned
   */

    handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
        // Backend returns unsuccessful response codes such as 404, 500 etc.
          switch (error.status) {
            case 400:
              console.log('Bad Request.');
              break;
            case 401:
                this.Case401(error);
                break;
            case 403:
              const completeURLPath = location.href;
              const urlPath = completeURLPath.split('?')[0];
              if (urlPath.split('/').pop() == '' || window.location.href.indexOf('/dealer') > -1 || (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'dealer')) {
                this.dismissModal();
                this.modalRef = this.modalService.open(SessionExpiredComponent, {centered: false, windowClass: 'alignMiddle', backdrop : 'static', keyboard : false});
                setTimeout(() => {
                  const keyValue=this.utilService.getUrlParameter(window.location.href, 'key');
                  const expiryValue=this.utilService.getUrlParameter(window.location.href, 'expiry');
                  let renewSubscription= {
                    key:keyValue,
                    expiry:expiryValue
                  };
                  if((keyValue && keyValue !=='')|| (expiryValue && expiryValue !=='')){
                    sessionStorage.setItem('renewSubscription',JSON.stringify(renewSubscription));
                  }
                  const dToken = localStorage.getItem('d_token');
                  localStorage.removeItem('d_token');
                  localStorage.removeItem('formData');
                  if (dToken) {
                    const URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_LOGOUT_URL}?token=${dToken}`);
                    window.location.href = URL;
                  } else {
                    const URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_URL}`);
                    window.location.href = URL;
                  }
                }, 3000);
              } else {
                console.log('Forbidden.');
              }
              break;
            case 404:
              console.log('Cannot find requested resource.');
              break;
            case 408:
              console.log('Request Timeout.');
              break;
            case 413:
              console.log('Payload Too Large.');
              break;
            case 421:
              console.log('Misdirected Request.');
              break;
            case 429:
              console.log('Too Many Requests.');
              break;
            case 500:
              console.log('Server Error.');
              break;
            case 502:
              console.log('Bad Gateway.');
              break;
            case 503:
              console.log('Service Unavailable.');
              break;
            case 504:
              console.log('Gateway Timeout.');
              break;
            default:
              return throwError(error);
          }
      } else {
          // A client-side or network error occurred.
          console.error('An error occurred:', error.message);
      }
    }
Case401(error){
  if (error.error.error === 'invalid_token') {
        console.log('Token expired');
    } else {
        console.log('Unauthorized.');
    }
  }

  dismissModal(){
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DealerState } from 'app/store/dealer/state/dealer.state';
import { Subscription } from 'rxjs';
import { eFlag } from '@shared/constants/dealer.constants';
@Component({
  selector: 'app-initial-entry-completion',
  templateUrl: './initial-entry-completion.component.html',
  styleUrls: ['./initial-entry-completion.component.scss']
})
export class InitialEntryCompletionComponent implements OnInit,AfterViewInit {
  public dealerType: string;
  public dealerInfo: any;
  public isCA: boolean = false;
  public isLC: boolean = false;
  public isIb4: boolean = false;
  public isNFS: boolean = false;
  public isJf0: boolean = false;
  public isKameari: boolean = false;
  public isPrintPdf: boolean = false;
  public showLoader: boolean = false;
  public currentLang: string = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
  @Input() summaryData: any;
  @Output() closePopup = new EventEmitter();
  @Input() selectedComponent: any;
  public qrString: string;
  public isPrint: boolean = false;
  subscription1: Subscription;
  constructor(private router: Router, private store: Store<DealerState>,public translate: TranslateService,) { }

  ngOnInit(): void {
    this.dealerTypeCheck();
    this.currentLang= localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
    this.qrString = environment.UI_URL + `customer/ev-fleet/customer-entry?${eFlag.key}=${eFlag.value}&` + this.summaryData.token;
    /*****QR CODE COMMENT */
    /* setTimeout(() => {
      if (!this.isPrint) {
        const canvas = document.getElementsByTagName("canvas")
        const img = canvas[0].toDataURL('image/png');
        (<HTMLImageElement>document.getElementById('qrCodeImage')).src = img;
      }
      this.isPrint = true;
    }) */
    console.log('selectedComponent', this.selectedComponent)
  }

  dealerTypeCheck() {
    this.subscription1 = this.store.select('dealerInfo').subscribe((data) => {
      this.dealerInfo = data;
      if (data && data.dealerType) {
        this.dealerType = data.dealerType;
        if (data.dealerType === 'CA') {
          this.isCA = true;
        } else if (data.dealerType === 'LC') {
          this.isLC = true;
        } else if (data.dealerType === 'NFS') {
          this.isNFS = true;
        } else if (data.dealerType === 'IB4') {
          this.isIb4 = true;
        } else if (data.dealerType === 'JF0') {
          this.isJf0 = true;
        } else if (data.dealerType === 'Kameari') {
          this.isKameari = true;
          this.router.navigateByUrl('/error/404?redirectURL=dealer');
        }
      }
    });
  }

  goToCustomerPortal(url: any) {
    window.open(url, '_blank');
  }

  summarPopupClose() {
    this.closePopup.emit();
    localStorage.removeItem('colorPaletteData');
    localStorage.removeItem('evf_admission_data');
    this.router.navigateByUrl('/dealer/ev-fleet')
  }

  print() {
    try {
      if (!this.isPrintPdf) {
        this.isPrintPdf = true;
        this.showLoader = true;
        let elementId = "summaryPrintFrame";
        let elementExists = document.getElementById(elementId);
        if (elementExists !== null) {
          elementExists.remove();
        }
        let fontFamily = this.currentLang === 'jp' ? 'Meiryo UI Regular' : 'NissanBrand-Regular';
        let printContents = document.getElementById("printSection").innerHTML;
        let frame1: any = document.createElement('iframe');
        frame1.name = "summary-print-frame";
        frame1.id = elementId;
        frame1.style.position = "absolute";
        frame1.style.top = "-1000000px";
        document.body.appendChild(frame1);
        let frameDoc;
        if(frame1.contentWindow){
          frameDoc = frame1.contentWindow
        }else if(frame1.contentDocument.document){
          frameDoc = frame1.contentDocument.document
        }else{
          frameDoc = frame1.contentDocument
        }
        frameDoc.document.open();
        frameDoc.document.write(`
      <html>
  
      <head>
          <title>EVF Admission</title>
          <link rel="stylesheet" type="text/css"
              href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" media="print">
          <link rel="stylesheet" type="text/css"
              href="${environment.BASE_URL}/assets/scss/evf-summary-print.css" media="print">
              <link href='https://fonts.googleapis.com/css?family=Noto Sans JP' rel='stylesheet' />
          <style>
          /** Nissan Regular Font For EN*/
          @font-face {
              font-family: 'NissanBrand-Regular';
              src: url('${environment.BASE_URL}/assets/fonts/nissan/NissanBrand-Regular.woff') format('woff'),
                      url('${environment.BASE_URL}/assets/fonts/nissan/NissanBrand-Regular.woff2') format('woff2');
              font-weight: normal;
              font-style: normal;
          }
          @font-face {
              font-family: 'NissanBrand-Light';
              src: url('${environment.BASE_URL}/assets/fonts/nissan/NissanBrand-Light.woff') format('woff'),
                   url('${environment.BASE_URL}/assets/fonts/nissan/NissanBrand-Light.woff2') format('woff2');
              font-weight: normal;
              font-style: normal;
          }
          @font-face {
              font-family: 'NissanBrand-Italic';
              src: url('${environment.BASE_URL}/assets/fonts/nissan/NissanBrand-Italic.woff') format('woff'),
                   url('${environment.BASE_URL}/assets/fonts/nissan/NissanBrand-Italic.woff2') format('woff2');
              font-weight: normal;
              font-style: normal;
          }
          @font-face {
              font-family: 'NissanBrand-Bold';
              src: url('${environment.BASE_URL}/assets/fonts/nissan/NissanBrand-Bold.woff') format('woff'),
                   url('${environment.BASE_URL}/assets/fonts/nissan//NissanBrand-Bold.woff2') format('woff2');
              font-weight: normal;
              font-style: normal;
          }
  
              body{
                font-family: ${fontFamily};
              }
      
              
          </style>
      </head>
      
      <body onload="printWindow()">
          
      <script src="https://code.jquery.com/jquery-3.6.4.js" crossorigin="anonymous"></script>
          ${printContents}
      </body>
      <script>
      function printWindow(){
        setTimeout(function() {
          window.print();
          window.close();
        },3000)
      }
      </script>
      </html>`);
        frameDoc.document.close();
        setTimeout(() => {
          this.isPrintPdf = false;
          this.showLoader = false;
        }, 7000)
        return false;
      }
    } catch (error) {
      console.log(error)
      this.isPrintPdf = false;
      this.showLoader = false;
    }

  }
  ngAfterViewInit() {
    setTimeout(() => {
      console.log('selectedComponent', this.selectedComponent)
    if (this.selectedComponent === 'editDetailsPrint') {
      this.showLoader = true;
      this.print();
      this.selectedComponent = '';
    }
  });
  }
  ngOnDestroy(){
    if(this.subscription1){
      this.subscription1.unsubscribe();
    }
  }
}

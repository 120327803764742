import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoaderService } from '@shared/components/loader/service/loader.service';
import { EvFleetState } from 'app/store/evfleet/state/evfleet.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ev-fleet-layout',
  templateUrl: './ev-fleet-layout.component.html',
  styleUrls: ['./ev-fleet-layout.component.scss']
})
export class EvFleetLayoutComponent implements OnInit {
  public urlValid: boolean = true;
  public oldUrl: boolean = false;
  public alreadySubscribed: boolean = false;
  public currentLang = localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp';
  subscription1: Subscription;
  constructor(private loaderService: LoaderService,private evfleetstore: Store<EvFleetState>) {
    this.subscription1 = this.evfleetstore.select('evFleetstepCount').subscribe((response) => {
      if(response && response.status_en === 'CUSTOMER_ENTRY_COMPLETED'){
        this.alreadySubscribed = true;
        if(localStorage.getItem('evf_c_token')){
          localStorage.removeItem('evf_c_token');
        }
      }else if(response && response.status_en === 'INITIAL_ENTRY_COMPLETED' && response.isValidUrl === false){
        this.urlValid = false;
      }else if(response && response.status == 403){
        this.oldUrl = true;
      }
    });
    
   }

  ngOnInit(): void {
    try {
      this.loaderService.removeloaderBody();

    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
    if(this.subscription1){
      this.subscription1.unsubscribe();
    }
  }
}

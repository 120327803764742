import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@shared/components/components.module';
import { EvFleetLayoutComponent } from './ev-fleet-layout.component';
import { HeaderComponent } from './header/header.component';
import { BreadcrumbEvfleetComponent } from './header/breadcrumb/breadcrumb-evfleet.component';

@NgModule({
  declarations: [EvFleetLayoutComponent, HeaderComponent, BreadcrumbEvfleetComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ComponentsModule
  ]
})
export class EvFleetLayoutModule { }

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CustomDateParserFormatter } from '@core/services/datepicker/customer-datepicker';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { InterceptorService } from '@core/services/interceptors/http.interceptor';
import { CustomPreloadingStrategy } from '@core/services/preloading.service';
import { WebSocketService } from '@core/services/websocket.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ComponentsModule } from '@shared/components/components.module';
import { scrollBarConfig } from '@shared/constants/scroll.constants';
import { DirectivesModule } from '@shared/directives/directives.module';
import { SharedModule } from '@shared/shared.module';
import { SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerUsedCarReducers } from './store/customer-used-car/reducers/customer-used-car.reducer';
import { CustomerReducers } from './store/customer/reducers/customer.reducer';
import { DealerReducers } from './store/dealer/reducers/dealer.reducer';
import { EvFleetReducers } from './store/evfleet/reducers/evfleet.reducer';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot({...DealerReducers, ...CustomerReducers, ...CustomerUsedCarReducers, ...EvFleetReducers}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    ComponentsModule,
    DirectivesModule
  ],
  providers: [
    ErrorHandlerService,
    CustomPreloadingStrategy,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      provide: SLIMSCROLL_DEFAULTS,
      useValue: scrollBarConfig
    },
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    WebSocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ComponentsModule } from '@shared/components/components.module';
import { BizConnectEmailLayoutComponent } from './biz-connect-email-layout.component';
import { HeaderComponent } from './header/header.component';



@NgModule({
  declarations: [BizConnectEmailLayoutComponent, HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    TranslateModule
  ]
})
export class BizConnectEmailLayoutModule { }

import * as DealerActions from '../actions/dealer.actions';
import { DealerState } from '../state/dealer.state';


export function vehicleInfoReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_VEHICLE_INFO:
            return [...state, ...action.payload ];

        case DealerActions.FETCH_VEHICLE_INFO:
            return state;

        default:
            return state;
    }
}

export function bizconnectPackagePlan(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_PACKAGE_PLAN_BIZ_CONNECT:
            return {...state, ...action.payload };

   

        default:
            return state;
    }
}

export function dealerInfoReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_DEALER_INFO:
            return {...state, ...action.payload };

        case DealerActions.FETCH_DEALER_INFO:
            return state;

        default:
            return state;
    }
}

export function reasonTypesReducerPaper(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_REASON_TYPES_PAPER:
            return [...state, ...action.payload ];

        case DealerActions.FETCH_REASON_TYPES_PAPER:
            return state;

        default:
            return state;
    }
}

export function reasonTypesReducerNoThanks(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_REASON_TYPES_NO_THANKS:
            return [...state, ...action.payload ];

        case DealerActions.FETCH_REASON_TYPES_NO_THANKS:
            return state;

        default:
            return state;
    }
}

export function paymentTypesReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_PAYMENT_TYPES:
            return [...state, ...action.payload ];

        case DealerActions.FETCH_PAYMENT_TYPES:
            return state;

        default:
            return state;
    }
}

export function recentAdmissionDetailsReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_RECENT_ADMISSION_DETAILS:
            return {...state, ...action.payload };

        case DealerActions.FETCH_RECENT_ADMISSION_DETAILS:
            return state;

        default:
            return state;
    }
}

export function carouselImageReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_CAROUSEL_IMAGES:
            return [...state, ...action.payload ];

        case DealerActions.FETCH_CAROUSEL_IMAGES:
            return state;

        default:
            return state;
    }
}

/** Used Car */

export function usedCarVehicleInfoReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_USEDCAR_VEHICLE_INFO:
            return {...state, ...action.payload };

        case DealerActions.FETCH_USEDCAR_VEHICLE_INFO:
            return state;

        default:
            return state;
    }
}

/** Used Car */

export function newCarVehicleInfoReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_NEWCAR_VEHICLE_INFO:
            return {...state, ...action.payload };

        case DealerActions.FETCH_NEWCAR_VEHICLE_INFO:
            return state;

        default:
            return state;
    }
}

export function ncExpiryReminderSettingsInfoReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_NCID_REMINDER_INFO:
            return {...state, ...action.payload };

        case DealerActions.FETCH_NCID_REMINDER_INFO:
            return state;

        default:
            return state;
    }
}

export function naviidReminderSettingInfoReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_NAVIID_REMINDER_INFO:
            return {...state, ...action.payload };

        case DealerActions.FETCH_NAVIID_REMINDER_INFO:
            return state;

        default:
            return state;
    }
}

/** Dealer Master Data */

export function dealerMasterdataInfoReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_DEALER_MASTERDATA_INFO:
            return {...state, ...action.payload };

        case DealerActions.FETCH_DEALER_MASTERDATA_INFO:
            return state;

        default:
            return state;
    }
}

export function coloPalletteReducer(state = [], action) {
    switch (action.type) {

        case DealerActions.SAVE_COLOR_PALLETTE_DATA:
            return {...state, ...action.payload };

        case DealerActions.FETCH_COLOR_PALLETTE_DATA:
            return state;

        default:
            return state;
    }
}




export const DealerReducers: DealerState = {
    vehicleInfo: vehicleInfoReducer,
    dealerInfo: dealerInfoReducer,
    reasonTypesPaper: reasonTypesReducerPaper,
    reasonTypesNoThanks: reasonTypesReducerNoThanks,
    paymentTypes: paymentTypesReducer,
    recentAdmissionDetails: recentAdmissionDetailsReducer,
    carouselImages: carouselImageReducer,
    usedCarVehicleInfo: usedCarVehicleInfoReducer,
    newCarVehicleInfo: newCarVehicleInfoReducer,
    ncExpiryReminderSettingsInfo: ncExpiryReminderSettingsInfoReducer,
    naviidReminderSettingsInfo: naviidReminderSettingInfoReducer,
    dealerMasterdataInfo: dealerMasterdataInfoReducer,
    packagePlanInfo:bizconnectPackagePlan,
    coloPalletteInfo: coloPalletteReducer,
  };

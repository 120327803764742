import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalValidator } from '@shared/validations/validators';
import { regex } from '@shared/constants/regex.constants';
import { ToastService } from '@shared/components/toaster/service/toaster.service';

import { GeneralService } from '@core/services/generic/general.service';

import { DealerState } from 'app/store/dealer/state/dealer.state';
import { SaveUsedCarVehicleInfo } from 'app/store/dealer/actions/dealer.actions';
import { HttpClient } from '@angular/common/http';
import { regexExp} from '@shared/constants/customer.constants';
  

@Component({
  selector: 'app-vin-search',
  templateUrl: './vin-search.component.html',
  styleUrls: ['./vin-search.component.scss']
})
export class VinSearchComponent implements OnInit {
  formVinSearch: FormGroup;
  @ViewChild('iHaveVinPopup', {static : true}) iHaveVinPopupElementView: ElementRef;
  @ViewChild('vinSearchingPopup', {static : true}) vinSearchingPopupElementView: ElementRef;
  @ViewChild('showConfirmationMethodHelpDesc', {static : true}) showConfirmationMethodHelpDesc: ElementRef;
  @ViewChild('showNaviMissmatchError', {static : true}) showNaviMissmatchErrorView: ElementRef;
  @Output() hideVinSearch = new EventEmitter();
  @Input() vehicleType;

  public maxDate = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};
  public uploadResponse =  { status: 'progress', message: 0, data: {}};
  public interval;
  public currentLang: string;
  public formSubmitted: boolean;
  addVinNum_prefix: any;
  errorMsg: any;
  
  constructor( private modalService: NgbModal, private formBuilder: FormBuilder,
               private generalService: GeneralService, private store: Store<DealerState>,
               private router: Router, private toastService: ToastService,
               private httpClient: HttpClient ) { }

  /**
   * @method ngOnInit
   * @description Form Initialization.
   */

  ngOnInit(): void {
    this.currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
    this.buildVinSearchForm();
    this.openVinSearchPopup();
  }

  /**
   * @method openVinSearchPopup
   * @description Show Vin search Popup.
   */
  openVinSearchPopup() {
    try {
      const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
      this.modalService.open(this.iHaveVinPopupElementView, initialState);
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method buildVinSearchForm
   * @description Form Initialization
   */

  buildVinSearchForm() {
    try {
      this.formVinSearch = this.formBuilder.group({
        addVinNumPrefix: [null, [GlobalValidator.nonEmpty]],
        addVinNumPostfix: [null, [GlobalValidator.nonEmpty]],
        naviId: [null, [Validators.maxLength(10),Validators.minLength(10),GlobalValidator.validNaviId]],
        vehicleType: [this.vehicleType]
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method controls
   * @description Returns form controls.
   */

  get controls() {
    return this.formVinSearch.controls;
  }

  dismiss() {
    try {
      this.hideVinSearch.emit();
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method vinSearchCompleted
   * @description Navigation after vin search
   */

  vinSearchCompleted() {
    try {
      this.modalService.dismissAll();
      this.router.navigate(['/dealer/used-car/vehicle-identification']);
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method checkVIN
   * @description Checking vehicle Info
   */

  checkVIN() {
    try {
      this.modalService.dismissAll();
      const initialState = { centered: false, windowClass: 'alignTop' };
      this.modalService.open(this.vinSearchingPopupElementView, initialState);
      this.formSubmitted = true;
      const vinNumber = (this.controls.addVinNumPrefix.value.toUpperCase()) + '-' + (this.controls.addVinNumPostfix.value.toUpperCase());
      const postData = {
        vinNumber,
        naviId : (this.controls.naviId.value) ? this.controls.naviId.value.toUpperCase() : '',
        vehicleType : this.controls.vehicleType.value,
        source : 'dealer'
      };
      this.generalService.post(postData, this.currentLang + '/vehicleinfo', {}, 'carwings').subscribe((res) => {
        this.formSubmitted = false;
        res.data.vinNumber = vinNumber;
        res.data.vehicleType = this.vehicleType;
        res.data.preSelectNaviType = res.data.dopFlg;
        localStorage.setItem('vehicleInfo' , JSON.stringify(res.data));
        this.hideVinSearch.emit();
        this.store.dispatch(new SaveUsedCarVehicleInfo(res.data));
        this.uploadResponse = { status: 'progress', message: 100, data: res.data };
      }, err => {
        this.formSubmitted = false;
        this.hideVinSearch.emit();
        this.modalService.dismissAll();
        if (err.status == 400) {
          this.toastService.showModalResponse(err);
        } else {
          this.toastService.showUnexpectedError(err);
        }
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method showConfirmationHelp
   * @desc Show confirmation Help Popup
   */

  showConfirmationHelp() {
    const initialState = { size: 'xl', centered: false, windowClass: 'align70' };
    this.modalService.open(this.showConfirmationMethodHelpDesc, initialState);
  }

  openPdfInNewTab(url) {
    try {
      this.httpClient.get(url, {responseType: 'arraybuffer'}).subscribe(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      });
    } catch (error) {
      console.error(error);
    }
  }

  openNaviIDConfirmationLink() {
    if (this.currentLang == 'en') {
      let url = '../assets/templates/navi-id-confirmation-method/confirmation_method-en.pdf'
      this.openPdfInNewTab(url)
    } else if (this.currentLang == 'jp') {
      let url = '../assets/templates/navi-id-confirmation-method/confirmation_method-jp.pdf'
      this.openPdfInNewTab(url)
    }
  }


  /**
   * @method prefixConvert
   * @description Vin separation validation.
   */
  prefixConvert() {
    this.addVinNum_prefix = this.controls.addVinNumPrefix.value;
    if (this.addVinNum_prefix) {
      if (regex.FULL_WIDTH_VIN.test(this.addVinNum_prefix) && this.addVinNum_prefix.length <= 9) {
        this.addVinNum_prefix = this.addVinNum_prefix.replace(/[OIQoiq]/g, '').toUpperCase();
        this.controls.addVinNumPrefix.setValue(this.addVinNum_prefix);
      } else {
        this.addVinNum_prefix = this.addVinNum_prefix.substring(0, this.addVinNum_prefix.length - 1).replace(regexExp.vinExp, '');
        this.controls.addVinNumPrefix.setValue(this.addVinNum_prefix);
        return false;
      }
    }
  }
}

<section class="customer_wrp mt-md-5 error_page_wrap">
    <div class="container h-100">
        <div class="row align-items-xl-center h-100">
            <div class="col-xl-9 col-lg-9 mx-auto text-center p-xs-1">
                <p *ngIf="errorStatus === 'expired' && currentLang === 'jp'">{{ 'pageNotFound' | translate }}</p>
                <p [ngClass]="{'page-expired-jp-text': errorStatus === 'expired' && currentLang === 'jp'}">{{errorMsg}}</p>
                <ng-container>
                    <button (click)="navigateToHomePage()" class="outline_button mt-4">{{ctaText}}
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</section>

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[nextField]'
})

export class FocusnextDirective {
    @Input() nextField: string;

    constructor(private el: ElementRef) { }

    @HostListener('keyup', ['$event'])
    onKeyUp() {
        const element = this.el.nativeElement.id;
        const length = this.el.nativeElement.value.trim().length;
        if ((element !== 'vin_number_seg_one' && length === 1) || element === 'vin_number_seg_one' && length === 9) {
            this.nextField ? document
                  .getElementById(this.nextField)
                  .focus() : document.getElementById(this.el.nativeElement.id).blur();
        }
    }
}

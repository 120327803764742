import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '@shared/utils/utils.service';
import { ToastService } from '../toaster/service/toaster.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  public errorTitle;
  public errorDesc;
  public homePage;
  public currentnissanConnectLang;
  public isNissanConnectModule= false;
  public isHomePageButton: boolean = true;
  constructor(private route: ActivatedRoute, private router: Router,
              private modalService: NgbModal, private toastService: ToastService,
              private translate: TranslateService, private utilService: UtilService) {
    try {
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
    };
      this.route.data.subscribe(v => {
        const completeURLPath = location.href;
        const urlPath = completeURLPath.split('?')[0];
        if (urlPath.split('/').pop() == '' || window.location.href.indexOf('/dealer') > -1 || (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'dealer')) {
          this.dealer_dLang();
        } else if (window.location.href.indexOf('customer') > -1 || window.location.href.split('/').pop() == 'customer' ||
                  (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'customer')) {
                    this.customer_cLang();
        } else if (window.location.href.indexOf('nissan-connect') > -1 || window.location.href.split('/').pop() == 'nissan-connect' ||
        (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'nissan-connect')) {
          this.nissanConnect_nLang();
          this.isNissanConnectModule = true;
        }
        if (v.status === '404') {
          this.getTranslatedMsgs('404Title','404Desc');
        } else {
          this.getTranslatedMsgCheck();
        }
        this.translate.get('homePage').subscribe((text: string) => {
          this.homePage = text;
        });
        if(this.route.snapshot.queryParamMap.get('redirectURL') == 'customer/ev-fleet'){
          this.isHomePageButton = false;
        }
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  ngOnInit(): void {
    this.modalService.dismissAll();
  }

  navigateToHomePage() {
    try {
      if (this.route.snapshot.queryParamMap.get('redirectURL') == 'customer/admission') {
        this.router.navigate(['/customer/admission/']);
      } else if (this.route.snapshot.queryParamMap.get('redirectURL') == 'customer') {
        this.router.navigate(['/customer']);
      } else if (this.route.snapshot.queryParamMap.get('redirectURL') == 'dealer') {
        this.router.navigate(['/dealer']);
      } else if (this.route.snapshot.queryParamMap.get('redirectURL') == 'nissan-connect') {
        this.router.navigate(['/nissan-connect']);
      } else {
        if (localStorage.getItem('d_token')) {
          this.router.navigate(['/dealer']);
        } else if (localStorage.getItem('c_token')) {
          this.router.navigate(['/customer']);
        }
      }
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

/**
  * @method dealer_dLang Method to use language depending on dealer side.
  */
  dealer_dLang(){
    this.translate.use(localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp');
  }

/**
  * @method customer_cLang Method to use language depending on customer side.
  */
  customer_cLang(){
    this.translate.use(localStorage.getItem('cLang') ? localStorage.getItem('dLang') : 'jp');
  }

/**
  * @method nissanConnect_nLang Method to use language depending on nissanconnect.
  */
  nissanConnect_nLang(){
    this.translate.use(localStorage.getItem('nLang') ? localStorage.getItem('nLang') : 'jp');
    this.currentnissanConnectLang = localStorage.getItem('nLang') ? localStorage.getItem('nLang') : 'jp';
  }
  getTranslatedMsgs(title,desc) {
    this.translate.get(title).subscribe((text: string) => {
      this.errorTitle = text;
    });
    this.translate.get(desc).subscribe((text: string) => {
      this.errorDesc = text;
    });
  }
  getTranslatedMsgCheck(){
    if(this.route.snapshot.queryParamMap.get('redirectURL') == 'nissan-connect'){
      this.getTranslatedMsgs('systemErrorHeading','contactKameari');
    } else{
      this.getTranslatedMsgs('500Title','500Desc');
    }
  }
}

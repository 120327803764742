import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalResponseComponent } from '@shared/components/modal-response/modal-response.component';
import { UxexpectedErrorResponseComponent } from '@shared/components/uxexpected-error-response/uxexpected-error-response.component';
import { UtilService } from '@shared/utils/utils.service';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private modalService: NgbModal, private utilService: UtilService,
              private translate: TranslateService) {}
  toasts: any[] = [];
  modal: NgbModalRef;

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  // tslint:disable-next-line: variable-name
  showUnexpectedError(_err = null) {
    const errorTxt = (_err && _err.error && _err.error.message) ? _err.error.message :  this.getTranslatedErrorMsg();
    const options = { classname: 'bg-danger', delay: 5000};
    this.show(errorTxt, options);
  }
   // tslint:disable-next-line: variable-name
  showModalResponse(_err = null) {
    let errorTxt = (_err && _err.error && _err.error.message) ? _err.error.message : this.getTranslatedErrorMsg();
    this.translate.get('errorMessages.'.concat(errorTxt)).subscribe((text: string) => {
      if (text.indexOf('errorMessages.') === -1) {
        errorTxt = text;
      }
    });
    if (this.modal) {
      this.modal.dismiss();
    }
    this.modal = this.modalService.open(ModalResponseComponent, {centered: false, backdrop : 'static', keyboard : false,  windowClass: 'alignTop'});
    this.modal.componentInstance.status = false;
    this.modal.componentInstance.responseMsg = errorTxt;
    this.modal.componentInstance.modalRef = this.modal;
  }

   // tslint:disable-next-line: variable-name
   showSuccessModalResponse(_res = null) {
    const successTxt = (_res && _res.response && _res.response.message) ? _res.response.message :  this.getTranslatedErrorMsg();
    if (this.modal) {
      this.modal.dismiss();
    }
    this.modal = this.modalService.open(ModalResponseComponent, {centered: false, backdrop : 'static', keyboard : false,  windowClass: 'alignTop'});
    this.modal.componentInstance.status = true;
    this.modal.componentInstance.responseMsg = successTxt;
    this.modal.componentInstance.modalRef = this.modal;
  }

  getTranslatedErrorMsg() {
    const completeURLPath = location.href;
    let translatedText;
    const urlPath = completeURLPath.split('?')[0];
    if (urlPath.split('/').pop() == '' || window.location.href.indexOf('/dealer') > -1 || (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'dealer')) {
      this.dealer_dLang();
    } else if (window.location.href.indexOf('customer') > -1 || window.location.href.split('/').pop() == 'customer' ||
              (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'customer')) {
      this.translate.use(localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp');
    }  else if (window.location.href.indexOf('customer/admission') > -1 || window.location.href.split('/').pop() == 'customer/admission' ||
            (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'customer/admission')) {
        this.translate.use(localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp');
    } else if (window.location.href.indexOf('nissan-connect') > -1 || window.location.href.split('/').pop() == 'nissan-connect' ||
    (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'nissan-connect')) {
      this.translate.use(localStorage.getItem('nLang') ? localStorage.getItem('nLang') : 'jp');
    }
    this.translate.get('unexpectdError').subscribe((text: string) => {
      translatedText =  text;
    });
    return translatedText;
  }

/**
  * @method dealer_dLang Method to use language depending on dealer side.
  */

  dealer_dLang(){
    this.translate.use(localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp');
  }
  
  showUnexpectedErrorResponse(_err = null) {
    if (this.modal) {
      this.modal.dismiss();
    }
    if(_err !== null){
      this.modal = this.modalService.open(UxexpectedErrorResponseComponent, {centered: false, backdrop : 'static', keyboard : false,  windowClass: 'align70'});
      this.modal.componentInstance.modalRef = this.modal;
    }
  }

  showRenewalSuccessModalResponse(_res = null) {
    const responseMsg1 = (_res && _res.response && _res.response.message1) ? _res.response.message1 : '';
    const responseMsg2 = (_res && _res.response && _res.response.message2) ? _res.response.message2 : '';
    const headerTxt = (_res && _res.response && _res.response.header) ? _res.response.header : '';
    const closeTxt = (_res && _res.response && _res.response.close) ? _res.response.close : '';
    if (this.modal) {
      this.modal.dismiss();
    }
    this.modal = this.modalService.open(ModalResponseComponent, {centered: false, backdrop : 'static', keyboard : false,  windowClass: 'alignTop'});
    this.modal.componentInstance.status = true;
    this.modal.componentInstance.modalRef = this.modal;
    this.modal.componentInstance.headerTxt = headerTxt;
    this.modal.componentInstance.renewalResponseMsg1 = responseMsg1;
    this.modal.componentInstance.renewalResponseMsg2 = responseMsg2;
    this.modal.componentInstance.closeTxt = closeTxt;
    this.modal.componentInstance.saveSuccess = true;
  }

  showIssuanceSuccessModalResponse(_res = null) {
    const successTxt1 = (_res && _res.response && _res.response.message1) ? _res.response.message1 :  this.getTranslatedErrorMsg();
    const successTxt2 = (_res && _res.response && _res.response.message2) ? _res.response.message2 :  this.getTranslatedErrorMsg();

    if (this.modal) {
      this.modal.dismiss();
    }
    this.modal = this.modalService.open(ModalResponseComponent, {centered: false, backdrop : 'static', keyboard : false,  windowClass: 'alignTop70'});
    this.modal.componentInstance.status = true;
    this.modal.componentInstance.responseMsg = successTxt1;
    this.modal.componentInstance.responseMsgIssuance = successTxt2;
    this.modal.componentInstance.isIssuance = true;
    this.modal.componentInstance.modalRef = this.modal;
  }
}

<ng-template #ncReminderSettingsPopup let-modal>
    <section class="popup_title_grey sm">
        <h1> {{ 'dealer.header.ncExpiry' | translate }} </h1>
        <a href="javascript:void(0);" class="close_icon" (click)="modal.dismiss(); dismiss()">
            <svg class="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clip-rule="evenodd"/>
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clip-rule="evenodd"/>
            </svg>
        </a>
    </section>
    <form [formGroup]="ncExpiryReminderSettingsForm" autocomplete="off">
        <section class="popup_cnt sm">
            <div class="row" *ngFor="let setting of arrayOfcontrols.controls; let i = index">
                <div class="col-xl-12">
                    <div class="row" [formGroup]="setting" >
                        <div class="col-xl-6 mt-4">
                            <span> {{setting?.controls?.setting?.value }} </span>
                        </div>
                        <div class="col-xl-6">
                            <div class="floating_label_wrp" [class.error]="setting?.controls?.value?.touched && setting?.controls?.value?.errors">
                                <input type="number" class="floating_label_field" formControlName="value">
                                <div *ngIf="setting?.controls?.value?.touched && setting?.controls?.value?.errors" class="form-invalid">
                                    <div *ngIf="setting?.controls?.value?.errors?.required && currentLang==='en'"> {{setting?.controls?.setting?.value }} is required </div>
                                    <div *ngIf="setting?.controls?.value?.errors?.pattern && currentLang==='en'">Enter only number.</div>
                                    <div *ngIf="setting?.controls?.value?.errors?.min && currentLang==='en'" > Please input a number greater than or equal to {{setting?.controls?.min_value?.value}} </div>
                                    <div *ngIf="setting?.controls?.value?.errors?.max && currentLang==='en'"> Please input a number less than or equal to {{setting?.controls?.max_value?.value}} </div>
                                    <div *ngIf="setting?.controls?.value?.errors?.required && currentLang==='jp'"> {{setting?.controls?.setting?.value }} 必要とされている </div>
                                    <div *ngIf="setting?.controls?.value?.errors?.pattern && currentLang==='jp'">数字のみを入力してください。</div>
                                    <div *ngIf="setting?.controls?.value?.errors?.min && currentLang==='jp'"> 以上の数値を入力してください {{setting?.controls?.min_value?.value}} </div>
                                    <div *ngIf="setting?.controls?.value?.errors?.max && currentLang==='jp'"> 以下の数値を入力してください {{setting?.controls?.max_value?.value}} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>    
        <section class="popup_button sm">    
            <hr class="border_line" />        
            <button class="primary_button ml-3" (click)="submit()" [disabled]="ncExpiryReminderSettingsForm.invalid || submitted"> {{'dealer.newCar.apply' | translate}} </button>
            <button type="button" class="outline_button" (click)="modal.dismiss(); dismiss()"> {{'dealer.newCar.cancel' | translate}} </button>
        </section>
    </form>
</ng-template>
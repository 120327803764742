import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoaderService } from '@shared/components/loader/service/loader.service';
import { UtilService } from '@shared/utils/utils.service';
import { EvFleetUpdateStepCount, UpdateUrlValidity } from 'app/store/evfleet/actions/evfleet.actions';
import { EvFleetState } from 'app/store/evfleet/state/evfleet.state';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { SecuredService } from '../generic/secured.service';

@Injectable()
export class CustomerResolver implements Resolve<Observable<string>> {
  private readonly destroy$: Subject<void> = new Subject<void>();
  constructor(private securedService: SecuredService, private loaderService: LoaderService, private evfleetstore: Store<EvFleetState>,
    private utilService: UtilService, private router: Router) { }

  resolve() {
    try {
      this.loaderService.showloaderBody();
      const token = this.utilService.tokenFromUrlParams('ref');
      const currentLang = localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp';
      const completeURLPath = location.href;

      if (completeURLPath.indexOf('/customer/ev-fleet') > -1) {
        const evf_c_token = localStorage.getItem('evf_c_token');
        if ( token !== '') {
          localStorage.setItem('evf_c_token', token);
          sessionStorage.removeItem('cLangSwitch');
          if (token !== evf_c_token) {
            localStorage.removeItem('customerEmail');
            localStorage.removeItem('evf_customer_admission_data');
            localStorage.removeItem('admissionId');
          }
          let urlValidityReponse = this.securedService.get(`${currentLang}/evfleet/urlValidity`, {}, 'admission').pipe(takeUntil(this.destroy$), map(response => {
            this.evfleetstore.dispatch(new UpdateUrlValidity(response));
            this.evfleetstore.dispatch(new EvFleetUpdateStepCount(response.data));
          }), catchError(error => {
            console.error(error);
            if (error && error.status == 403) {
              this.evfleetstore.dispatch(new EvFleetUpdateStepCount(error));
            } else {
              this.router.navigate(['error/500'], { queryParams: { redirectURL: 'customer/ev-fleet' } });
            }
            return of(null);
          }));
          this.unsubscribe();
          return urlValidityReponse;
        } else if (
          localStorage.getItem('evf_c_token')) {
          let urlValidityReponse = this.securedService.get(`${currentLang}/evfleet/urlValidity`, {}, 'admission').pipe(takeUntil(this.destroy$), map(response => {
            this.evfleetstore.dispatch(new UpdateUrlValidity(response));
            this.evfleetstore.dispatch(new EvFleetUpdateStepCount(response.data));
          }), catchError(error => {
            console.error(error);
            if (error && error.status == 403) {
              this.evfleetstore.dispatch(new EvFleetUpdateStepCount(error));
            } else {
              this.router.navigate(['error/500'], { queryParams: { redirectURL: 'customer/ev-fleet' } });
            }
            return of(null);
          }));
          this.unsubscribe();
          return urlValidityReponse;
        } else {
          this.router.navigate(['error/404'], { queryParams: { redirectURL: 'customer/ev-fleet' } });
          return of(null);
        }
      } else if (token) {
        localStorage.setItem('c_token', token);
        return this.securedService.get(`${currentLang}/customer`, {}, 'admission').pipe(
          catchError(error => {
            console.error(error);
            this.router.navigate(['error/500'], { queryParams: { redirectURL: 'customer' } });
            return of(null);
          }));
      } else if (localStorage.getItem('c_token')) {
        return this.securedService.get(`${currentLang}/customer`, {}, 'admission').pipe(
          catchError(error => {
            console.error(error);
            this.router.navigate(['error/500'], { queryParams: { redirectURL: 'customer' } });
            return of(null);
          }));
      } else {
        this.router.navigate(['error/500'], { queryParams: { redirectURL: 'customer' } });
        return of(null);
      }
    } catch (error) {
      return of(null);
    }
  }

  unsubscribe() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

<header class="header_wrp">
    <div class="container mb-3">
        <div class="row">
            <div class="col-xl-12 col-sm-12">                
                <nav class="main_nav customer_main_nav">
                    <a class="logo_wrp" href="javascript:void(0)" >
                        <img src="assets/images/logo_bizconnect.svg" class="nissan_connect_logo" alt="Nissan Connect" />
                    </a>
                    <div class="lang_drop_down">
                        <select (change)="switchLang($event.target.value)">
                            <option value="en" [selected]="isENG">ENGLISH</option>
                            <option value="jp" [selected]="!isENG">日本語</option>
                        </select>
                    </div> 
                </nav>
            </div>
        </div>
    </div>
</header>
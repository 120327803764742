import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CarouselComponent } from './carousel/carousel.component';
import { ErrorComponent } from './error/error.component';
import { LoaderComponent } from './loader/loader.component';
import { ModalResponseComponent } from './modal-response/modal-response.component';
import { PlanDetailsPopupComponent } from './plan-details-popup/plan-details-popup.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { ToastComponent } from './toaster/toaster.component';
import { VinCheckingProgressBarComponent } from './vin-checking-progress-bar/vin-checking-progress-bar.component';
import { VinSearchComponent } from './vin-search/vin-search.component';
import { ConfirmationMethodPopupComponent } from './confirmation-method-popup/confirmation-method-popup.component';

import { DirectivesModule } from '@shared/directives/directives.module';
import { FormErrorComponent } from './form-error/form-error.component';
import { SearchOrderComponent } from './search-order/search-order.component';
import { NcExpiryReminderPopupComponent } from './nc-expiry-reminder-popup/nc-expiry-reminder-popup.component';
import { NaviidReminderPopupComponent } from './naviid-reminder-popup/naviid-reminder-popup.component';
import { OrderSearchComponent } from './biz-connect/order-search-profit/order-search.component';
import { OrderConfirmationComponent } from './biz-connect/order-confirmation/order-confirmation.component';
import { CustomNoticeComponent } from './custom-notice/custom-notice.component';
import { MenuCardComponent } from './menu-card/menu-card.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { ColorPaletteComponent } from './color-palette/color-palette.component';
import { PackageListComponent } from './package-list/package-list.component';
import { VehicleProcurementComponent } from './vehicle-procurement/vehicle-procurement.component';
import { ChecksheetComponent } from './checksheet/checksheet.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { InitialEntryCompletionComponent } from './initial-entry-completion/initial-entry-completion.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NavitypeGradeConfirmationComponent } from './navitype-grade-confirmation/navitype-grade-confirmation.component';
import { UxexpectedErrorResponseComponent } from './uxexpected-error-response/uxexpected-error-response.component';
import { ReminderSettingsPopupComponent } from './reminder-settings-popup/reminder-settings-popup.component';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { RenewalUrlErrorComponent } from './renewal-url-error/renewal-url-error.component';

const components = [
  ToastComponent,
  CarouselComponent,
  ErrorComponent,
  LoaderComponent,
  SessionExpiredComponent,
  ModalResponseComponent,
  VinSearchComponent,
  VinCheckingProgressBarComponent,
  PlanDetailsPopupComponent,
  ConfirmationMethodPopupComponent,
  SearchOrderComponent,
  FormErrorComponent,
  NcExpiryReminderPopupComponent,
  NaviidReminderPopupComponent,
  ReminderSettingsPopupComponent,
  OrderSearchComponent,
  OrderConfirmationComponent,
  ColorPaletteComponent,
  CustomNoticeComponent,
  MenuCardComponent,
  BreadcrumbComponent,
  PackageListComponent,
  VehicleProcurementComponent,
  ChecksheetComponent,
  TermsAndConditionsComponent,
  InitialEntryCompletionComponent,
  NavitypeGradeConfirmationComponent,
  RenewalUrlErrorComponent
];
@NgModule({
  declarations: [...components, UxexpectedErrorResponseComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbToastModule,
    NgbCarouselModule,
    TranslateModule,
    NgbDatepickerModule,
    NgbModule,
    DirectivesModule,
    RouterModule,
    QRCodeModule,
    NgSlimScrollModule
  ],
  exports: [
    ...components
  ]
})
export class ComponentsModule {
  constructor(private translate: TranslateService) {
    const currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
    this.translate.setDefaultLang(currentLang);
  }
}

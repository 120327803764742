import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@shared/components/loader/service/loader.service';

@Component({
  selector: 'app-biz-connect-email-layout',
  templateUrl: './biz-connect-email-layout.component.html',
  styleUrls: ['./biz-connect-email-layout.component.scss']
})
export class BizConnectEmailLayoutComponent implements OnInit {

  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.loaderService.removeloaderBody();
  }
}

export const regex = {
    number: '^[0-9]*$',
    EMAIL_REGEX : /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
    NAME_REGEX : {
        en: /^[a-zA-Z ]+$/i,
        jp: /^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\u3000\uFF0D\u3005\u3006 ー \uff00-\uffef]*$/,
        both: /^[\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f\u4e00-\u9faf a-zA-Z \u3000]*$/
    },
    DEPARTMENT_NAME_REGEX : {
        en: /^[a-zA-Z0-9\-\s]+$/,
        jp: /^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\u3000\uFF0D\u3005\u3006 ー \uff00-\uffef]*$/,
        both: /^[\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f\u4e00-\u9faf a-zA-ZＡ-Ｚ０-９ 0-9 \u3000]*$/
    },
    HIRANGANA_NAME_REGEX : {
        en: /^[a-zA-Z ]+$/i,
        jp: /^[\u3040-\u309f\uFF0D \u3000 ー]*$/
    },
    KATAKANA_NAME_REGEX: /^[\u30A0-\u30FF\uFF0D ー ]*$/,
    KATAKANA_NAME_REGEX_WITH_SPACE: /^[\u30A0-\u30FF\u3000]*$/,
    KATAKANA_HIRAGANA_NAME_REGEX_WITH_SPACE: /^[\u3041-\u3096\u30A0-\u30FF\u3000]*$/,
    JP_ADDRESS_REGEX: /^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\uFF10-\uFF19\uFF21-\uFF3A \uFF0D \u3000\u3005\u3006 ー \uff00-\uffef]*$/,
    JP_OFFICE_REGEX: /^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\uFF10-\uFF19\uFF21-\uFF3A\u3000\uFF0D\u3005\u3006 ー \uff00-\uffef]*$/,
    JP_CHARACTERS: /^[一-龯ぁ-ゔゞァ-・ヽヾ゛゜ーａ-ｚＡ-Ｚ０-９々〆〤\s]+/,
    NUMBER_REGEX : /^\d+$/,
    ALPHA_NUMERIC: /^[a-zA-Z0-9\-\s]+$/,
    ORDERNO_ALPHA_NUMERIC: /^[a-zA-Z0-9]+$/,
    NAVI_ALPHA_NUMERIC: /^[a-zA-Zａ-ｚＡ-Ｚ0-9\-\s]+$/,
    JP_MOBILE_REGEX: /^([0-9\uFF10-\uFF19]{10}|[0-9\uFF10-\uFF19]{11})$/,
    JP_ZIP_REGEX: /^([0-9\uFF10-\uFF19]{7})$/,
    HALF_WIDTH_VIN: /^[\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A]*$/,
    FULL_WIDTH_VIN: /^[a-zA-Z0-9]+$/,
    HALF_WIDTH_KATAKANA: /^[\uff00-\uff9f]*$/,
    NUMBER_PLATE_TXTBOX_TWO: /^[0-9][A-Z0-9]{0,2}$/, 
    ALPHA_NUMERIC_WITHOUT_SPACES: /^[\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f\u4e00-\u9fafa-zA-ZＡ-Ｚ０-９0-9]*$/,
    NUMBER_WITH_DECIMAL: /(?=[^\0])(?=^([0-9]+){0,1}(\.[0-9]{1,3}){0,1}$)/,
    FULL_WIDTH_DELIVERY_COMPANY_ADDR:/^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\uFF10-\uFF19\uFF21-\uFF3A\u3005\u3006\uFF01-\uFF0C\uFF0D-\uFF5E\uFFFF\u3000\u2E80-\u2FD5]*$/,
    full_width_number:/^[\uFF10-\uFF19]*$/,
    REP_NAME_REGEX:/^[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FaF\uFF0D\u3005\u3006 ー \uFF01-\uFF0F\uFF1A-\uFF5E\uFFFF\u3000-\u303F\uFF5F-\uFF9F\u2E80-\u2FD5\u31F0-\u31FF\u3220-\u3243\u3280-\u337F A-Za-z_@.,/#&$%=(')"{}^+?!`;~:<>*+-]*$/,
    FIRST_NUMBER_PLATE_REGEX:/^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\u2E80-\u2FD5]*$/,
    THIRD_NUMBER_PLATE_REGEX:/^[\u3040-\u3049\u304B-\u3056\u3058-\u3077\u3079-\u3092\u3094]*$/,
    VALID_EMAIL_REGEX:/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
    FULL_WIDTH_ADDR:/^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\uFF10-\uFF19\u3005\u3006\uFF21-\uFF3A\uFF41-\uFF5A\uFF0D\uFFFF\u3000\u2E80-\u2FD5\uFF5F-\uFF9F ー a-zA-Z0-9-]*$/,
    FULL_WIDTH_ADDR_WITH_SPACE:/^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\uFF10-\uFF19\u3005\u3006\uFF21-\uFF3A\uFF41-\uFF5A\uFF0D\uFFFF\u3000\u2E80-\u2FD5]*$/,
    HYPHEN_ALPHA_NUMERIC: /^[a-zA-Z0-9-]+$/,
    FULL_WIDTH_NAME_WITH_SPACE: /^[\u3041-\u3096\u3000\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\u2E80-\u2FD5\uFF01-\uFF5E a-zA-Z]*$/,
    PHONE_NUMBER_SPLIT_REGEX: /(,|-)/,
    ALPHA_NUMERIC_SYMBOLS: /^[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~a-zA-Z0-9\-\s]+$/,
    ALPHA_NUMERIC_SYMBOLS_WITHOUT_SPACE: /^[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~a-zA-Z0-9(\\)\-]+$/,
    ALPHA_NUMERIC_SYMBOLS_JP_ALL_FULL_WIDTH: /^[\u3041-\u3096\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\u3000！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～ａ-ｚＡ-Ｚ０-９]*$/,
    ALPHA_SYMBOLS_JP_ALL_FULL_WIDTH: /^[\u3041-\u3096\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\u3000！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～ａ-ｚＡ-Ｚ]*$/,
    ALPHA_SYMBOLS_JP_ALL: /^[\u3041-\u3096\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\uFF5F-\uFF9F\u3000 ！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～ａ-ｚＡ-Ｚa-zA-Z!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~a-zA-Z\-(\\)\s ]*$/,
    ALPHA_NUMERIC_SYMBOLS_JP_ALL: /^[\u3041-\u3096\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\uFF5F-\uFF9F\u3000 ！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～ａ-ｚＡ-Ｚ０-９a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~a-zA-Z0-9\-(\\)\s ]*$/,
    ALPHA_NUMERIC_HYPHON_JP_ALL_FULL_WIDTH_WITH_SPACE: /^[\u3041-\u3096\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\uFF5F-\uFF9F\u3000ａ-ｚＡ-Ｚ０-９]*$/,
    ALPHA_NUMERIC_SYMBOLS_FULLWIDTH_HALF_WIDTH:/^[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FaF\uFF0D\u3005\u3006 ー \uFF01-\uFF0F\uFF1A-\uFF5E\uFFFF\u3000-\u303F\uFF5F-\uFF9F\u2E80-\u2FD5\u31F0-\u31FF\u3220-\u3243\u3280-\u337F A-Za-z０-９0-9_@.,/#&$%=(')"{}^+?!`;~:<>*+-]*$/,
    NAVI_ID_REGEX: /^[a-fA-F0-9]+$/,
    CUSTOMER_ADDRESS_REGEX: /^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\uFF10-\uFF19\uFF21-\uFF3A\uFF0D\uFFFF\u3000\u3005\u3006\uff00-\uff5e]*$/,
    CUSTOMER_NAME_REGEX:/^[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\u3000\uFF0D\u3005\u3006\uff00-\uff5e]*$/,
    CUSTOMER_NAME_KATAKANA_REGEX_WITH_SPACE: /^[\u30A0-\u30FF\u3000]*$/,
    ALPHA_NUMERIC_NO_HYPHON: /^[a-zA-Z0-9\s]+$/,
};

export const scrollBarConfig = {
    alwaysVisible: false,
    gridOpacity: '0.2', barOpacity: '0.5',
    gridBackground: '#c2c2c2',
    gridWidth: '6',
    gridMargin: '2px 2px',
    barBackground: '#2C3E50',
    barWidth: '4',
    barMargin: '2px 2px'
  };

import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { UtilService } from '@shared/utils/utils.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SecuredService } from '../generic/secured.service';

@Injectable()
export class GeneratePasswordResolver implements Resolve<Observable<string>> {
  constructor(private utilService: UtilService, private securedService: SecuredService,
              private router: Router) {}

  resolve() {
    try {
        const token = this.utilService.tokenFromUrlParams('token');
        const admissionType = this.utilService.getUrlParameter(window.location.href, 'admissionType');
        const currentLang = localStorage.getItem('nLang') ? localStorage.getItem('nLang') : 'jp';
        if ( token ) {
          const data = {admissionType};
            return this.securedService.get(`${currentLang}/customers/temp-pwd`, data, 'admission').pipe(
                catchError(error => {
                  console.error(error);
                  this.router.navigate(['error/500'], { queryParams: { redirectURL: 'nissan-connect' } });
                  return of(null);
                }));
        } else {
            this.router.navigate(['error/500'], { queryParams: { redirectURL: 'nissan-connect' } });
            return of(null);
        }
    } catch (error) {
      return of(null);
    }
  }
}

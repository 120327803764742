<div class="customer_header_wrp " *ngIf="!alreadySubscribed">
    <div class="col-lg-9 col-sm-12 col-md-12 mx-auto text-center p-xs-0 p-md-0">
        <ul class="customer_step_nav">
            <li>
                <a href="javascript:void(0);"
                    [ngClass]="[pageNumber == 1 ? 'active' : '',  pageNumber > 1 ? 'completed' : '']">
                    <div class="number">
                        <span [innerHTML]="pageNumber > 1 ? completedHTML: '1'"></span>
                    </div>
                    {{'dealer.evFleet.customer.verifyContractNumber' | translate}}
                </a>
            </li>
            <li>
                <a href="javascript:void(0);"
                    [ngClass]="[pageNumber == 2 ? 'active' : '',  pageNumber > 2 ? 'completed' : '']">
                    <div class="number">
                        <span [innerHTML]="pageNumber > 2 ? completedHTML: '2'"></span>
                    </div>
                    {{'dealer.evFleet.customer.checksheetTnc' | translate}}
                </a>
            </li>
            <li >
                <a href="javascript:void(0);"
                    [ngClass]="[pageNumber == 3 ? 'active' : '',  (pageNumber > 3 ) ? 'completed' : '']">
                    <div class="number">
                        <span [innerHTML]="(pageNumber > 3 ) ? completedHTML: '3'"></span>
                    </div>
                    {{'dealer.evFleet.customer.CustInfo' | translate}}
                </a>
            </li>
            <li >
                <a href="javascript:void(0);"
                    [ngClass]="[pageNumber == 4 ? 'active' : '',  (pageNumber > 4 ) ? 'completed' : '']">
                    <div class="number">
                        <span [innerHTML]="pageNumber > 4 ? completedHTML: '4'"></span>
                    </div>
                    {{'dealer.evFleet.customer.Summary' | translate}}
                </a>
            </li>
        </ul>
    </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { commonConstants }  from './environment.common.constants';
export const environment = {
  production: commonConstants.production,
  SERVER_API_URL: 'https://api.qa-nissanconnect-regist.com',
  UI_URL: 'https://qa-nissanconnect-regist.com/#/',
  BASE_URL: 'https://qa-nissanconnect-regist.com',
  GD_URL: 'https://api.qa-nissanconnect-regist.com',
  GD_LOGOUT_URL: 'https://api.qa-nissanconnect-regist.com/api/v1/logout',
  CARWINGS_LOGIN: commonConstants.CARWINGS_LOGIN,
    NICOS_PAYMENT_URL: commonConstants.NICOS_PAYMENT_URL,
    NAVI_METHOD_REDIRECTION: commonConstants.NAVI_METHOD_REDIRECTION,
    WEBSOCKET_UPLOAD: commonConstants.WEBSOCKET_UPLOAD,
    FLOW_OF_WEB_ADMS: commonConstants.FLOW_OF_WEB_ADMS,
    NCLINK_MAINTANANCE: commonConstants.NCLINK_MAINTANANCE,
    NCAS_MANUAL: commonConstants.NCAS_MANUAL,
    NC_EXPIRY_POPUP: commonConstants.NC_EXPIRY_POPUP,
    NAVI_EXPIRY_POPUP: commonConstants.NAVI_EXPIRY_POPUP,
    E_SCOTT_PAYMENT_URL: commonConstants.E_SCOTT_PAYMENT_URL,
    NOTICE_CONTENT_URL: 'https://d3ct603clkck0f.cloudfront.net/dynamic-contents/dev/evf/notice/noticecontent.json',
    CHECKSHEET_URL: 'https://d3ct603clkck0f.cloudfront.net/dynamic-contents/dev/evf/checksheet',
    TNC_URL: 'https://d3ct603clkck0f.cloudfront.net/dynamic-contents/dev/evf/tnc',
    COLOR_PALETTE_URL: 'https://d3ct603clkck0f.cloudfront.net/dynamic-contents/dev/common/color-palette/colorPalette.json',
    NC_UI_CUSTOMIZATION_URL:'https://d3ct603clkck0f.cloudfront.net/dynamic-contents/qa/ncas/nc-ui-customization.json'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent {
  // private currentLang: string = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
  @Input() public packageInfo;
  @Input() public loadingFrom;
  @Output() selectedPackage = new EventEmitter();
  public currentLang: string = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';

  selectPackage(packageInfo: any){
    this.selectedPackage.emit(packageInfo);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public isENG = localStorage.getItem('cLang') === 'en' ? true : false;

  switchLang(flag) {
    if (localStorage.getItem('cLang') !== flag) {
      localStorage.setItem('cLang', flag);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  }
}


<section class="popup_title_grey sm">
    <h1> {{ 'dealer.admission.admissionType.digital.vehicleInfoConfirmation' | translate }} </h1>
    <a href="javascript:void(0);" class="close_icon" (click)="cancel()">
        <svg class="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clip-rule="evenodd"/>
            <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clip-rule="evenodd"/>
        </svg>
    </a>
</section>
<section class="popup_cnt sm">
    <div class="row biz_form_box_wrap">        
        <div class="col-xl-12">
            <div class="row pt-2">
                <div class="col-xl-6 col-sm-6">
                    <div class="label_custom"> {{ 'usedCar.vinNumber' | translate }}</div>
                </div>
                <div class="col-xl-6 col-sm-6">
                    <div class="data">{{ vinNumber }}</div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-xl-6 col-sm-6">
                    <div class="label_custom"> {{ 'dealer.admission.admissionType.digital.vehicleModel' | translate }}</div>
                </div>
                <div class="col-xl-6 col-sm-6">
                    <div class="data">{{ naviTypeGradeConfirmationPopupData?.model }}</div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-xl-6 col-sm-6">
                    <div class="label_custom"> {{ 'dealer.admission.admissionType.digital.grade' | translate }}</div>
                </div>
                <div class="col-xl-6 col-sm-6">
                    <div class="data">{{ naviTypeGradeConfirmationPopupData?.grade }}</div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-xl-6 col-sm-6">
                    <div class="label_custom"> {{ 'dealer.admission.admissionType.digital.naviType' | translate }}</div>
                </div>
                <div class="col-xl-6 col-sm-6">
                    <div class="data">{{ naviTypeGradeConfirmationPopupData?.naviType }}</div>
                </div>
            </div>
        </div>    
    </div>
</section>    
<section class="popup_button sm">    
    <hr class="border_line" />        
    <button class="primary_button ml-3" (click)="confirm()"> {{ 'dealer.bizConnect.confirm' | translate }} </button>
    <button type="button" class="outline_button" (click)="cancel()"> {{'dealer.bizConnect.cancel' | translate}} </button>
</section>
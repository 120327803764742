import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-notice',
  templateUrl: './custom-notice.component.html'
})
export class CustomNoticeComponent {

  @Input() noticeContent: string;


}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-renewal-url-error',
  templateUrl: './renewal-url-error.component.html',
  styleUrls: ['./renewal-url-error.component.scss']
})
export class RenewalUrlErrorComponent {
  errorMsg: string;
  ctaText: string;
  public errorStatus:string;
  public currentLang: string = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
  constructor(private route: ActivatedRoute, private router: Router,
    private translate: TranslateService) {
    this.route.data.subscribe(data => {
      this.errorStatus = data.status;
      if (data.status == 'expired') {
        this.errorMsg = this.translate.instant('pageExpired');
        this.ctaText = this.translate.instant('renewalErrorClose');
      } else {
        this.errorMsg = this.translate.instant('pageNotFound');
        this.ctaText = this.translate.instant('renewalErrorClose')
      }
    })
  }

  navigateToHomePage() {
    this.router.navigate(['/dealer']);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public pageNumber: number;
  public pageTitle: string;
  public nextPageTitle: string;
  public stepClass: string;
  public showPreviousButton = false;
  public previousPageURL;
  public completedHTML = `<i class='fas fa-check'></i>`;
  public usedCarStep = 1;
  public source = 'home';
  public hasUCVehicleInformation = false;
  public isENG = localStorage.getItem('cLang') === 'en' ? true : false;
  totalPages: number;
  urlValid : boolean
  @Input() oldUrl: any;
  constructor(public router: Router) {
  }

  ngOnInit() {
    this.urlValid = false;
  }

  switchLang(flag) {
    if (localStorage.getItem('cLang') !== flag) {
      localStorage.setItem('cLang', flag);
      if(this.router.url === '/customer/ev-fleet/email-otp'){
        sessionStorage.setItem('cLangSwitch', JSON.stringify({router: '/customer/ev-fleet/email-otp'}))
      }
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  }

}

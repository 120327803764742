export const customerPageIndex = [
    {
        stepCount: 1,
        url: '/customer/customer-information'
    },
    {
        stepCount: 2,
        url: '/customer/payment'
    },
    {
        stepCount: 3,
        url: '/customer/subscribe'
    },
    {
        stepCount: 4,
        url: 'customer/completed'
    }
];

export const newCarCustomerPageIndex = [
    {
        stepCount: 1,
        url: '/customer/new-car/customer-information'
    },
    {
        stepCount: 2,
        url: '/customer/new-car/payment'
    },
    {
        stepCount: 3,
        url: '/customer/new-car/subscribe'
    },
    {
        stepCount: 4,
        url: 'customer/new-car/completed'
    }
];


export const usedCarCustomerPageIndex = {
    home: [
                {
                    stepCount: 1,
                    url: '/customer/admission/info'
                },
                {
                    stepCount: 2,
                    url: '/customer/admission/select-plan'
                },
                {
                    stepCount: 3,
                    url: '/customer/admission/customer-information'
                },
                {
                    stepCount: 4,
                    url: '/customer/admission/payment'
                },
                {
                    stepCount: 5,
                    url: '/customer/admission/subscribe'
                },
                {
                    stepCount: 6,
                    url: '/customer/admission/completed'
                }
            ],
    dealer: [
            {
                stepCount: 1,
                url: '/customer/admission/verify-email'
            },
            {
                stepCount: 2,
                url: '/customer/admission/customer-information'
            },
            {
                stepCount: 3,
                url: '/customer/admission/payment'
            },
            {
                stepCount: 4,
                url: '/customer/admission/subscribe'
            },
            {
                stepCount: 5,
                url: '/customer/admission/completed'
            }
          ]
};

export const naviIDConfirmationLink = {
     en_url : '/assets/templates/navi-id-confirmation-method/confirmation_method-en.pdf',
     jp_url : '/assets/templates/navi-id-confirmation-method/confirmation_method-jp.pdf'
}

export const regexExp = {
    vinExp : /[^0-9a-zA-Z]/gi
}

export const paymentConstants = {
    CARD_VISA : 'VISA'
}
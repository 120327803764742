import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DealerState } from 'app/store/dealer/state/dealer.state';

import { ToastService } from '@shared/components/toaster/service/toaster.service';
import { SecuredService } from '@core/services/generic/secured.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reminder-settings-popup',
  templateUrl: './reminder-settings-popup.component.html',
  styleUrls: ['./reminder-settings-popup.component.scss']
})
export class ReminderSettingsPopupComponent implements OnInit {

  @ViewChild('ReminderSettingsPopup', {static : true}) ReminderSettingsPopupElementView: ElementRef;
  @Output() cancelled = new EventEmitter();
  @Input() reminderSettings;
  @ViewChild('successPopup') successPopupView: ElementRef;
  subscription1: Subscription;
  public currentLang: string;
  public ReminderSettingsForm: FormGroup;
  public settings;
  public submitted: boolean;
  mappedNames: any;
  showLoader: boolean;

  constructor(  private modalService: NgbModal, private toastService: ToastService,
                private formBuilder: FormBuilder, private store: Store<DealerState>,
                private securedService: SecuredService  ) { }

  ngOnInit(): void {
    this.currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
    this.store.select('naviidReminderSettingsInfo').subscribe((resp) => {
      this.settings = resp.data;
      this.initReminderSettingsForm();
      this.openReminderSettingsPopup();
    });
  }

  /**
   * @method initNcReminderSettingsForm
   * @description Initializing NC Reminder Settings Form
   */

   initReminderSettingsForm() {
    try {
      this.ReminderSettingsForm = this.formBuilder.group({
        value: [null, [Validators.required,Validators.pattern('^[0-9]*$')]],
        max_no_times:  [null, [Validators.required,Validators.pattern('^[0-9]*$')]]
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method controls
   * @description Returns form controls.
   */

  get controls() {
    return this.ReminderSettingsForm.controls;
  }

  /**
   * @method arrayOfcontrols
   * @description Returns form controls.
   */

  get arrayOfcontrols() { return this.controls.settings as FormArray; }


  /**
   * @method openReminderSettingsPopup
   * @description reminder settings popup
   */

   openReminderSettingsPopup() {
    try {
      const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
      this.modalService.open(this.ReminderSettingsPopupElementView, initialState);
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method dismiss
   * @description Hide all popups.
   */

  dismiss() {
    try {
      this.modalService.dismissAll();
      this.cancelled.emit();
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method submit
   * @description Save all info.
   */

  submit() {
    console.log(this.ReminderSettingsForm.value)
    try {
      if (this.ReminderSettingsForm.invalid) {
        return;
      } else {
        this.submitted = true;
        const ReminderSettingsData = this.ReminderSettingsForm.value;
        if (this.reminderSettings.name == '3. Reminder for customer info registration for customer' && this.reminderSettings.group == 'Customer') {
          ReminderSettingsData.emailTemplate = 'reminder-customer-reg-info-to-customer';
        } else if (this.reminderSettings.name == '4. Reminder for customer info registration for customer(Expired)' && this.reminderSettings.group == 'Customer') {
          ReminderSettingsData.emailTemplate = 'reminder-customer-reg-expiry-info-to-customer';
        } else if (this.reminderSettings.name == '5. Reminder for customer info registration for business' && this.reminderSettings.group == 'Business') {
          ReminderSettingsData.emailTemplate = 'reminder-customer-reg-info-to-business';
        } else if (this.reminderSettings.name == '6. Reminder for customer info registration for business(Expired)' && this.reminderSettings.group == 'Business') {
          ReminderSettingsData.emailTemplate = 'reminder-customer-reg-expiry-info-to-business';
        } else if (this.reminderSettings.name == '10. Reminder for vehicle info registration' && this.reminderSettings.group == 'Business') {
          ReminderSettingsData.emailTemplate = 'reminder-vehicle-info-reg-to-business';
        } else if (this.reminderSettings.name == '11. Reminder for NFS confirmation' && this.reminderSettings.group == 'Business') {
          ReminderSettingsData.emailTemplate = 'reminder-confirm-reg-info-by-business';
        }
        this.showLoader = true;
        this.subscription1 = this.securedService.put(ReminderSettingsData, this.currentLang + '/evfleet/updateEmailReminderSettings', {}, 'admission').subscribe((response) => {
        this.showLoader = false;
        
          this.submitted = false;
          if ( response && response.message) {
            this.modalService.dismissAll();
            const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static'};
            this.modalService.open(this.successPopupView, initialState);
          }
          
        }, err => {
          this.showLoader = false;
          this.submitted = false;
          this.modalService.dismissAll();
          this.toastService.showModalResponse(err);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  ngOnDestroy() {
 
    if(this.subscription1){
      this.subscription1.unsubscribe();
    }
   
  }
}

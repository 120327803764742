import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DealerState } from 'app/store/dealer/state/dealer.state';
import { SaveNAVIIDReminderInfo } from 'app/store/dealer/actions/dealer.actions';

import { ToastService } from '@shared/components/toaster/service/toaster.service';
import { SecuredService } from '@core/services/generic/secured.service';

@Component({
  selector: 'app-naviid-reminder-popup',
  templateUrl: './naviid-reminder-popup.component.html',
  styleUrls: ['./naviid-reminder-popup.component.scss']
})
export class NaviidReminderPopupComponent implements OnInit {

  @ViewChild('naviIdReminderSettingsPopup', {static : true}) naviIdReminderSettingsPopupElementView: ElementRef;
  @Output() cancelled = new EventEmitter();

  public currentLang: string;
  public naviIdReminderSettingsForm: FormGroup;
  public settings;
  public submitted: boolean;

  constructor(  private modalService: NgbModal, private toastService: ToastService,
                private formBuilder: FormBuilder, private store: Store<DealerState>,
                private securedService: SecuredService  ) { }

  ngOnInit(): void {
    this.currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';

    this.store.select('naviidReminderSettingsInfo').subscribe((resp) => {
      this.settings = resp.data;
      this.initNaviIdReminderSettingsForm();
      this.openNaviIdReminderSettingsPopup();
    });
  }

  /**
   * @method initNcReminderSettingsForm
   * @description Initializing NC Reminder Settings Form
   */

  /**
   * @method initNcReminderSettingsForm
   * @description Initializing NC Reminder Settings Form
   */

   initNaviIdReminderSettingsForm() {
    try {
      this.naviIdReminderSettingsForm = this.formBuilder.group({
        function: ['NAVIID_REMINDER'],
        settings: new FormArray([])
      });
      this.initNaviIdReminderSettingsArrayForm();
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method controls
   * @description Returns form controls.
   */

  get controls() {
    return this.naviIdReminderSettingsForm.controls;
  }

  /**
   * @method arrayOfcontrols
   * @description Returns form controls.
   */

  get arrayOfcontrols() { return this.controls.settings as FormArray; }

  /**
   * @method initNcReminderSettingsArrayForm
   * @description Initializing NC Reminder Settings Form Array
   */

   initNaviIdReminderSettingsArrayForm() {
    try {
      if ( this.settings.length ) {
        // tslint:disable-next-line: prefer-for-of
        for ( let i = 0; i < this.settings.length; i++ ) {
          this.arrayOfcontrols.push(this.formBuilder.group({
            id: [this.settings[i].id],
            settings_function: [this.settings[i].settings_function],
            setting: [ this.settings[i].setting, Validators.required],
            value: [ this.settings[i].value, [Validators.required, Validators.max(this.settings[i].max_value), Validators.min(this.settings[i].min_value), Validators.pattern('^[0-9]*$')]],
            min_value: [this.settings[i].min_value],
            max_value: [this.settings[i].max_value],
          }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method openSearchOrderPopup
   * @description search order popup
   */

   openNaviIdReminderSettingsPopup() {
    try {
      const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
      this.modalService.open(this.naviIdReminderSettingsPopupElementView, initialState);
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method dismiss
   * @description Hide all popups.
   */

  dismiss() {
    try {
      this.modalService.dismissAll();
      this.cancelled.emit();
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method submit
   * @description Save all info.
   */

  submit() {
    try {
      if (this.naviIdReminderSettingsForm.invalid) {
        return;
      } else {
        this.submitted = true;
        const naviidReminderSettingsData = this.naviIdReminderSettingsForm.value.settings;
        this.securedService.post(naviidReminderSettingsData, this.currentLang + '/settings', {}, 'admission').subscribe((response) => {
          this.submitted = false;
          this.store.dispatch(new SaveNAVIIDReminderInfo(response));
          if ( response && response.message) {
            const options = { classname: 'bg-success', delay: 2000};
            this.toastService.show(response.message, options );
          }
          this.modalService.dismissAll();
        }, err => {
          this.submitted = false;
          this.modalService.dismissAll();
          this.toastService.showModalResponse(err);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
}

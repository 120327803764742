import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoaderComponent } from '@shared/components/loader/loader.component';

@Directive({
  selector: '[appLoader]'
})
/**
 * Desc: Sectionwise loader directive which injects the LoaderComponent to the corresponding section where the directive is used.
 * Author: Nibin
 */
export class LoaderDirective {
  loadingFactory: ComponentFactory<LoaderComponent>;
  loadingComponent: ComponentRef<LoaderComponent>;
  @Input() set appLoader(loading: boolean) {
    this.vcRef.clear();
    if (loading) {
      this.loadingComponent = this.vcRef.createComponent(this.loadingFactory); // create and embed an instance of the loading component
    } else {
      this.vcRef.createEmbeddedView(this.templateRef); // embed the contents of the host template
    }
  }

  constructor(private templateRef: TemplateRef<any>,
              private vcRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver) {
    this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
  }
}

export const commonConstants = {
  production: false,
  CARWINGS_LOGIN: 'https://www.nissan.co.jp/CONNECT/MEMBER/',
  NICOS_PAYMENT_URL: 'https://test.bc-pay.jp/kfgw/F/wf01pc.html',
  NAVI_METHOD_REDIRECTION: 'https://www3.nissan.co.jp/connect/service_search.html',
  WEBSOCKET_UPLOAD: 'https://qaws.nissanconnect-regist.com/democar/erp',
  FLOW_OF_WEB_ADMS: 'https://www3.nissan.co.jp/connect/offer/flow.html',
  NCLINK_MAINTANANCE: 'https://saas.nissan.co.jp/portal/cms/maintenance/',
  NCAS_MANUAL: 'https://saas.nissan.co.jp/portal/uploads/nc_manuals/prod/intro_ncas.html',
  E_SCOTT_PAYMENT_URL: 'https://www.test.e-scott.jp/euser/stn/CdGetJavaScript.do?k_TokenNinsyoCode=F63XxDPfYup4Lup68HwgVjxcTtBdmDTE',
  NC_EXPIRY_POPUP: true,
  NAVI_EXPIRY_POPUP: true,
};

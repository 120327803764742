import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { SaveBizConnectVehicleInfo } from 'app/store/dealer/actions/dealer.actions';
import { DealerState } from 'app/store/dealer/state/dealer.state';

import { SecuredService } from '@core/services/generic/secured.service';

import { GlobalValidator } from '@shared/validations/validators';
import { ToastService } from '@shared/components/toaster/service/toaster.service';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss']
})
export class OrderSearchComponent implements OnInit {

  @Input() dealerType: any;
  @Input() nbcCorporateNumber: any;
  @Output() hideBizConnectSearchOrderInfoPopup = new EventEmitter();
  @ViewChild('searchBizConnectOrderPopup', {static : true}) searchBizConnectOrderPopupElementView: ElementRef;
  @ViewChild('showCapProfitDownPopup', {static : true}) showCapProfitDownPopupView: ElementRef;
  @ViewChild('showOrderConfirmationPopup', {static : true}) showOrderConfirmationPopupView: ElementRef;
  @ViewChild('showErrorPopup', {static : true}) showErrorPopupView: ElementRef;

  currentLang: string;
  formSearchOrder: FormGroup;
  formSubmitted: boolean;
  submitted: boolean;
  responseMsg: any;
  public dropdowns = {
    companyNames : [],
    dealershipNames: []
  };
  dealerMasterData: any;
  confirmationPopupData: { corporateName: any; orderNumber: any; vehicleType: any; };

  constructor(private modalService: NgbModal, private toastService: ToastService,
              private formBuilder: FormBuilder, private router: Router,
              private securedService: SecuredService, private store: Store<DealerState>,
              private cdf:ChangeDetectorRef ) { }

  /**
   * @method ngOnInit
   * @description Form and other Initialization.
   */

  ngOnInit(): void {
    this.currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
    this.getDealerMasterData();
    this.initSearchOrderForm();
    this.openSearchOrderPopup();
    this.subscribeFormChanges();
  }

  /**
   * @method getDealerMasterData
   * @description Fetch dealer master data.
   */
  getDealerMasterData() {
    this.store.select('dealerMasterdataInfo').subscribe((resp) => {
      this.dealerMasterData = resp.data;
      if ( this.dealerMasterData && this.dealerMasterData.length > 0 ) {
        this.dropdowns.companyNames = this.dealerMasterData;
      }
    });
  }

  /**
   * @method initSearchOrderForm
   * @description Initializing search order form
   */

  initSearchOrderForm() {
    try {
      this.formSearchOrder = this.formBuilder.group({
        companyName: [],
        dealershipName: [],
        tempOrderNumber: [null, [GlobalValidator.nonEmpty, Validators.minLength(9), Validators.maxLength(9), GlobalValidator.ordernoAlphaNumeric]],
        nbcCorporateNumber: [this.nbcCorporateNumber]
      });
      this.setValidations();
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  setValidations() {
    try {
      if ( this.dealerType !== 'CA' ) {
        const validationFields = ['companyName', 'dealershipName'];
        validationFields.forEach(field => {
          this.formSearchOrder.get(field).setValidators(null);
          this.formSearchOrder.get(field).setValidators([GlobalValidator.nonEmpty]);
          this.formSearchOrder.get(field).updateValueAndValidity();
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method openSearchOrderPopup
   * @description search order popup
   */

  openSearchOrderPopup() {
    try {
      const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
      this.modalService.open(this.searchBizConnectOrderPopupElementView, initialState);
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method controls
   * @description Returns form controls.
   */

  get controls() {
    return this.formSearchOrder.controls;
  }

  /**
   * @method dismiss
   * @description Hide all popups.
   */

  dismiss() {
    try {
      this.hideBizConnectSearchOrderInfoPopup.emit();
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method subscribeFormChanges
   * @description For subscribing form changes.
   */
    subscribeFormChanges() {
      this.controls.companyName.valueChanges.subscribe(companyCode => {
        this.dropdowns.dealershipNames = [];
        this.controls.dealershipName.setValue(null);
        if (companyCode) {
          this.dropdowns.dealershipNames = this.dropdowns.companyNames.find(companyName => companyName.companyCode === companyCode).dealerDetails;
        }
      });
    }

  /**
   * @method checkVIN
   * @description Checking vehicle Info
   */

  searchBizConnectOrderInfo() {
    try {
      this.submitted = true;
      const tempOrderNumber = (this.controls.tempOrderNumber.value) ? this.controls.tempOrderNumber.value : null;
      const dealerCompanyCode = (this.controls.companyName.value) ? this.controls.companyName.value : null;
      const dealerShipCode = (this.controls.dealershipName.value) ? this.controls.dealershipName.value : null;
      const nbcCorporateNumber = (this.controls.nbcCorporateNumber.value) ? this.controls.nbcCorporateNumber.value : null;
      if(this.dealerType !== 'CA'){
      let dealerCompanyName = this.dropdowns.companyNames.find(item=>item.companyCode === this.controls.companyName.value).companyName;
      let dealerShipName = this.dropdowns.dealershipNames.find(item=>item.dealerShipCode === this.controls.dealershipName.value).dealerShipName;
      localStorage.setItem('dealCompanyName',dealerCompanyName);
      localStorage.setItem('dealShipName',dealerShipName);
      localStorage.setItem('dealCompanyCode',dealerCompanyCode);
      localStorage.setItem('dealshipCode',dealerShipCode);
      }
      let url;
      if (this.dealerType !== 'CA') {
        url = `orderNumber=${tempOrderNumber}&dealerCompanyCode=${dealerCompanyCode}&dealerShipCode=${dealerShipCode}&nbcCorporateNumber=${nbcCorporateNumber}`;
      } else {
        url = `orderNumber=${tempOrderNumber}&nbcCorporateNumber=${nbcCorporateNumber}`;
      }

      this.securedService.get(`${this.currentLang}/fleetAdmission/orderSearch?${url}`, {}, 'admission').subscribe((response) => {
        this.submitted = false;
        if (response && response.data) {
          this.modalService.dismissAll();
          this.hideBizConnectSearchOrderInfoPopup.emit();
          localStorage.setItem('biz_connect_vehicle_info' , JSON.stringify(response.data));
          this.confirmationPopupData = {
            corporateName: response.data.corporateName,
            orderNumber: response.data.orderNumber,
            vehicleType: response.data.vehicleType
          };
          const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
          this.modalService.open(this.showOrderConfirmationPopupView, initialState);
        }
      }, err => {
        this.submitted = false;
        this.modalService.dismissAll();
        this.hideBizConnectSearchOrderInfoPopup.emit();
        if (err && err.error && err.error.code === '460' && err.error.data) {
          this.responseMsg = err.error.message;
          localStorage.setItem('biz_connect_vehicle_info' , JSON.stringify(err.error.data));
          const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
          this.modalService.open(this.showCapProfitDownPopupView, initialState);
        }     
        else {
           this.responseMsg = err.error.message;
           localStorage.setItem('biz_connect_vehicle_info' , JSON.stringify(err.error.data));
          const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
          this.modalService.open(this.showErrorPopupView, initialState);
          //this.toastService.showModalResponse(err);
        }
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method close
   * @description Close modal popup and redirecting to vehicle identification page
   */
  close() {
    this.modalService.dismissAll();
    this.router.navigate(['/dealer/biz-connet/vehicle-info']);
  }
  closeOrderConfirmPopup() {
    this.modalService.dismissAll();
    const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
    this.modalService.open(this.searchBizConnectOrderPopupElementView, initialState);
  }
  closeCurrentPopup() {
    console.log('continue click')
    this.modalService.dismissAll();
    const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
    this.modalService.open(this.searchBizConnectOrderPopupElementView, initialState);
    this.cdf.detectChanges();
  }
}

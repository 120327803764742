<ng-template #ReminderSettingsPopup let-modal>
    <section class="popup_title_grey sm grey_header pd-rght-23">
        <h1>{{reminderSettings.name}} </h1>
        <a href="javascript:void(0);" class="close_icon" (click)="modal.dismiss(); dismiss()">
            <svg class="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clip-rule="evenodd" />
            </svg>
        </a>
    </section>
<app-loader *ngIf="showLoader"></app-loader>
    <form [formGroup]="ReminderSettingsForm" autocomplete="off">
        <section class="popup_cnt sm">
            <div class="col-xl-12">
                <div >
                    <div class="row">
                        <div class="col-xl-2">
                        </div>
                        <div class="col-6">
                            <h5 class="add_vin line-ht-36">{{ 'dealer.evFleet.reminderSettings.dayOnceEvery' | translate }}</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-2"> </div>
                        <div class="col-8">
                            <div class="floating_label_wrp mt-10" [class.error]="ReminderSettingsForm.get('value')?.touched && ReminderSettingsForm.get('value')?.errors">
                                <input type="number" class="floating_label_field" id="tmp_order_number" min="0" step="1" pattern="\d+"
                                    placeholder="Day (Once Every)" formControlName="value">
                                <label class="floating_label required" for="tmp_order_number">{{ 'dealer.evFleet.reminderSettings.dayOnceEvery' | translate }}</label>
                                <div *ngIf="ReminderSettingsForm.get('value')?.touched && ReminderSettingsForm.get('value')?.errors" class="form-invalid">
                                    <div *ngIf="ReminderSettingsForm.get('value')?.errors?.required"> {{ 'dealer.evFleet.reminderSettings.fieldRequired' | translate }}</div>
                                    <div *ngIf="ReminderSettingsForm.get('value')?.errors && !ReminderSettingsForm.get('value')?.errors?.required">  {{ 'dealer.evFleet.reminderSettings.numberonly' | translate }} </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-2">
                        </div>
                        <div class="col-6">
                            <h5 class="add_vin">{{ 'dealer.evFleet.reminderSettings.max' | translate }}</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-2"> </div>
                        <div class="col-8">
                            <div class="floating_label_wrp mt-10" [class.error]="ReminderSettingsForm.get('max_no_times')?.touched && ReminderSettingsForm.get('max_no_times')?.errors">
                                <input type="number" class="floating_label_field" id="max_number" min="0" step="1" pattern="\d+"
                                    placeholder="Max" formControlName="max_no_times">
                                <label class="floating_label required" for="max_number"> {{ 'dealer.evFleet.reminderSettings.max' | translate }}</label>
                                <div *ngIf="ReminderSettingsForm.get('max_no_times')?.touched && ReminderSettingsForm.get('max_no_times')?.errors" class="form-invalid">
                                    <div *ngIf="ReminderSettingsForm.get('max_no_times')?.errors?.required">  {{ 'dealer.evFleet.reminderSettings.fieldRequired' | translate }} </div>
                                    <div *ngIf="ReminderSettingsForm.get('max_no_times')?.errors && !ReminderSettingsForm.get('max_no_times')?.errors?.required">  {{ 'dealer.evFleet.reminderSettings.numberonly' | translate }} </div>
                                    
                                    
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="popup_button sm">
            <hr class="border_line" />
            <button class="primary_button ml-3" (click)="submit()"
                [disabled]="(ReminderSettingsForm.get('max_no_times')?.errors || ReminderSettingsForm.get('value')?.errors) || submitted"> {{'dealer.evFleet.reminderSettings.apply' | translate}} </button>
            <button type="button" class="outline_button" (click)="modal.dismiss(); dismiss()"> {{'dealer.evFleet.reminderSettings.cancel'
                | translate}} </button>
        </section>
    </form>
</ng-template>
<ng-template #successPopup let-modal>
    <div class="email_otp">
        <div class="otp_icon">
            <div>
                <img src="assets/images/email_verified.svg" alt="otp" />
                <h2> {{ 'dealer.evFleet.reminderSettings.updatedSuccessfully' | translate }}</h2>
            </div>
        </div>
        <div class="otp_box">
            <div class="otp_links">
                <button type="button" class="secondary_button mt-2" (click)="dismiss()">{{ 'dealer.evFleet.reminderSettings.ok' | translate }}</button>
            </div>            
        </div>
    </div>
</ng-template>
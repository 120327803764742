import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../base-http.service';





/**
 * Name: GeneralService
 * params: HttpClient
 * basepath: nissan/general/api/
 * desc: General services should be accessing this service
 */

@Injectable({
  providedIn: 'root'
})
export class GeneralService extends BaseHttpService {
  // @ts-ignore
  constructor(private http: HttpClient) {
    super(http, 'api/v1');
   }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CustomerLayoutComponent } from './customer-layout.component';
import { HeaderComponent } from './header/header.component';
import { ComponentsModule } from '@shared/components/components.module';



@NgModule({
  declarations: [CustomerLayoutComponent, HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    TranslateModule
  ]
})
export class CustomerLayoutModule { }


<header class="header_wrp d-flex align-items-center common-header">
    <div class="header-container-wrap">
        <div class="container">
            <nav class="d-flex main_nav align-items-center flex-wrap">
                <div class="flex-auto nissan-n-logo d-none d-sm-none d-md-none d-lg-block">
                    <a href="javascript:void(0);" >
                        <img src="assets/images/Nissan-r-logo.svg" routerLink="/dealer" alt="Nissan" class="nissan_logo" />
                    </a>                   
                </div>
                <div class="flex-auto d-none d-sm-block d-lg-none">
                    <a href="javascript:void(0);" >
                        <img src="assets/images/Nissan-r-logo.svg" routerLink="/dealer" alt="Nissan" class="nissan_logo Update" />
                    </a>                   
                </div>
                <div class="mr-auto nissan-c-logo" *ngIf="!isBizConnectModule">
                    <a  href="javascript:void(0)">
                        <img src="assets/images/nissan_connect_logo.svg" class="d-block" alt="Nissan Connect"  (click)="promptRedirectionPopup()" />
                    </a>            
                </div>
                <div class="mr-auto nissan-c-logo" *ngIf="isBizConnectModule">
                    <a  href="javascript:void(0)">
                        <img src="assets/images/logo_bizconnect.svg" class="d-block" alt="Nissan Connect"  (click)="promptRedirectionPopup()" />
                    </a>            
                </div>
                <div  class="ml-auto d-inline-flex header-dropdown-grp">
                    <div class="lang_drop_down" *ngIf="showLanguageToggleBtn">
                        <select (change)="switchLang($event.target.value)">
                            <option value="en" [selected]="isENG">ENGLISH</option>
                            <option value="jp" [selected]="!isENG">日本語</option>
                        </select>
                    </div>
                </div>
                <div class="flex-auto">
                    <div class="user_profile  d-none d-sm-inline-block">
                        <a href="javascript:void(0);" (click)="open(content)">
                            {{dealerName}}
                         </a> 
                     </div>
                     <div class="logout_wrpper">
                         <a href="javascript:void(0)" (click)="logout()">
                             <img src="assets/images/sign-out-alt.svg" alt="sign_out" />
                         </a>
                     </div>
                </div>
                <div #adminHam class="flex-auto hamburg-menu" *ngIf="!hideAdminstratorSetting && !isEvFleetModuleModule">
                    <a class="d-block ml-8 hamburg-icon" (click)="showAdminstratorMenu()">
                        <img style="pointer-events: none;" src="assets/images/menu-2-line.svg"  alt="Nissan" class="nissan_logo d-block w-100" />
                    </a>   
                </div>
                <div #adminEVFleetHam class="flex-auto hamburg-menu" *ngIf="!hideAdminstratorSetting && isEvFleetModuleModule">
                    <a class="d-block ml-8 hamburg-icon" (click)="showAdminstratorEVFleetMenu()">
                        <img style="pointer-events: none;" src="assets/images/menu-2-line.svg"  alt="Nissan" class="nissan_logo d-block w-100" />
                    </a>   
                </div>
                <div class="mobile-menu" *ngIf="showSettingsMenu">
                    <div class="mobile-menu__backdrop"></div>
                    <div class="mobile-menu__content">
                        <div class="container">
                        <div class="mobile-menu__close">
                            <span><img  src="assets/images/close.svg"  alt="x" /></span>
                        </div>
                          <div class="mobile-menu__header">
                              <div class="mobile-menu__dropdown">
                                <div  class="lang_drop_down admin-setting ">
                                    <span class="admin-label"> {{ 'dealer.header.administratorSetting' | translate }} </span>
                                     <ul class="mobile-menu-dropdown">
                                        <li class="admin-label" (click)="navigateToAlerts()">{{ 'dealer.header.alertSettings' | translate }} </li>
                                        <li class="admin-label"  *ngIf="showNcExpiryReminderSetting" (click)="navigateToNCExpiryReminder()">{{ 'dealer.header.ncExpiry' | translate }} </li>
                                        <li class="admin-label" (click)="navigateToNaviIdReminder()">{{ 'dealer.header.naviIdReminder' | translate }} </li>
                                     </ul>
                                 </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-menu" *ngIf="showSettingsEVMenu">
                    <div class="mobile-menu__backdrop"></div>
                    <div class="mobile-menu__content" style="height: fit-content;border-radius: 22px;">
                
                        <div class="container" style="padding: 0px !important;">
                            <div class="grey_header" style="text-align: left;padding-left: 5px;">
                                <div class="mobile-menu__close" style="display: inline-block" (click)="closeEVFleetMenu()">
                                    <span class="white_background"><img src="assets/images/close.svg" alt="x" /></span>
                                </div>
                                <span class="admin-label header-bold">{{ 'dealer.evFleet.reminderSettings.emailReminderSettings' | translate }}</span>
                            </div>
                            <div class="mobile-menu__header">
                                <div class="mobile-menu__dropdown_evfleet">
                                    <div class="admin-setting ">
                
                                        <a href="javascript:void(0);" class="admin-label left-align header-underline" (click)="SwitchTabs('Customer')"
                                            [class.active_header_menu]="activeTabSelected == 'Customer'">
                                            {{ 'dealer.evFleet.reminderSettings.customer' | translate }} </a>
                                        <a href="javascript:void(0);" class="admin-label left-align header-underline" (click)="SwitchTabs('Business')"
                                            [class.active_header_menu]="activeTabSelected == 'Business'">
                                            {{ 'dealer.evFleet.reminderSettings.business' | translate }} </a>
                                        <ul class="mobile-menu-dropdown list-unstyled" *ngIf="activeTabSelected == 'Customer'">
                                            <li class="admin-label left-align aligh-text-left"
                                                (click)="navigateToReminderSettings('3. Reminder for customer info registration for customer')">{{ 'dealer.evFleet.reminderSettings.customerInforeg' | translate }} </li>
                                            <li class="admin-label left-align aligh-text-left"
                                                (click)="navigateToReminderSettings('4. Reminder for customer info registration for customer(Expired)')">{{ 'dealer.evFleet.reminderSettings.customerInforegExp' | translate }}</li>
                                        </ul>
                                        <ul class="mobile-menu-dropdown list-unstyled" *ngIf="activeTabSelected == 'Business'">
                                            <li class="admin-label left-align aligh-text-left"
                                                (click)="navigateToReminderSettings('5. Reminder for customer info registration for business')">{{ 'dealer.evFleet.reminderSettings.businessInforeg' | translate }}</li>
                                            <li class="admin-label left-align aligh-text-left"
                                                (click)="navigateToReminderSettings('6. Reminder for customer info registration for business(Expired)')">{{ 'dealer.evFleet.reminderSettings.businessInforegExp' | translate }}</li>
                                            <li class="admin-label left-align aligh-text-left"
                                                (click)="navigateToReminderSettings('10. Reminder for vehicle info registration')">{{ 'dealer.evFleet.reminderSettings.vehicleInforeg' | translate }}</li>
                                            <li class="admin-label left-align aligh-text-left" (click)="navigateToReminderSettings('11. Reminder for NFS confirmation')">
                                                {{ 'dealer.evFleet.reminderSettings.nfsConfirm' | translate }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>
<ng-template #content let-modal>

    <a href="javascript:void(0);" class="popup_close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <svg class="bi bi-x" width="30px" height="30px" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M5.646 5.646a.5.5 0 000 .708l8 8a.5.5 0 00.708-.708l-8-8a.5.5 0 00-.708 0z" clip-rule="evenodd"></path>
            <path fill-rule="evenodd" d="M14.354 5.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z" clip-rule="evenodd"></path>
        </svg>
    </a>
    <div class="logout_header">
        <h4>{{dealerName}}</h4>
    </div>
    <div class="logout_cnt">
        <a href="javascript:void(0)" (click)="logout()">
            <img src="assets/images/sign-out-alt.svg" alt="sign_out" />
            {{ 'dealer.header.signOut' | translate }}
        </a>
    </div>
  </ng-template>

<ng-template #promptRedirection let-modal>
    <div class="clear_popup">
        <div class="clear_cnt">
            <span class="info">
                <img class="info_icon" src="assets/images/info.svg">
            </span>           
            {{ 'dealer.admission.admissionType.digital.infoLabels.cancelPopup' | translate }}
        </div>
        <div class="popup_btn">			
            <a href="javascript:;" class="button_link" (click)="modal.dismiss();"> {{ 'dealer.admission.admissionType.digital.cancel' | translate }} </a>
            <a href="javascript:;" class="primary_button" routerLink="/dealer" (click)="modal.dismiss()"> {{ 'dealer.admission.admissionType.digital.ok' | translate }} </a>
        </div>	
    </div>
</ng-template>

<ng-template #content let-modal>
    <a href="javascript:void(0);" class="popup_close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <svg class="bi bi-x" width="30px" height="30px" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M5.646 5.646a.5.5 0 000 .708l8 8a.5.5 0 00.708-.708l-8-8a.5.5 0 00-.708 0z" clip-rule="evenodd"></path>
            <path fill-rule="evenodd" d="M14.354 5.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z" clip-rule="evenodd"></path>
        </svg>
    </a>
    <div class="logout_header">
        <h4>{{dealerName}}</h4>
    </div>
    <div class="logout_cnt">
        <a href="javascript:void(0)" (click)="logout()">
            <img src="assets/images/sign-out-alt.svg" alt="sign_out" />
            {{ 'dealer.header.signOut' | translate }}
        </a>
    </div>
  </ng-template>
<ng-template #promptRedirection let-modal>
    <div class="clear_popup">
        <div class="clear_cnt">
            <span class="info">
                <img class="info_icon" src="assets/images/info.svg">
            </span>           
            {{ 'dealer.admission.admissionType.digital.infoLabels.cancelPopup' | translate }}
        </div>
        <div class="popup_btn">			
            <a href="javascript:;" class="button_link" (click)="modal.dismiss();"> {{ 'dealer.admission.admissionType.digital.cancel' | translate }} </a>
            <a href="javascript:;" class="primary_button" routerLink="/dealer" (click)="modal.dismiss()"> {{ 'dealer.admission.admissionType.digital.ok' | translate }} </a>
        </div>	
    </div>
</ng-template>

<ng-template #ncExpiryReminderPopup let-modal>
    <app-nc-expiry-reminder-popup></app-nc-expiry-reminder-popup>
</ng-template>
<ng-template #naviIdExpiryReminderPopup let-modal>
    <app-naviid-reminder-popup></app-naviid-reminder-popup>
</ng-template>

<ng-template #ReminderSettingsPopup let-modal>
    <app-reminder-settings-popup [reminderSettings]="selectedsettings"></app-reminder-settings-popup>
</ng-template>


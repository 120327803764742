<div class="row justify-content-center">
    <div class="col-xl-9 col-md-10 col-sm-12">
        <div class="notification_menu_wrp">
            <div class="row">
                <div class="col-xl-9 col-sm-8">
                    <ng-container *ngIf="breadcrumbData.section === 'customer-info'">
                        <h1> {{ 'dealer.evFleet.admission.breadcrumb.title' | translate }} </h1>
                        <ul>
                            <li>
                                <a href="javascript:void(0);" (click)="promptRedirectionPopup(cancelForm)"> {{ 'dealer.evFleet.admission.breadcrumb.home' | translate }}</a>
                            </li>
                            <li class="active">
                                {{ 'dealer.evFleet.admission.breadcrumb.customerInfo' | translate}}
                            </li>
                        </ul>                            
                    </ng-container>
                    <ng-container *ngIf="breadcrumbData.section === 'vehicle-info'">
                        <h1> {{ 'dealer.evFleet.admission.breadcrumb.title' | translate }} </h1>
                        <ul>
                            <li>
                                <a href="javascript:void(0);" (click)="promptRedirectionPopup(cancelForm)"> {{ 'dealer.evFleet.admission.breadcrumb.home' | translate }}</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" (click)="promptRedirectionCheck()"> {{ 'dealer.evFleet.admission.breadcrumb.customerInfo' | translate }}</a>
                            </li>
                            <li class="active">
                                {{ 'dealer.evFleet.admission.breadcrumb.vehicleInfo' | translate }}
                            </li>
                        </ul>  
                    </ng-container> 
                    <ng-container *ngIf="breadcrumbData.section === 'summary'">
                        <h1> {{ 'dealer.evFleet.admission.breadcrumb.title' | translate }} </h1>
                        <ul>
                            <li>
                                <a href="javascript:void(0);" (click)="promptRedirectionPopup(cancelForm)"> {{ 'dealer.evFleet.admission.breadcrumb.home' | translate }}</a>
                            </li>
                            <li>
                                <a routerLink="/dealer/ev-fleet/admission/customer-info"> {{ 'dealer.evFleet.admission.breadcrumb.customerInfo' | translate }}</a>
                            </li>
                            <li>
                                <a routerLink="/dealer/ev-fleet/admission/vehicle-info"> {{ 'dealer.evFleet.admission.breadcrumb.vehicleInfo' | translate }} </a>
                            </li>
                            <li class="active">
                                {{ 'dealer.evFleet.admission.breadcrumb.summary' | translate }}
                            </li>
                        </ul>  
                    </ng-container>                          
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Cancel Form Popup -->
<ng-template #cancelForm let-modal>
	<div class="clear_popup">
		<div class="clear_cnt">
            <span class="info">
                <img class="info_icon" alt="info"  src="assets/images/info.svg">
            </span>           
            {{ 'common.infoLabels.cancelMsg' | translate }}
        </div>
		<div class="popup_btn">			
			<a href="javascript:;" class="button_link" (click)="modal.dismiss();"> {{ 'common.buttons.cancel' | translate }} </a>
			<a class="primary_button" routerLink="/dealer/ev-fleet" (click)="modal.dismiss()"> {{ 'common.buttons.ok' | translate }}</a>
		</div>	
	</div>
</ng-template>

import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ToastService } from '@shared/components/toaster/service/toaster.service';
import { eFlag } from '@shared/constants/dealer.constants';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showLogout = false;
  constructor(private router: Router, private route: ActivatedRoute,
              private toastService: ToastService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (localStorage.getItem('d_token') && this.route.snapshot.queryParamMap.get('redirectURL') == 'dealer') {
      this.showLogout = true;
    }
  }

  navigateTo(url) {
    this.router.navigate([url]);
  }

  navigateToHomePage() {
    try {
      if (this.route.snapshot.queryParamMap.get('redirectURL') == 'customer/admission') {
        this.router.navigate(['/customer/admission/']);
      } else if (this.route.snapshot.queryParamMap.get('redirectURL') == 'customer') {
        this.router.navigate(['/customer']);
      } else if (this.route.snapshot.queryParamMap.get('redirectURL') == 'customer/ev-fleet') {
        this.router.navigate(['/customer/ev-fleet']);
      } else if (this.route.snapshot.queryParamMap.get('redirectURL') == 'dealer') {
        this.router.navigate(['/dealer']);
      } else if (this.route.snapshot.queryParamMap.get('redirectURL') == 'nissan-connect') {
        this.router.navigate(['/nissan-connect']);
      } else {
        if (localStorage.getItem('d_token')) {
          this.router.navigate(['/dealer']);
        } else if (localStorage.getItem('c_token')) {
          this.router.navigate(['/customer']);
        }else if (localStorage.getItem('evf_c_token')) {
          this.router.navigate(['/customer/ev-fleet']);
        }
      }
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  logout() {
    if (localStorage.getItem('d_token') && this.route.snapshot.queryParamMap.get('redirectURL') == 'dealer') {
      const dToken = localStorage.getItem('d_token');
      localStorage.removeItem('d_token');
      localStorage.removeItem('formData');
      if (dToken) {
        const encodedString = btoa(dToken);
        let URL;
        if(eFlag.value == true){
          URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_LOGOUT_URL}?${eFlag.key}=${eFlag.value}&key=${encodedString}`);
        }else{
          URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_LOGOUT_URL}?token=${dToken}`);
        }
        window.location.href = URL;
      } else {
        const URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_URL}`);
        window.location.href = URL;
      }
    }
  }

}

import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[zipCode]'
})
export class ZipCodeFormatterDirective implements OnInit {

  constructor(private el: ElementRef, private render: Renderer2) { }

  ngOnInit() {
    setTimeout(() => {
      const output = this.formatZipCode(this.el.nativeElement.value);
      if (output) {
          this.render.setProperty(this.el.nativeElement, 'value', output);
      }
    }, 500);
  }

  @HostListener('blur', ['$event']) onInputChange() {
    try {
      const value = this.el.nativeElement.value.replace(/[^0-9]/g, '') || '';
      if (!isNaN(value) && value) {
          const output = this.formatZipCode(value);
          this.render.setProperty(this.el.nativeElement, 'value', output);
      }
    } catch (e) {
      console.log(e);
    }
  }

  @HostListener('click', ['$event']) onInputMouseChange() {
    try {
      const cursorPosition = this.el.nativeElement.selectionStart;
      const subString = this.el.nativeElement.value.substring(0, this.el.nativeElement.selectionStart);
      const value = (this.el.nativeElement.value).replace(/\D/g, '');
      const commaCount = subString.split(',').length - 1;
      if (!isNaN(value) && value) {
          this.render.setProperty(this.el.nativeElement, 'value', value.toString());
      }
      this.el.nativeElement.setSelectionRange(cursorPosition - commaCount, cursorPosition - commaCount, 'none');
    } catch (e) {
      console.log(e);
    }
  }

   formatZipCode = (str) => {
    try {
      const cleaned = ('' + str).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{4})$/);
      if (match) {
        return match[1] + '-' + match[2];
      }
      return cleaned;
    } catch (error) {
      console.log(error);
    }
  }
}

import * as EvFleetActions from '../actions/evfleet.actions';
import { EvFleetState } from '../state/evfleet.state';

export function evFleetStepCountReducer(state = [], action) {
    switch (action.type) {

        case EvFleetActions.EVFLEET_UPDATE_STEP_COUNT:
            return {...state, ...action.payload };

        case EvFleetActions.EVFLEET_SAVE_STEP_COUNT:
            return state;

        default:
            return state;
    }
}
export function urlValidityReducer(state = [], action) {
    switch (action.type) {

        case EvFleetActions.UPDATE_URLVALIDITY:
            return {...state, ...action.payload };

        case EvFleetActions.SAVE_URL_VALIDITY:
            return state;

        default:
            return state;
    }
}
export function ncasNumberReducer(state = [], action) {
    switch (action.type) {

        case EvFleetActions.UPDATE_URLVALIDITY:
            return {...state, ...action.payload };

        case EvFleetActions.SAVE_URL_VALIDITY:
            return state;

        default:
            return state;
    }
}
export function emailReducer(state = [], action) {
    switch (action.type) {

        case EvFleetActions.UPDATE_EMAIL:
            return {...state, ...action.payload };

        case EvFleetActions.SAVE_EMAIL:
            return state;

        default:
            return state;
    }
}

export function contractNumberDuplicateReducer(state = [], action) {
    switch (action.type) {

        case EvFleetActions.SAVE_DUPLICATE_CONTRACT_NUMBER:
            return {...state, ...action.payload };

        default:
            return state;
    }
}

export function evFleetTermsAndConditionsReducer(state = [], action) {
    switch (action.type) {

        case EvFleetActions.SAVE_TERMS_AND_CONDITIONS:
            return {...state, ...action.payload };

        default:
            return state;
    }
}

export function evFleetChecksheetReducer(state = [], action) {
    switch (action.type) {

        case EvFleetActions.SAVE_CHECKSHEET:
            return {...state, ...action.payload };

        default:
            return state;
    }
}

export const EvFleetReducers: EvFleetState = {
    evFleetstepCount: evFleetStepCountReducer,
    evFleetChecksheet: evFleetChecksheetReducer,
    evFleetTermsAndConditions: evFleetTermsAndConditionsReducer,
    contractNumberDuplicate: contractNumberDuplicateReducer,
    urlValidity:urlValidityReducer,
    ncasNumber: ncasNumberReducer,
    email:emailReducer
};

<form [formGroup]="formCheckSheet">
    <div class="w-100 py-1 pt-3 info_label text-right section-title">
        <p class="mb-1">{{checkSheetObj.extraLabel1}}</p>
        <p class="mb-1">{{checkSheetObj.extraLabel2}}</p>
    </div>
    <div class="w-100 py-3 section-title">
        <h1 [innerHtml]="checkSheetObj.checkSheetTitle"></h1>
        <p [innerHtml]="checkSheetObj.checkSheetDescription"></p>
    </div>
    <div class="check_sheet_cnt_wrp mt-2">
        <ng-container *ngFor="let checkSheet of checkSheetObj.checkSheets; let i=index">
            <div class="row">
                <div class="col-12">
                    
                    <h1 class="font_15"> {{ checkSheet.title }} </h1>
                    <p class="description" [innerHtml]="checkSheet.description">{{checkSheet.description}} </p>

                    
                    <div class="checkbox_custom">
                        <input id="checkbox_{{i}}" name="checkBoxOne" type="checkbox" [formControlName]="i"
                            (change)="onCheckBoxSelection()" />
                        <label for="checkbox_{{i}}" class="checkbox_label"></label>
                    </div>
                    <h6> {{ checkSheet.question }} </h6>
                </div>
            </div>
        </ng-container>
    </div>
</form>
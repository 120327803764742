import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navitype-grade-confirmation',
  templateUrl: './navitype-grade-confirmation.component.html',
  styleUrls: []
})
export class NavitypeGradeConfirmationComponent {
  @Input() naviTypeGradeConfirmationPopupData;
  @Input() vinNumber;
  @Output() closeNaviTypeGradeConfirm = new EventEmitter();
  constructor(private modalService: NgbModal) { }


  confirm(){
    this.closeNaviTypeGradeConfirm.emit();
  }

  cancel() {
    this.modalService.dismissAll();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerLayoutModule } from './customer-layout/customer-layout.module';
import { DealerLayoutModule } from './dealer-layout/dealer-layout.module';
import { ErrorLayoutModule } from './error-layout/error-layout.module';
import { NissanConnectLayoutModule } from './nissan-connect-layout/nissan-connect-layout.module';
import { BizConnectEmailLayoutModule } from './biz-connect-email-layout/biz-connect-email-layout.module';
import { EvFleetLayoutModule } from './ev-fleet-layout/ev-fleet-layout.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DealerLayoutModule,
    CustomerLayoutModule,
    ErrorLayoutModule,
    NissanConnectLayoutModule,
    BizConnectEmailLayoutModule,
    EvFleetLayoutModule
  ]
})
export class LayoutsModule { }

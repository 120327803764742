import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadcrumbData: any;
  @Output() navigateFromBreadCrumb = new EventEmitter();
  
  public isEvFleetModule: boolean = false;

  constructor(private route: ActivatedRoute,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    if(this.route.snapshot.data.isEvFleetModule && this.route.snapshot.data.isEvFleetModule == true)
      this.isEvFleetModule = true
  }

  promptRedirectionPopup(content){
    const initialState: any = { centered: true, keyboard : false, backdrop : 'static' };
    this.modalService.open(content, initialState);
  }

  promptRedirectionCheck(){
    this.navigateFromBreadCrumb.emit(true);
  }
  

}

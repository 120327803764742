<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<div *ngIf="urlValid && !oldUrl" [ngClass]="currentLang === 'jp' ?  'en-japanese': 'en-english'">
    <app-header [oldUrl]="oldUrl"></app-header>
    <router-outlet *ngIf="!alreadySubscribed"></router-outlet>
</div>
<div class="email_otp" *ngIf="!urlValid">
    <div class="otp_icon">
        <div class="background-info">
            <h4 class="text-header">
                <div class="font-error text-font" [innerHTML]="'dealer.evFleet.customer.urlExpired' | translate"></div>
            </h4>
        </div>
    </div>
</div>
<div class="email_otp" *ngIf="oldUrl">
    <app-header [oldUrl]="oldUrl"></app-header>
    <div class="otp_icon old-url-section">
        <div class="">
            <h4 class="text-header">
                <div class="text-center pl-4 old-url-text" [innerHTML]="'dealer.evFleet.customer.summary.oldUrlMessage' | translate">
                </div>
            </h4>
        </div>
    </div>
</div>
<div class="email_otp subscription_success_popup mt-5 pt-5" *ngIf="alreadySubscribed && urlValid">
    <div class="otp_icon p-0">
        <img src="assets/images/payment_success.svg" alt="otp" />
        <h3>{{'dealer.evFleet.customer.summary.completeMsg3' | translate}}</h3>
        <h4>{{'dealer.evFleet.customer.summary.completeMsg4' | translate}} </h4>
    </div>
</div>
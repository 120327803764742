import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { LoaderService } from '@shared/components/loader/service/loader.service';
import { UtilService } from '@shared/utils/utils.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { SecuredService } from '../generic/secured.service';

@Injectable()
export class UsedCarCustomerResolver implements Resolve<Observable<string>> {
  // @ts-ignore
  constructor(private securedService: SecuredService, private loaderService: LoaderService,
              private router: Router, private utilService: UtilService) {}

  resolve() {
    try {
      this.loaderService.showloaderBody();
      const token = this.utilService.tokenFromUrlParams('ref');
      const currentLang = localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp';
      if ( token || localStorage.getItem('c_token') ) {
        if (token) {
          localStorage.removeItem('cVehicleInfo');
          localStorage.setItem('c_token', token);
        }
        return this.securedService.get(`${currentLang}/customer/usedcar`, {}, 'admission').pipe(
          catchError(error => {
            console.error(error);
            this.router.navigate(['error/500'], { queryParams: { redirectURL: 'customer/admission' } });
            return of(null);
        }));
      } else {
        return of({data: {source: 'home', stepCount: 0}});
      }
    } catch (error) {
      return of(null);
    }
  }

}

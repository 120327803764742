import { Action } from '@ngrx/store';

export const EVFLEET_SAVE_STEP_COUNT =  '[EVFLEET] SAVE STEP COUNT';
export const EVFLEET_UPDATE_STEP_COUNT = '[EVFLEET] UPDATE STEP COUNT';
export const UPDATE_URLVALIDITY = '[EVFLEET] UPDATE URL VALIDITY';
export const SAVE_URL_VALIDITY =  '[EVFLEET] SAVE URL VALIDITY';
export const UPDATE_NCASNUMBER =  '[EVFLEET] SAVE NCASNUMBER';
export const SAVE_NCASNUMBER =  '[EVFLEET] SAVE NCASNUMBER';
export const UPDATE_EMAIL =  '[EVFLEET] SAVE EMAIL';
export const SAVE_EMAIL =  '[EVFLEET] SAVE EMAIL';
export const SAVE_DUPLICATE_CONTRACT_NUMBER =  '[EVFLEET] SAVE DUPLICATE CONTRACT NUMBER';
export const SAVE_TERMS_AND_CONDITIONS =  '[EVFLEET] SAVE TERMS AND CONDITIONS';
export const SAVE_CHECKSHEET =  '[EVFLEET] SAVE CHECK SHEET';


export class EvFleetFetchStepCount implements Action {
    readonly type = EVFLEET_SAVE_STEP_COUNT;
    constructor() {}
}

export class EvFleetUpdateStepCount implements Action {
    readonly type = EVFLEET_UPDATE_STEP_COUNT;
    // @ts-ignore
    constructor(private payload: {}) {}
}
export class saveurlValidity implements Action {
    readonly type = SAVE_URL_VALIDITY;
    // @ts-ignore
    constructor() {}
}
export class UpdateUrlValidity implements Action {
    readonly type = UPDATE_URLVALIDITY;
    // @ts-ignore
    constructor(private payload: {}) {}
}
export class savencasNumber implements Action {
    readonly type = SAVE_URL_VALIDITY;
    // @ts-ignore
    constructor() {}
}
export class updatencasNumber implements Action {
    readonly type = UPDATE_URLVALIDITY;
    // @ts-ignore
    constructor(private payload: {}) {}
}
export class saveemail implements Action {
    readonly type = SAVE_URL_VALIDITY;
    // @ts-ignore
    constructor() {}
}
export class updateEmail implements Action {
    readonly type = UPDATE_URLVALIDITY;
    // @ts-ignore
    constructor(private payload: {}) {}
}
export class SaveDuplicateContractNumberStatus implements Action {
    readonly type = SAVE_DUPLICATE_CONTRACT_NUMBER;
    // @ts-ignore
    constructor(private payload: {}) {}
}
export class SaveEvFleetTermsandConditions implements Action {
    readonly type = SAVE_TERMS_AND_CONDITIONS;
    // @ts-ignore
    constructor(private payload: {}) {}
}
export class SaveEvFleetChecksheet implements Action {
    readonly type = SAVE_CHECKSHEET;
    // @ts-ignore
    constructor(private payload: {}) {}
}



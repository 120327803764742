import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { UtilService } from '@shared/utils/utils.service';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public loading = true;
  // @ts-ignore
  constructor(private activatedRoute: ActivatedRoute, private router: Router,//private elementRef : ElementRef
              private titleService: Title, private utilService: UtilService) {
    router.events.subscribe((routerEvent: RouterEvent) => {
      this.checkRouterEvent(routerEvent);
      if (routerEvent instanceof NavigationEnd) {
        /** Dynamic font selection */
        const completeURLPath = location.href;
        const urlPath = completeURLPath.split('?')[0];
        let currentLang;
        if (urlPath.split('/').pop() == '' || window.location.href.indexOf('/dealer') > -1 || (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'dealer')) {
          currentLang = this.dealer_dLang();
        } else if ( window.location.href.indexOf('customer') > -1 || window.location.href.split('/').pop() == 'customer' || window.location.href.indexOf('biz-connect-email') > -1 ||
            (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'customer')) {
            currentLang = this.customer_cLang();
        } else {
          currentLang = localStorage.getItem('nLang') ? localStorage.getItem('nLang') : 'jp';
        }
        if (currentLang === 'jp') {
          document.getElementsByTagName('html').item(0).setAttribute('lang', currentLang);
          document.getElementsByTagName('html').item(0).setAttribute('class', 'jp-lang');
          document.documentElement.style.setProperty('--Nissan_font_Regular', "'Meiryo UI Regular'");
          document.documentElement.style.setProperty('--Nissan_font_Light', "'Meiryo UI Light'");
          document.documentElement.style.setProperty('--Nissan_font_Bold', "'Meiryo UI Bold'");
        } else {
          document.getElementsByTagName('html').item(0).removeAttribute('lang');
          document.getElementsByTagName('html').item(0).removeAttribute('class');
          document.documentElement.style.removeProperty('--Nissan_font_Regular');
          document.documentElement.style.removeProperty('--Nissan_font_Light');
          document.documentElement.style.removeProperty('--Nissan_font_Bold');
        }
      }
    });
    this.updatePageTitle();
  }

  /**
   * @method checkRouterEvent Method to show loader depending on the router event.
   * @param routerEvent RouterEvent.
   */

  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
      this.loading = false;
    }
  }

  updatePageTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data)
        )
        .subscribe(event => {
          this.titleService.setTitle(event.title ? event.title : 'Nissan Connect');
        });
    }

/**
 * @method dealer_dLang Method to get language depending on dealer side.
 */
  dealer_dLang(){
    return localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
  }

/**
 * @method customer_cLang Method to get language depending on customer side.
 */
  customer_cLang(){
    return localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp';
  }
}

<section  *ngIf="packageInfo && packageInfo.packagePlans">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-md-12 col-sm-12 pd-0">
                <div class="new_form_box_wrap">
                    <form autocomplete="off">
                        <div  [ngClass]="loadingFrom != 'evf-taskListing' ? 'form_box_wrp': 'evf-taskListing'">
                            <h1> {{'dealer.evFleet.admission.vehicleInfo.selPackagePlan' | translate}}</h1>
                            <ng-container *ngIf="packageInfo && packageInfo.packagePlans">
                                <div class="package_box_one pilot" *ngFor="let package of packageInfo.packagePlans">
                                    <p class="en_txt_bold">{{ currentLang === 'jp'? package.displayNameJp : package.displayName }} </p>
                                    <div class="amount">
                                    </div>
                                    <div class="radio_custom" (click)="selectPackage(package)">
                                        <ng-container *ngIf="packageInfo.selectedPlan && packageInfo.selectedPlan !=null && package.id === packageInfo?.selectedPlan.id;else notChecked">
                                            <input name="packagePlan" type="radio" formcontrolname="packagePlan"
                                            ng-reflect-name="packagePlan" id="radio0" checked="true">
                                        </ng-container>
                                        <ng-template #notChecked>
                                            <input name="packagePlan" type="radio" formcontrolname="packagePlan"
                                            ng-reflect-name="packagePlan" id="radio0">
                                        </ng-template>
    
                                        <label for="radio0"></label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
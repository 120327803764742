import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent{

  @Input() validationErrors: ValidationErrors[];
  @Input() min: number;
  @Input() max: number;
  @Input() label: string;

}

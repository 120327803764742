import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@shared/components/components.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { DealerLayoutComponent } from './dealer-layout.component';
import { HeaderComponent } from './header/header.component';




@NgModule({
  declarations: [DealerLayoutComponent, HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModalModule,
    TranslateModule,
    ComponentsModule,
    DirectivesModule
  ]
})
export class DealerLayoutModule { }

export const sortListConstants = {
    en: [
        {label: 'Status (Asc)', key: 'status', order: 'asc' },
        {label: 'Status (Desc)', key: 'status', order: 'desc' },
        {label: 'Admission Date (Asc)', key: 'admissionDate', order: 'asc' },
        {label: 'Admission Date (Desc)', key: 'admissionDate', order: 'desc' }
    ],
    jp: [
        {label: '状態（昇順）', key: 'status', order: 'asc' },
        {label: '状態（降順）', key: 'status', order: 'desc' },
        {label: '入会日（昇順）', key: 'admissionDate', order: 'asc' },
        {label: '入会日（降順）', key: 'admissionDate', order: 'desc' }
    ]
};

export const EvFleetsortListConstants = {
    en: [
        {label: 'Status (Asc)', key: 'status', order: 'asc' },
        {label: 'Status (Desc)', key: 'status', order: 'desc' },
        {label: 'Admission Date (Asc)', key: 'admissionDate', order: 'asc' },
        {label: 'Admission Date (Desc)', key: 'admissionDate', order: 'desc' },
        {label: 'NCAS#(Asc)', key: 'ncasNumber', order: 'asc' },
        {label: 'NCAS#(Desc)', key: 'ncasNumber', order: 'desc' }
    ],
    jp: [
        {label: '状態（昇順）', key: 'status', order: 'asc' },
        {label: '状態（降順）', key: 'status', order: 'desc' },
        {label: '入会日（昇順）', key: 'admissionDate', order: 'asc' },
        {label: '入会日（降順）', key: 'admissionDate', order: 'desc' },
        {label: '入会番号（昇順）', key: 'ncasNumber', order: 'asc' },
        {label: '入会番号（降順）', key: 'ncasNumber', order: 'desc' }
    ]
};

export const colspanList = [
    {name : 'unmapped', colspan : 19}, {name : 'mapped', colspan : 22}, {name : 'others_unmapped', colspan : 19},
    {name : 'others_mapped', colspan : 21}, {name : 'not_interested', colspan : 19}
  ];

export const sortListUsedCarConstants = {
    en: [
        {label: 'Status (Asc)', key: 'status', order: 'asc' },
        {label: 'Status (Desc)', key: 'status', order: 'desc' },
        {label: 'Admission Date (Asc)', key: 'admissionDate', order: 'asc' },
        {label: 'Admission Date (Desc)', key: 'admissionDate', order: 'desc' }
    ],
    jp: [
        {label: '状態（昇順）', key: 'statusJp', order: 'asc' },
        {label: '状態（降順）', key: 'statusJp', order: 'desc' },
        {label: '入会日（昇順）', key: 'admissionDate', order: 'asc' },
        {label: '入会日（降順）', key: 'admissionDate', order: 'desc' }
    ]
};

export const sortListDemoCarConstants = {
    en: [
        {label: 'Expiration Month (Asc)', key: 'expirationDate', order: 'asc' },
        {label: 'Expiration Month (Desc)', key: 'expirationDate', order: 'desc' }
    ],
    jp: [
        {label: 'NC有効期限日（昇順）', key: 'expirationDate', order: 'asc' },
        {label: 'NC有効期限日（降順）', key: 'expirationDate', order: 'desc' }
    ]
};

export const sortBizConnectListConstants = {
    en: [
        {label: 'Admission Status (Asc)', key: 'status', order: 'asc' },
        {label: 'Admission Status (Desc)', key: 'status', order: 'desc' },
        {label: 'Admission Date (Asc)', key: 'admissionDate', order: 'asc' },
        {label: 'Admission Date (Desc)', key: 'admissionDate', order: 'desc' }
    ],
    jp: [
        {label: '状態（昇順）', key: 'statusJp', order: 'asc' },
        {label: '状態（降順）', key: 'statusJp', order: 'desc' },
        {label: '入会日（昇順）', key: 'admissionDate', order: 'asc' },
        {label: '入会日（降順）', key: 'admissionDate', order: 'desc' }
    ]
};

export const admissionTypeTooltip  = {
    en: `<p>WEB	: An application has been made for the Nissan Connect service at NCAS or PROFIT.</p>
         <p>Application Form : An application has been made for the Nissan Connect service.</p>
         <p>ー	: NissanConnect service has not been applied.</p>`,
    jp: `<p>WEB	: NCASまたはPROFITにてNissanConnect サービスへ申し込みが行われました</p>
         <p>申込書	: 申込書にてNissanConnect サービスへ申し込みが行われました</p>
         <p>ー	: NissanConnect サービスへの申し込みは行われていません</p>`
};

export const statusTooltip = {
    en: `<p>Customer Filling	: Customer is entering the information required to enroll for NissanConnect service on their device.</p>
        <p>PROFIT Order No. not registered	: PROFIT Order Number is not registered in NCAS.</p>
        <p>PROFIT Order No. Registered	: The PROFIT Order Number has been registered in NCAS (the registration from PROFIT to CARWINGS has not been completed).</p>
        <p>C/A application completed	:  All the application work for Nissan Connect service by C/A has been completed.</p>
        <p>Registration completed (ID notification in progress)	: Vehicle information has been registered from PROFIT to CARWINGS.</p>
        <p>Registration completed (ID notification sent)	: Notification process of the Nissan Connect Service ID to the Customer has started.</p>
        <p>Application cancel	: Nissan Connect Service application has been cancelled at Profit.</p>
        `,
    jp: `<p>お客さま入力中	: お客さまの端末でNissanConnect サービスの入会に必要な情報を入力中です</p>
        <p>PROFIT注文書No.未登録	: NCASにてPROFIT注文書No.が登録されていません</p>
        <p>PROFIT注文書No.登録済み	: NCASにてPROFIT注文書No.が登録済みです（PROFITからCARWINGSへの登録が完了していません）</p>
        <p>C/A申込作業完了	: C/AによるNissanConnect サービスの申し込み作業がすべて完了しています</p>
        <p>登録完了（ID通知準備中）	: PROFITからCARWINGSへ車両情報を登録済みです</p>
        <p>登録完了（ID通知送信済み）	: お客さまへNissanConnect サービスのIDの通知処理が開始されました</p>
        <p>申込キャンセル	: PROFITでNissanConnect サービスの申し込みがキャンセルされました</p>
        `
};

export const statusTooltipWithExtLink = {
    en: `<p>Customer Filling	: Customer is entering the information required to enroll for NissanConnect service on their device.</p>
        <p>PROFIT Order No. not registered	: PROFIT Order Number is not registered in NCAS.</p>
        <p>PROFIT Order No. Registered	: The PROFIT Order Number has been registered in NCAS (the registration from PROFIT to CARWINGS has not been completed).</p>
        <p>C/A application completed	:  All the application work for Nissan Connect service by C/A has been completed.</p>
        <p>Registration completed (ID notification in progress)	: Vehicle information has been registered from PROFIT to CARWINGS.</p>
        <p>Registration completed (ID notification sent)	: Notification process of the Nissan Connect Service ID to the Customer has started.</p>
        <p>Application cancel	: Nissan Connect Service application has been cancelled at Profit.</p>
        `,
    jp: `<p>お申込手続きの進捗状況を表しています。</p>
         <p>詳細は以下のページをご覧ください。</p>
         <p><a class="link" href="https://saas.nissan.co.jp/portal/uploads/nc_manuals/prod/ncas_notice.html" target="_blank">■「状態」文言詳細</a></p>
        `
};

export const phase2statusTooltip = {
    en: `<p>Customer Filling	: Customer is entering the information required to enroll for NissanConnect service on their device.</p>
         <p>Registration completed	: Your application has been completed, but the vehicle verification information has not been registered in NCAS.</p>
         <p>C/A application completed	:  All the application work for Nissan Connect service by C/A has been completed.</p>
         <p>Registration completed (ID notification in progress)	: Vehicle information has been registered from PROFIT to CARWINGS.</p>
         <p>Registration completed (ID notification sent)	: Notification process of the Nissan Connect Service ID to the Customer has started.</p>
        `,
    jp: `<p>お客さま入力中　：　お客さまの端末でNissanConnect サービスの入会に必要な情報を入力中です</p>
         <p>お客さま申込完了　：　お客様の申し込みが完了していますが、NCASにて車検証の情報が登録されていません</p>
         <p>C/A申込作業完了　：　C/AによるNissanConnect サービスの申し込み作業がすべて完了しています</p>
         <p>登録完了（ID通知準備中）　：　PROFITからCARWINGSへ車両情報を登録済みです</p>
         <p>登録完了（ID通知送信済み）　：　お客さまへNissanConnect サービスのIDの通知処理が開始されました</p>
        `
};


export enum DEALER_TYPE {
    Business = 1,
    CA = 2,
    IB4 = 3,
    JF0 = 4,
    Kameari = 5
}
export const statusTooltipforEvfleet = {
    en: `<p>お申込手続きの進捗状況を表しています。</p>
    <p>詳細は以下のページをご覧ください。</p>
    <p><a class="link" href="https://saas.nissan.co.jp/portal/uploads/nc_manuals/prod/ncas_notice.html" target="_blank">■「状態」文言詳細</a></p>
    `,
    jp: `<p>お申込手続きの進捗状況を表しています。</p>
         <p>詳細は以下のページをご覧ください。</p>
         <p><a class="link" href="https://saas.nissan.co.jp/portal/uploads/nc_manuals/prod/ncas_notice.html" target="_blank">■「状態」文言詳細</a></p>`

};

export const renewalListConstants = {
    defaultPageSize: 30,
    freezeDate: 21,
    oldMonthCount: 5,
    expiryDateFormat: 'yyyy/MM',
    cwWithdrawRenewalStatusEn:'RENEWAL_CANCEL_WITHDRAW_FROM_CW',
    cwWithdrawRenewalStatusJp:'更新中止（会員webから退会済み）',
}

export const carType = {
    demoCar:'Demo Car',
    companyCar:'Company Car'
}
export const eFlag = {
    key: 'eFlag',
    value: true
}

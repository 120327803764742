import { Component, ElementRef, HostListener, OnInit, SecurityContext, ViewChild} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { DEALER_TYPE } from '@shared/constants/dealer.constants';
import { DealerState } from 'app/store/dealer/state/dealer.state';
import { Subject, Subscription } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { eFlag } from '@shared/constants/dealer.constants';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public showNavigationLinks = false;
  public showLanguageToggleBtn = false;
  public confirmNavigation = false;
  public isENG = localStorage.getItem('dLang') === 'en' ? true : false;
  public usedCar = false;
  public usedCarStep = 1;
  public dealerName;
  public completedHTML = `<i class='fas fa-check'></i>`;
  public currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
  public isBizConnectModule = false;
  isBusinessUser = false;
  hideAdminstratorSetting = false;
  showSettingsMenu = false;
  showSettingsEVMenu = false;
  @ViewChild('promptRedirection', {static: false}) promptRedirectionPopupView: ElementRef;
  @ViewChild('ncExpiryReminderPopup', {static: false}) ncExpiryReminderPopupView: ElementRef;
  @ViewChild('naviIdExpiryReminderPopup', {static: false}) naviIdExpiryReminderPopupView: ElementRef;
  @ViewChild('ReminderSettingsPopup', {static: false}) ReminderSettingsPopupView: ElementRef;
  // @ViewChild('admin', {static: false}) admin: ElementRef;
  @ViewChild('adminHam', {static: false}) adminHam: ElementRef;
  ngDestroyed = new Subject();
  subscription1: Subscription;
  isEvFleetModuleModule: any;
  activeTabSelected: any;
  selectedsettings: any;
  public showNcExpiryReminderSetting :boolean = false;
  constructor(private modalService: NgbModal, private activatedRoute: ActivatedRoute,
              private router: Router, private store: Store<DealerState>,
              private sanitizer: DomSanitizer) {
    try {
      this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data)
        ).pipe(takeUntil(this.ngDestroyed)).subscribe(event => {
          this.showNavigationLinks = false;
          this.showLanguageToggleBtn = false;
          this.confirmNavigation = false;
          this.usedCar = false;
          this.isBizConnectModule = false;
          this.isEvFleetModuleModule = false;
          if (event.showNavigationLinks) {
            this.showNavigationLinks = event.showNavigationLinks;
          }

          if (event.showLanguageToggleBtn) {
            this.showLanguageToggleBtn = event.showLanguageToggleBtn;
          }

          if ( event.confirmNavigation ) {
            this.confirmNavigation = event.confirmNavigation;
          }
          if ( event.usedCar ) {
            this.usedCar = event.usedCar;
            this.usedCarStep = event.step;
          }
          if(event.isBizConnect) {
            this.isBizConnectModule = event.isBizConnect
          }
          if(event.isEvFleetModule) {
            this.isEvFleetModuleModule = event.isEvFleetModule
          }
          
          this.hideAdminstratorSetting = !this.isBusinessUser || this.router.url.indexOf('configuration') > -1;
          this.showSettingsMenu = false;
          this.showSettingsEVMenu = false;
        });
    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit() {
    this.subscription1 = this.store.select('dealerInfo').subscribe((data) => {
      this.dealerName = data.caName;
      this.isBusinessUser = ( data.dealerType === DEALER_TYPE[3] || data.dealerType === DEALER_TYPE[4] ) ? true : false;
      this.hideAdminstratorSetting = !this.isBusinessUser || this.router.url.indexOf('configuration') > -1;
    });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  switchLang(flag) {
    if (localStorage.getItem('dLang') !== flag) {
      localStorage.setItem('dLang', flag);
      localStorage.removeItem('formData');
      if(this.router.url === '/dealer/ev-fleet/register-order-number/task-list'){
        sessionStorage.setItem('cLangSwitch', JSON.stringify({router: '/dealer/ev-fleet/register-order-number/task-list'}))
      }
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  }

  logout() {
      const dToken = localStorage.getItem('d_token');
      localStorage.removeItem('d_token');
      localStorage.removeItem('formData');
      if (dToken) {
        const encodedString = btoa(dToken);
        let URL;
        if(eFlag.value == true){
          URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_LOGOUT_URL}?${eFlag.key}=${eFlag.value}&key=${encodedString}`);
        }else{
          URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_LOGOUT_URL}?key=${dToken}`);
        }
        window.location.href = URL;
      } else {
        const URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_URL}`);
        window.location.href = URL;
      }
  }

  promptRedirectionPopup() {
    if (this.confirmNavigation) {
      this.modalService.open(this.promptRedirectionPopupView, { centered: false, windowClass: 'alignTop' });
    } else {
      this.router.navigate(['/dealer']);
    }
  }
  showAdminstratorMenu() {
    this.showSettingsMenu = !this.showSettingsMenu;
  }
  showAdminstratorEVFleetMenu(){
    this.showSettingsEVMenu = !this.showSettingsEVMenu;
    this.activeTabSelected = 'Customer';
  }
  closeEVFleetMenu(){
    this.showSettingsEVMenu = false;
  }
  navigateToAlerts() {
    this.router.navigate(['/dealer/configuration']);
  }

  navigateToNCExpiryReminder() {
    if ( environment.NC_EXPIRY_POPUP ) {
      this.modalService.open(this.ncExpiryReminderPopupView, { centered: false, windowClass: 'alignTop' });
    } else {
      this.router.navigate(['/dealer/ncid-expiry-reminder-settings']);
    }
  }

  navigateToNaviIdReminder() {
    if ( environment.NAVI_EXPIRY_POPUP ) {
      this.modalService.open(this.naviIdExpiryReminderPopupView, { centered: false, windowClass: 'alignTop' });
    } else {
      this.router.navigate(['/dealer/naviid-reminder-settings']);
    }
  }
  navigateToReminderSettings(selection){
    this.closeEVFleetMenu();
    this.selectedsettings = {
      name : selection,
      group : this.activeTabSelected
    }
    this.modalService.open(this.ReminderSettingsPopupView, { centered: false, windowClass: 'alignTop' });

  }
  SwitchTabs(selectedtab){
    this.activeTabSelected = selectedtab;
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.adminHam && this.adminHam.nativeElement) {
      if (!this.adminHam.nativeElement.contains(event.target)) {
        this.showSettingsMenu = false;
      }
    }
  }

  ngOnDestroy() {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
    if(this.subscription1){
      this.subscription1.unsubscribe();
    }
  }
}


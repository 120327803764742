import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SecuredService } from '@core/services/generic/secured.service';

import { Store } from '@ngrx/store';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalValidator } from '@shared/validations/validators';
import { ToastService } from '@shared/components/toaster/service/toaster.service';

import { SaveNewCarVehicleInfo } from 'app/store/dealer/actions/dealer.actions';
import { DealerState } from 'app/store/dealer/state/dealer.state';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-search-order',
  templateUrl: './search-order.component.html',
  styleUrls: ['./search-order.component.scss']
})
export class SearchOrderComponent implements OnInit {
  @Output() hideSearchOrderInfoPopup = new EventEmitter();
  @ViewChild('searchOrderPopup', {static : true}) searchOrderPopupElementView: ElementRef;
  @ViewChild('showCapProfitDownPopup', {static : true}) showCapProfitDownPopupView: ElementRef;
  @ViewChild('showErrorResponse', {static : true}) showErrorResponseView: ElementRef;


  currentLang: string;
  formSearchOrder: FormGroup;
  formSubmitted: boolean;
  submitted: boolean;
  responseMsg: any;
  public dealerType;
  public tempOrderNumber;
  public isIb4Jf0 = false;

  constructor(private modalService: NgbModal, private toastService: ToastService,
              private formBuilder: FormBuilder, private router: Router,
              private securedService: SecuredService, private store: Store<DealerState> ,private translate: TranslateService) { }

  /**
   * @method ngOnInit
   * @description Form and other Initialization.
   */

  ngOnInit(): void {
    this.currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
    this.initSearchOrderForm();
    this.openSearchOrderPopup();
    this.store.select('dealerInfo').subscribe((data) => {
      if( data && data.dealerType ) {
        this.dealerType = data.dealerType;
        if(this.dealerType === 'IB4' || this.dealerType === 'JF0'){
          this.isIb4Jf0 = true;
        }else{
          this.isIb4Jf0 = false;
        }
      }
    });
  }

  /**
   * @method initSearchOrderForm
   * @description Initializing search order form
   */

  initSearchOrderForm() {
    try {
      this.formSearchOrder = this.formBuilder.group({
        tempOrderNumber: [null, [ GlobalValidator.nonEmpty, Validators.minLength(9),
                                  Validators.maxLength(9), GlobalValidator.ordernoAlphaNumeric]],
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method openSearchOrderPopup
   * @description search order popup
   */

  openSearchOrderPopup() {
    try {
      const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
      this.modalService.open(this.searchOrderPopupElementView, initialState);
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method controls
   * @description Returns form controls.
   */

  get controls() {
    return this.formSearchOrder.controls;
  }

  /**
   * @method dismiss
   * @description Hide all popups.
   */

  dismiss() {
    try {
      this.hideSearchOrderInfoPopup.emit();
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method checkVIN
   * @description Checking vehicle Info
   */

  searchOrderInfo() {
    try {
      this.submitted = true;
      const tempOrderNumber = this.controls.tempOrderNumber.value;
      this.securedService.get(`${this.currentLang}/profit/${tempOrderNumber}`, {}, 'admission').subscribe((response) => {
        this.submitted = false;
        if (response && response.data) {
          this.modalService.dismissAll();
          localStorage.setItem('new_car_vehicle_info' , JSON.stringify(response.data));
          this.store.dispatch(new SaveNewCarVehicleInfo(response.data));
          this.router.navigate(['/dealer/new-car']);
        }
      }, err => {
        this.submitted = false;
        this.modalService.dismissAll();
        this.hideSearchOrderInfoPopup.emit();
        if (err && err.error && err.error.code === '460' && err.error.data) {
          this.responseMsg = err.error.message;
          localStorage.setItem('new_car_vehicle_info' , JSON.stringify(err.error.data));
          this.store.dispatch(new SaveNewCarVehicleInfo(err.error.data));
          const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
          this.modalService.open(this.showCapProfitDownPopupView, initialState);
        } else {
          if(err.error && err.error.message){
          this.responseMsg = err.error.message;
          } else{
            this.translate.get('unexpectdError').subscribe((text: string) => {
              this.responseMsg =  text;
            });
          }
          const initialState: any ={centered: false, backdrop : 'static', keyboard : false,  windowClass: 'alignTop'};
          this.modalService.open(this.showErrorResponseView, initialState);
        }
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method close
   * @description Close modal popup and redirecting to vehicle identification page
   */
  close() {
    this.modalService.dismissAll();
    if(this.dealerType === 'IB4' || this.dealerType === 'JF0'){
    this.router.navigate(['/dealer/new-car']);
    }
  }
}

<form [formGroup]="formTnc" class="tnc-form">
    <div class="w-100 py-3 text-center section-title">
        <h1 [innerHtml]="tncObj.tncTitle"></h1>
        <p [innerHtml]="tncObj.tncDescription"></p>
    </div>
    <div class="check_sheet_cnt_wrp mt-2 pb-0 mb-3 evf-terms-and-conditions">
        <div class="row">
            <div class="col-12" [innerHTML]="tncObj.tncContent" >
            </div>
        </div>
        <div class="row my-4 ">
            <div class="col-12">
                <div class="floating_label_wrp">
                    <div class="radio_custom">
                        <input id="terms_cnt_rd" name="understandTermsAndConditions" type="checkbox" formControlName="understandTermsAndConditions" (change)="onCheckBoxSelection()" />
                        <label for="terms_cnt_rd"></label>							
                    </div>
                    <label for="terms_cnt_rd" class="label_custom required cursor mt-1 ml-2"  > {{'dealer.evFleet.customer.tnc.agree' | translate}} </label>
                </div>								
            </div>
        </div>
    </div>
</form>
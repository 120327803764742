import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { UtilService } from '@shared/utils/utils.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SecuredService } from '../generic/secured.service';

@Injectable()
export class BizEmailResolver implements Resolve<Observable<string>> {
  // @ts-ignore
  constructor(private utilService: UtilService, private securedService: SecuredService,
              private router: Router) {}

  resolve() {
    try {
        const token = this.utilService.getUrlParameter(window.location.href, 'token');
        const currentLang = localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp';
        if ( token ) {
          localStorage.setItem('home_token',token);
          return this.securedService.post({}, currentLang + '/bizCustomer/url/validate', {}, 'notification').pipe(
            catchError(error => {
              console.error(error);
              this.router.navigate(['error/500'], { queryParams: { redirectURL: 'biz-connect-email' } });
              return of(null);
          }));
        }else if ( localStorage.getItem('home_token') ) {
          return this.securedService.post({}, currentLang + '/bizCustomer/url/validate', {}, 'notification').pipe(
            catchError(error => {
              console.error(error);
              this.router.navigate(['error/500'], { queryParams: { redirectURL: 'biz-connect-email' } });
              return of(null);
            }));
        } else {
            this.router.navigate(['error/500'], { queryParams: { redirectURL: 'biz-connect-email' } });
            return of(null);
        }
    } catch (error) {
      return of(null);
    }
  }
}

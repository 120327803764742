import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router} from '@angular/router';
import { Store } from '@ngrx/store';
import { EvFleetState } from 'app/store/evfleet/state/evfleet.state';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-breadcrumb-evfleet',
  templateUrl: './breadcrumb-evfleet.component.html',
  styleUrls: ['./breadcrumb-evfleet.component.scss']
})
export class BreadcrumbEvfleetComponent implements OnInit {
  public pageNumber: number;
  public completedHTML = `<i class='fas fa-check'></i>`;
  public alreadySubscribed: boolean = false;
  ngDestroyed = new Subject();
  subscription1: Subscription;
  constructor(public router: Router, private evfleetstore: Store<EvFleetState>) {
    router.events.pipe(takeUntil(this.ngDestroyed)).subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.activateBreadCrumb(val.url);
      }
    });

    this.subscription1 = this.evfleetstore.select('evFleetstepCount').subscribe((response) => {
      if(response && response.status_en === 'CUSTOMER_ENTRY_COMPLETED'){
        this.alreadySubscribed = true;
      }else if(response && response.status_en === 'SUBSCRIBED'){
        this.pageNumber = 5;
      }
    });
  }

  activateBreadCrumb(currentUrlString: string){
    let check = currentUrlString.includes("?");
    let currentUrl = currentUrlString;
    if(check){
      currentUrl = currentUrlString.split('?')[0];
    }
    if (currentUrl === '/customer/ev-fleet/customer-entry' || currentUrl === '/customer/ev-fleet/email-otp') {
      this.pageNumber = 1; //custimer verification
    }else if (currentUrl === '/customer/ev-fleet/checksheet' || currentUrl === '/customer/ev-fleet/tnc') {
      this.pageNumber = 2; //checksheet
    }else if (currentUrl === '/customer/ev-fleet/customer-info') {
      this.pageNumber = 3; //customer info
    }else if (currentUrl === '/customer/ev-fleet/summary') {
      this.pageNumber = 4; //customer info
    }
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;
    this.activateBreadCrumb(currentUrl);
  }

  ngOnDestroy() {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
    if(this.subscription1){
      this.subscription1.unsubscribe();
    }
  }

}

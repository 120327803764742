
    <div class="home_box_updated red_border evf-card">
        <a href="javascript:;">
            <img src="{{cardDisplayItems.icon}}" alt="{{cardDisplayItems.altText}">
            <h5 class="top">
                <p class="m-0">{{cardDisplayItems.subTitle| translate }} </p>
                {{cardDisplayItems.title| translate}} 
                <p class="font_11">{{cardDisplayItems.description | translate}}</p>
            </h5>
        </a>
        <span>{{cardDisplayItems.cornerText}}</span>
    </div>

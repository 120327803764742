import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from '@shared/utils/utils.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';




@Injectable()
export class InterceptorService implements HttpInterceptor {

    constructor(private errorHandlerService: ErrorHandlerService, private utilService: UtilService) { }

    /**
     * @method intercept Intercepts each request send to the server.
     * @param request HttpRequest.
     * @param next HttpHandler.
     * @description Injects token and other headers along with the API request. Incase of error, passed on to the error handler
     */

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token;
        let headers;

        const completeURLPath = location.href;
        const urlPath = completeURLPath.split('?')[0];
        if (urlPath.split('/').pop() == '' || window.location.href.indexOf('/dealer') > -1 || (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'dealer')) {
            token = this.d_tokenVal();
            if (request.url.indexOf('colorPalette.json') > -1) {
                headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                });
            } else if (request.url.indexOf('.json') > -1) {
                headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                });
            } else if (request.url.indexOf('upload-vinlist') > -1) {
                headers = new HttpHeaders({
                    Authorization: token,
                    Accept: '*/*',
                });

                // tslint:disable-next-line: max-line-length
            } else if (request.url.indexOf('orders-download') == -1 && request.url.indexOf('download-vins') == -1 && request.url.indexOf('deletedOrders-download') == -1 && request.url.indexOf('test-pr-car/download') == -1) {
                headers = new HttpHeaders({
                    'Authorization': token
                });
            } else {
                headers = new HttpHeaders({
                    'Authorization': token,
                    'Content-Type': 'text/csv'
                });
            }
            this.noToken(token);
        } else if (window.location.href.indexOf('customer/ev-fleet') > -1) {
            token = localStorage.getItem('evf_c_token') ? localStorage.getItem('evf_c_token') : '';
            if(request.url.indexOf('.json') > -1){
                headers = new HttpHeaders();
            }else if(request.url.indexOf('.pdf') > -1){
                headers = new HttpHeaders();
            }else{
                headers = new HttpHeaders({
                    Authorization: token,
                    Accept: '*/*',
                });
            }
        } else if (window.location.href.indexOf('customer') > -1 ||
                   window.location.href.split('/').pop() == 'customer' ||
                   (this.utilService.getUrlParameter(window.location.href, 'redirectURL') == 'customer')) {
                    token = this.c_tokenVal();
                    if (request.url.indexOf('colorPalette.json') > -1) {
                        headers = new HttpHeaders({
                            'Content-Type': 'application/json',
                            Accept: '*/*',
                        });
                    } else if (request.url.indexOf('.json') > -1) {
                        headers = new HttpHeaders({
                            'Content-Type': 'application/json',
                            Accept: '*/*',
                        });
                    }else if (request.url.indexOf('upload') == -1 && request.url.indexOf('download') == -1) {
                        headers = new HttpHeaders({
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': token,
                            'Cache-Control': 'no-cache, no-store',
                            'Pragma': 'no-cache'
                        });
                    } else {
                        headers = new HttpHeaders({
                            Authorization: token,
                            Accept : '*/*',
                        });
                    }
        } else if (request.url.indexOf('biz-connect-email') == -1) {
            token = this.utilService.tokenFromUrlParams('token');
            headers = new HttpHeaders({
                Authorization: this.homeTokenVal(token),
                Accept: '*/*',
            });

        } else {
            token = this.utilService.tokenFromUrlParams('token');
            headers = new HttpHeaders({
                'Authorization': token,
                'Cache-Control': 'no-cache, no-store',
                'Pragma': 'no-cache'
            });
        }

        const modifiedRequest = request.clone({ headers });

        return next.handle(modifiedRequest).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.errorHandlerService.handleError(error);
                return throwError(error);
            })
        );
    }

    d_tokenVal(){
       if(localStorage.getItem('d_token')){
        return localStorage.getItem('d_token');
       } 
       else{
        return '';
       }
    }
    noToken(token){
        if (!token) {
            return EMPTY;
        }
    }
    c_tokenVal(){
        if(localStorage.getItem('c_token')){
            return localStorage.getItem('c_token') ;
        }
        else{
            return '';
        }
    }
    homeTokenVal(token){
        if(localStorage.getItem('home_token')){
            return localStorage.getItem('home_token');
        }
        else{
            return token;
        }
    }
}

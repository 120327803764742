import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UtilService } from '@shared/utils/utils.service';
import { UsedCarCustomerState } from 'app/store/customer-used-car/state/customer-used-car.state';
import { CustomerState } from 'app/store/customer/state/customer.state';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public pageNumber: number;
  public pageTitle: string;
  public nextPageTitle: string;
  public stepClass: string;
  public showPreviousButton = false;
  public previousPageURL;
  public completedHTML = `<i class='fas fa-check'></i>`;
  public alreadySubscribed = false;
  public isFromSummaryPage = false;
  public isUsedCar = false;
  public usedCarStep = 1;
  public source = 'home';
  public hasUCVehicleInformation = false;
  public isENG = localStorage.getItem('cLang') === 'en' ? true : false;
  totalPages: number;
  freePlan = false;
  usedCarfreePlan = false;
  constructor(private activatedRoute: ActivatedRoute, public router: Router,
              private utilService: UtilService, private usedCarstore: Store<UsedCarCustomerState>,
              private store: Store<CustomerState>) {
    const currentLang = localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp';
    try {
      this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data)
        )
        .subscribe(event => {
          this.isUsedCar = false;
          if (event && event.page) {
            this.pageNumber = event.page;
            this.isUsedCar = false;
            this.isFromSummaryPage = this.utilService.getUrlParameter(window.location.href, 'redirect') === 'summary';
            // tslint:disable-next-line: no-string-literal
            this.pageTitle = event.headerTitle['current'][currentLang];
            // tslint:disable-next-line: no-string-literal
            let headerTitleLang = event.headerTitle['next'][currentLang] ? `Next- ${event.headerTitle['next'][currentLang]}` : '';
            this.nextPageTitle = headerTitleLang;
            this.stepClass = event.class;
            this.showPreviousButton = event.showPreviousPage;
            this.alreadySubscribed = false;
            this.usedcarCheckAndRedirection(event);
          } else {
            let usedCarFlag = (event.usedCar) ? event.usedCar : false;
            this.isUsedCar = usedCarFlag;
            this.alreadySubscribed = true;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  navigateTo(url) {
    if (this.pageNumber == 4) {
      this.router.navigate([url], { queryParams: { redirect: 'summary' }});
    } else {
      this.router.navigate([url]);
    }
  }

  switchLang(flag) {
    if (localStorage.getItem('cLang') !== flag) {
      localStorage.setItem('cLang', flag);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  }

  eventPage4(event){
    if ( this.usedCarfreePlan && event.page == 4 ) {
      this.pageNumber = 3;
    }
  }

  sourceDealer(response , event){
    if (response.adminFee === 0 && response.amount === 0) {
      this.usedCarfreePlan = true;
      this.totalPages = 3;
      this.eventPage4(event);
    } else {
      this.totalPages = 4;
    }
  }

  eventPage5(event){
    if ( this.usedCarfreePlan && event.page == 5 ) {
      this.pageNumber = 4;
    }
  }

  sourceHome(response , event){
    if (response.adminFee === 0 && response.amount === 0) {
      this.usedCarfreePlan = true;
      this.totalPages = 4;
      this.eventPage5(event);
    } else {
      this.totalPages = 5;
    }
  }

  customerInfoResponse(event){
    /** Skip payment Phase 1 admissions */
    this.store.select('customerInfo').subscribe((response) => {
      if (response && response.amount === 0) {
        this.freePlan = true;
        if ( this.freePlan && event.page == 4 ) {
          this.pageNumber = 3
        }
      }
     });
  }

  sourceResponse(event){
    this.usedCarstore.select('usedCarcustomerInfo').subscribe((response) => {
      if (response.source) {
        this.source = response.source;
        if (this.source === 'dealer') {
           this.sourceDealer(response , event);     
        } else if (this.source === 'home') {
          this.sourceHome(response , event);         
        }
      }
    });
  }

  ucvFlag(){
    if (localStorage.getItem('cVehicleInfo')) {
      this.hasUCVehicleInformation = true;
    } else {
      this.hasUCVehicleInformation = false;
    }
  }

  usedcarCheckAndRedirection(event){
    if (event.showPreviousPage) {
      this.previousPageURL = event.previousURL;
    }
    if ( event.usedCar ) {
      this.isUsedCar = event.usedCar;
      this.ucvFlag();
      this.sourceResponse(event);
      this.usedCarstore.select('usedCarstepCount').subscribe((response) => {
        this.usedCarStep = response.stepCount + 1;
      });
    } else {
      this.customerInfoResponse(event); 
    }
  }
}

<section class="popup_title_grey sm">
    <h5 class="mb-0" *ngIf="currentLang == 'en'">How to confirm dealer option Navi ID</h5>
    <h5 class="mb-0" *ngIf="currentLang == 'jp'">ディーラーナビゲーションシステムでの確認方法</h5>
    <a href="javascript:void(0);" class="popup_close" aria-label="Close" (click)="dismiss()">
        <svg class="bi bi-x" width="30px" height="30px" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M5.646 5.646a.5.5 0 000 .708l8 8a.5.5 0 00.708-.708l-8-8a.5.5 0 00-.708 0z" clip-rule="evenodd"></path>
            <path fill-rule="evenodd" d="M14.354 5.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z" clip-rule="evenodd"></path>
        </svg>
    </a>
</section>
<section>
    <div class="system_block_wrapper">
        <div class="row">
            <div class="col-12" *ngIf="currentLang == 'en'">
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>Follow the steps below to check.</p>
                    </div>
                </div>
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY13 Model】 MM113D-W/A、MM513D-L</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>Settings > System Settings> Others > Version Information</p>
                    </div>
                </div>
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY13 Model】 MP313D-W/A</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>Setting/Edit > System Information</p>
                    </div>
                </div>
    
                    
    
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY13 Model】 MC313D-W/A</p>
                        <p>【FY14 Model】 MC314D-W/A</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>Setting > Phonecall > CARWINGS > Vehicle function ID display</p>
                    </div>
                </div>
    
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY14 Model】 MM514D-L・MM114D-W/A、MP314D-W/A</p>
                        <p>【FY15 Model】 MM515D-L・MP315D-W/A、MC315D-W/A/WM</p>
                        <p>【FY16 Model】 MM516D-W/L、MM316D-W/A</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>Menu > Information/Setting > System Setting > Dealercompanyexclusive Menu > CARWINGS Information</p>
                    </div>
                </div>
    
                    
    
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY17 Model】 MM517D-W/L、MM317D-W/A</p>
                        <p>【FY18 Model】 MM518D-L/W、MM318D-L/W/A</p>
                        <p>【FY19 Model】 MM519D-L/W、MM319D-L/W/A</p>
                        <p>【FY20 Model】 MM520D-L、MM320D-L、MJ320D-W/A</p>
                        <p>【FY21 Model】 MM521D-L、MM321D-L、MJ321D-W/A</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>Menu > Information/setting > System Setting > Other Setting > Dealer Company Eclusive Menu > Nissan Connect Service Information
                        </p>
                    </div>
                </div>
    
                    
    
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY15 Model】 MM115D-W/A</p>
                        <p>【FY16 Model】 MJ116D-W/A</p>
                        <p>【FY17 Model】 MJ117D-W/A </p>
                        <p>【FY18 Model】 MJ118D-W/A </p>
                        <p>【FY19 Model】 MJ119D-W/A </p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>Not compatible with Nissan Connect service</p>
                    </div>
                </div>
    
                    
                
            </div>
    
    
    
            <div class="col-12" *ngIf="currentLang == 'jp'">
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>以下の手順で確認してください。</p>
                    </div>
                </div>
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY13モデル】 MM113D-W/A、MM513D-L</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>設定　＞　システム設定　　＞その他　＞　バージョン情報</p>
                    </div>
                </div>
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY13モデル】 MP313D-W/A</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>設定・編集　＞　システム情報</p>
                    </div>
                </div>
    
                    
    
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY13モデル】 MC313D-W/A</p>
                        <p>【FY14モデル】 MC314D-W/A</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>設定　＞　電話発信　＞　CARWINGS　＞　車載機IDの表示</p>
                    </div>
                </div>
    
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY14モデル】 MM514D-L・MM114D-W/A、MP314D-W/A</p>
                        <p>【FY15モデル】 MM515D-L・MP315D-W/A、MC315D-W/A/WM</p>
                        <p>【FY16モデル】 MM516D-W/L、MM316D-W/A</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>メニュー　＞　情報・設定　＞　システム設定　＞　その他設定　＞　販売会社専用メニュー　＞　CARWINGS情報</p>
                    </div>
                </div>
    
                    
    
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY17モデル】 MM517D-W/L、MM317D-W/A</p>
                        <p>【FY18モデル】 MM518D-L/W、MM318D-L/W/A</p>
                        <p>【FY19モデル】 MM519D-L/W、MM319D-L/W/A</p>
                        <p>【FY20モデル】 MM520D-L、MM320D-L、MJ320D-W/A</p>
                        <p>【FY21モデル】 MM521D-L、MM321D-L、MJ321D-W/A</p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>メニュー　＞　情報・設定　＞　システム設定　＞　その他設定　＞　販売会社専用メニュー　＞　NissanConnect サービス情報</p>
                    </div>
                </div>
    
                    
    
                <div class="system_block row">
                    <div class="col-md-6 p-0 text-left">
                        <p>【FY15モデル】 MM115D-W/A</p>
                        <p>【FY16モデル】 MJ116D-W/A</p>
                        <p>【FY17モデル】 MJ117D-W/A </p>
                        <p>【FY18モデル】 MJ118D-W/A </p>
                        <p>【FY19モデル】 MJ119D-W/A </p>
                    </div>
                    <div class="col-md-6 p-0 text-right">
                        <p>NissanConnect サービス非対応</p>
                    </div>
                </div>
    
                    
                
            </div>
        </div>
    </div>
</section>

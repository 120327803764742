import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit {
  @Input() modal;
  @Input() confirmationPopupData;
  @Output() closeOrderConfirm = new EventEmitter();
  constructor(private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {}

  dismiss() {
    this.closeOrderConfirm.emit();
  }

  /**
   * @method close
   * @description Close modal popup and redirecting to vehicle identification page
   */
  close() {
    this.modalService.dismissAll();
    this.router.navigate(['/dealer/biz-connect/vehicle-info']);
  }
}


<header class="header_wrp error_wrp d-flex align-items-center common-header">
    <div class="header-container-wrap">
        <div class="container">
            <div class="d-flex main_nav align-items-center flex-wrap">
                <div class="flex-auto nissan-n-logo d-none d-sm-none d-md-none d-lg-block">
                    <a href="javascript:void(0);" (click)="navigateToHomePage()">
                        <img src="assets/images/Nissan-r-logo.svg"  alt="Nissan" class="nissan_logo" />
                    </a>                   
                </div>
                <div class="flex-auto nissan-n-logo logo-sm-width d-none d-sm-block d-lg-none">
                    <a href="javascript:void(0);" (click)="navigateToHomePage()">
                        <img src="assets/images/Nissan-Logo.svg"  alt="Nissan" class="nissan_logo" />
                    </a>                   
                </div>
                <div class="mr-auto nissan-c-logo">
                    <a  href="javascript:void(0)" (click)="navigateToHomePage()">
                        <img src="assets/images/nissan_connect_logo.svg" class="d-block" alt="Nissan Connect" />
                    </a>            
                </div>
                <div class="flex-auto error-logout-div">
                    
                     <div class="logout_wrpper d-sm-inline-block ">
                        <a href="javascript:void(0)" title="Logout" (click)="logout()" *ngIf="showLogout">
                            <img class="logout_icon_img" src="assets/images/sign-out-alt.svg" alt="sign_out" />
                        </a>
                     </div>
                </div>
             </div>
        </div>
    </div>
</header>
/* tslint:disable */
import { Action } from '@ngrx/store';

export const SAVE_VEHICLE_INFO = '[ADMISSION] SAVE VEHICLE INFORMATION';
export const FETCH_VEHICLE_INFO = '[ADMISSION] FETCH VEHICLE INFORMATION';

export const SAVE_REASON_TYPES_PAPER = '[ADMISSION] SAVE REASON TYPES PAPER';
export const FETCH_REASON_TYPES_PAPER = '[ADMISSION] FETCH REASON TYPES PAPER';

export const SAVE_REASON_TYPES_NO_THANKS = '[ADMISSION] SAVE REASON TYPES NO THANKS';
export const FETCH_REASON_TYPES_NO_THANKS = '[ADMISSION] FETCH REASON TYPES NO THANKS';

export const SAVE_PAYMENT_TYPES = '[ADMISSION] SAVE PAYMENT TYPES';
export const FETCH_PAYMENT_TYPES = '[ADMISSION] FETCH PAYMENT TYPES';

export const SAVE_DEALER_INFO = '[ADMISSION] SAVE DEALER INFORMATION';
export const FETCH_DEALER_INFO = '[ADMISSION] FETCH DEALER INFORMATION';

export const SAVE_RECENT_ADMISSION_DETAILS = '[ADMISSION] SAVE RECENT ADMISSION DETAILS';
export const FETCH_RECENT_ADMISSION_DETAILS = '[ADMISSION] FETCH RECENT ADMISSION DETAILS';

export const SAVE_CAROUSEL_IMAGES = '[ADMISSION] SAVE_CAROUSEL_IMAGES';
export const FETCH_CAROUSEL_IMAGES = '[ADMISSION] FETCH_CAROUSEL_IMAGES';

export const SAVE_USEDCAR_VEHICLE_INFO = '[ADMISSION] SAVE_USEDCAR_VEHICLE_INFO';
export const FETCH_USEDCAR_VEHICLE_INFO = '[ADMISSION] FETCH_USEDCAR_VEHICLE_INFO';

export const SAVE_NEWCAR_VEHICLE_INFO = '[ADMISSION] SAVE_NEWCAR_VEHICLE_INFO';
export const FETCH_NEWCAR_VEHICLE_INFO = '[ADMISSION] FETCH_NEWCAR_VEHICLE_INFO';

export const SAVE_NCID_REMINDER_INFO = '[ADMISSION] SAVE_NCID_REMINDER_INFO';
export const FETCH_NCID_REMINDER_INFO = '[ADMISSION] FETCH_NCID_REMINDER_INFO';

export const SAVE_NAVIID_REMINDER_INFO = '[ADMISSION] SAVE_NAVIID_REMINDER_INFO';
export const FETCH_NAVIID_REMINDER_INFO = '[ADMISSION] FETCH_NAVIID_REMINDER_INFO';

export const SAVE_DEALER_MASTERDATA_INFO = '[ADMISSION] SAVE_DEALER_MASTERDATA_INFO';
export const FETCH_DEALER_MASTERDATA_INFO = '[ADMISSION] FETCH_DEALER_MASTERDATA_INFO';
export const SAVE_PACKAGE_PLAN_BIZ_CONNECT = '[ADMISSION] SAVE_PACKAGE_PLAN_BIZ_CONNECT';
export const FETCH_PACKAGE_PLAN_BIZ_CONNECT = '[ADMISSION] FETCH_PACKAGE_PLAN_BIZ_CONNECT';

export const SAVE_COLOR_PALLETTE_DATA = '[ADMISSION] SAVE_COLOR_PALLETTE_DATA';
export const FETCH_COLOR_PALLETTE_DATA = '[ADMISSION] FETCH_COLOR_PALLETTE_DATA';
export class SaveVehicleInformation implements Action {
    readonly type = SAVE_VEHICLE_INFO;
    // @ts-ignore
    constructor(private payload: []) {} 
}

export class FetchVehicleInformation implements Action {
    readonly type = SAVE_VEHICLE_INFO;
    constructor() {}
}

export class SaveReasonTypesPaper implements Action {
    readonly type = SAVE_REASON_TYPES_PAPER;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchReasonTypesPaper implements Action {
    readonly type = FETCH_REASON_TYPES_PAPER;
    constructor() {}
}

export class SaveReasonTypesNoThanks implements Action {
    readonly type = SAVE_REASON_TYPES_NO_THANKS;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchReasonTypesNoThanks implements Action {
    readonly type = FETCH_REASON_TYPES_NO_THANKS;
    constructor() {}
}

export class SavePaymentTypes implements Action {
    readonly type = SAVE_PAYMENT_TYPES;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchPaymentTypes implements Action {
    readonly type = FETCH_PAYMENT_TYPES;
    constructor() {}
}

export class SaveDealerInformation implements Action {
    readonly type = SAVE_DEALER_INFO;
    // @ts-ignore
    constructor(private payload) {}
}

export class FecthDealerInformation implements Action {
    readonly type = FETCH_DEALER_INFO;
    constructor() {}
}

export class SaveRecentAdmissionDetails implements Action {
    readonly type = SAVE_RECENT_ADMISSION_DETAILS;
    // @ts-ignore
    constructor(private payload) {}
}

export class FecthRecentAdmissionDetails implements Action {
    readonly type = FETCH_RECENT_ADMISSION_DETAILS;
    constructor() {}
}

export class SaveCarouselImages implements Action {
    readonly type = SAVE_CAROUSEL_IMAGES;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchCarouselImages implements Action {
    readonly type = FETCH_CAROUSEL_IMAGES;
    constructor() {}
}

/** Used Cars */

export class SaveUsedCarVehicleInfo implements Action {
    readonly type = SAVE_USEDCAR_VEHICLE_INFO;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchUsedCarVehicleInfo implements Action {
    readonly type = FETCH_USEDCAR_VEHICLE_INFO;
    constructor() {}
}

/**New Cars */

export class SaveNewCarVehicleInfo implements Action {
    readonly type = SAVE_NEWCAR_VEHICLE_INFO;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchNewCarVehicleInfo implements Action {
    readonly type = FETCH_NEWCAR_VEHICLE_INFO;
    constructor() {}
}

export class SaveNCIDReminderInfo implements Action {
    readonly type = SAVE_NCID_REMINDER_INFO;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchNCIDReminderInfo implements Action {
    readonly type = FETCH_NCID_REMINDER_INFO;
    constructor() {}
}

export class SaveNAVIIDReminderInfo implements Action {
    readonly type = SAVE_NAVIID_REMINDER_INFO;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchNAVIIDReminderInfo implements Action {
    readonly type = FETCH_NAVIID_REMINDER_INFO;
    constructor() {}
}

export class SaveDealerMasterDataInfo implements Action {
    readonly type = SAVE_DEALER_MASTERDATA_INFO;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchDealerMasterDataInfo implements Action {
    readonly type = FETCH_DEALER_MASTERDATA_INFO;
    constructor() {}
}


export class SaveBizConnectPackagePlan implements Action {
    readonly type = SAVE_PACKAGE_PLAN_BIZ_CONNECT;
    // @ts-ignore
    constructor(private payload) {}
}

export class SaveColorPaletteData implements Action {
    readonly type = SAVE_COLOR_PALLETTE_DATA;
    // @ts-ignore
    constructor(private payload) {}
}

export class FetchColorPaletteData implements Action {
    readonly type = FETCH_COLOR_PALLETTE_DATA;
    constructor() {}
}




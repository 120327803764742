import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-response',
  templateUrl: './modal-response.component.html',
  styleUrls: ['./modal-response.component.scss']
})
export class ModalResponseComponent {
  public status = false;
  public responseMsg = '';
  public modalRef;
  public headerTxt;
  public renewalResponseMsg1;
  public renewalResponseMsg2;
  public closeTxt;
  public saveSuccess: boolean;
  public isIssuance: boolean;
  public responseMsgIssuance;
  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

}

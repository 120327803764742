<div>
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
    <app-header></app-header>
    <router-outlet></router-outlet>
</div>

<!-- Cancel Form Popup -->
<ng-template #sessionExpired let-modal>
	<div class="clear_popup">
		<div class="clear_cnt">
            <span class="info">
                <svg class="bi bi-info-circle-fill" width="35px" height="35px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 16A8 8 0 108 0a8 8 0 000 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"/>
                </svg>
            </span>           
            {{ 'dealer.admission.admissionType.digital.infoLabels.cancelPopup' | translate }}
        </div>
		<div class="popup_btn">			
			<a href="javascript:;" class="primary_button" routerLink="" (click)="modal.dismiss()"> {{ 'dealer.admission.admissionType.digital.ok' | translate }} </a>
			<a href="javascript:;" class="button_link" (click)="modal.dismiss();"> {{ 'dealer.admission.admissionType.digital.cancel' | translate }} </a>
		</div>	
	</div>
</ng-template>


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomPreloadingStrategy } from '@core/services/preloading.service';
import { ChooseOptionResolver } from '@core/services/resolvers/choose-option.resolver';
import { CustomerResolver } from '@core/services/resolvers/customer.resolver';
import { DealerResolver } from '@core/services/resolvers/dealer.resolver';
import { GeneratePasswordResolver } from '@core/services/resolvers/generate-password.resolver';
import { UsedCarCustomerResolver } from '@core/services/resolvers/used-car-customer.resolver';
import { VerifyIdentityResolver } from '@core/services/resolvers/verify-identity.resolver';
import { BizEmailResolver } from '@core/services/resolvers/biz-email.resolver';

import { ErrorComponent } from '@shared/components/error/error.component';
import { CustomerLayoutComponent } from '@shared/layouts/customer-layout/customer-layout.component';
import { DealerLayoutComponent } from '@shared/layouts/dealer-layout/dealer-layout.component';
import { ErrorLayoutComponent } from '@shared/layouts/error-layout/error-layout.component';
import { NissanConnectLayoutComponent } from '@shared/layouts/nissan-connect-layout/nissan-connect-layout.component';
import { BizConnectEmailLayoutComponent } from '@shared/layouts/biz-connect-email-layout/biz-connect-email-layout.component';

import { LoginComponent } from '@dealer/login/login.component';
import { EvFleetResolver } from '@core/services/resolvers/ev-fleet.resolver';
import { EvFleetLayoutComponent } from '@shared/layouts/ev-fleet-layout/ev-fleet-layout.component';
import { RenewalUrlErrorComponent } from '@shared/components/renewal-url-error/renewal-url-error.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dealer',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'error',
    component: ErrorLayoutComponent,
    children: [
      {
        path: '404',
        data : {status : '404'},
        component: ErrorComponent,
      },
      {
        path: '500',
        data : {status : '500'},
        component: ErrorComponent,
      },
      {
        path: 'expired',
        data : {status : 'expired'},
        component: RenewalUrlErrorComponent,
      },
      {
        path: 'page-not-found',
        data : {status : 'notFound'},
        component: RenewalUrlErrorComponent
      }
    ]
  },
  {
    path: 'dealer',
    component: DealerLayoutComponent,
    resolve: { message: DealerResolver },
    children: [
      {
        path: '',
        loadChildren: () => import(`./dealer/dealer.module`).then(m => m.DealerModule),
      },
      {
        path: '**',
        redirectTo: '/error/404'
      }
    ]
  },
  {
    path: 'customer/admission',
    component: CustomerLayoutComponent,
    resolve: { message: UsedCarCustomerResolver },
    children: [
      {
        path: '',
        loadChildren: () => import(`./customer/used-car/used-car.module`).then(m => m.UsedCarModule),
      },
      {
        path: '**',
        redirectTo: '/error/404?redirectURL=customer/admission'
      }
    ]
  },
  {
    path: 'customer/adomission',
    component: CustomerLayoutComponent,
    resolve: { message: UsedCarCustomerResolver },
    children: [
      {
        path: '',
        loadChildren: () => import(`./customer/used-car/used-car.module`).then(m => m.UsedCarModule),
      },
      {
        path: '**',
        redirectTo: '/error/404?redirectURL=customer/admission'
      }
    ]
  },
  {
    path: 'customer/new-car',
    component: CustomerLayoutComponent,
    resolve: { message: CustomerResolver },
    children: [
      {
        path: '',
        loadChildren: () => import(`./customer/new-car/new-car.module`).then(m => m.NewCarModule),
      },
      {
        path: '**',
        redirectTo: '/error/404?redirectURL=customer/new-car'
      }
    ]
  },
  {
    path: 'customer/ev-fleet',
    component: EvFleetLayoutComponent,
    resolve: { message: CustomerResolver },
    children: [
      {
        path: '',
        loadChildren: () => import(`./customer/ev-fleet/ev-fleet.module`).then(m => m.EvFleetModule),
      },
      {
        path: '**',
        redirectTo: '/error/404?redirectURL=customer/ev-fleet'
      }
    ]
  },
  {
    path: 'customer',
    component: CustomerLayoutComponent,
    resolve: { message: CustomerResolver },
    children: [
      {
        path: '',
        loadChildren: () => import(`./customer/customer.module`).then(m => m.CustomerModule),
      },
      {
        path: '**',
        redirectTo: '/error/404?redirectURL=customer'
      }
    ]
  },
  {
    path: 'nissan-connect',
    component: NissanConnectLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import(`./nissan-connect/nissan-connect.module`).then(m => m.NissanConnectModule),
      },
      {
        path: '**',
        redirectTo: '/error/404?redirectURL=nissan-connect'
      }
    ]
  },
  {
    path: 'biz-connect-email',
    resolve: { message: BizEmailResolver },
    component: BizConnectEmailLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import(`./biz-connect-email/biz-connect-email.module`).then(m => m.BizConnectEmailModule),
      },
      {
        path: '**',
        redirectTo: '/error/404?redirectURL=nissan-connect'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      useHash: true,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy
    })],
  exports: [RouterModule],
  providers: [DealerResolver, CustomerResolver, VerifyIdentityResolver,
              GeneratePasswordResolver, ChooseOptionResolver, EvFleetResolver, UsedCarCustomerResolver,
              BizEmailResolver],
  declarations: [LoginComponent]
})
export class AppRoutingModule { }

import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[phoneNumber]'
})
export class PhoneNumberFormatterDirective implements OnInit {
  @Input() default10Format: string;
  @Input() default11Format: string;
  constructor(private el: ElementRef, private render: Renderer2) { }

  ngOnInit() {
      setTimeout(() => {
        const output = this.formatPhoneNumber(this.el.nativeElement.value);
        if (output) {
            this.render.setProperty(this.el.nativeElement, 'value', output);
        }
      }, 700);
  }

  @HostListener('blur', ['$event']) onInputChange() {
    try {
      const value = this.el.nativeElement.value.replace(/[^0-9]/g, '') || '';
      if (!isNaN(value) && value) {
          const output = this.formatPhoneNumber(value);
          this.render.setProperty(this.el.nativeElement, 'value', output);
      }
    } catch (e) {
      console.log(e);
    }
  }

  @HostListener('click', ['$event']) onInputMouseChange() {
    try {
      const cursorPosition = this.el.nativeElement.selectionStart;
      const subString = this.el.nativeElement.value.substring(0, this.el.nativeElement.selectionStart);
      const value = ((this.el.nativeElement.value).replace(/\D/g, ''));
      const commaCount = subString.split(',').length - 1;
      if (value) {
          this.render.setProperty(this.el.nativeElement, 'value', value.toString());
      }
      this.el.nativeElement.setSelectionRange(cursorPosition - commaCount, cursorPosition - commaCount, 'none');
    } catch (e) {
      console.log(e);
    }
  }

   formatPhoneNumber = (str) => {
    try {
      let match;
      const cleaned = ('' + str).replace(/\D/g, '');
      if (str && str.length == 10) {
        if (this.default10Format) {
          const formatDigits = this.default10Format.split(',');
          const regex = `^(\\d{${formatDigits[0]}})(\\d{${formatDigits[1]}})(\\d{${formatDigits[2]}})$`;
          const re = new RegExp(regex);
          match = cleaned.match(re);
        } else {
          match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        }
      } else if (str && str.length == 11) {
        if (this.default11Format) {
          const formatDigits = this.default11Format.split(',');
          const regex = `^(\\d{${formatDigits[0]}})(\\d{${formatDigits[1]}})(\\d{${formatDigits[2]}})$`;
          const re = new RegExp(regex);
          match = cleaned.match(re);
        } else {
          match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
        }
      }
      if (match) {
        return match[1] + '-' + match[2] + '-' + match[3];
      }
      return cleaned;
    } catch (e) {
      console.log(e);
    }
  }
}


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalValidator } from '@shared/validations/validators';
import { ToastService } from '@shared/components/toaster/service/toaster.service';

@Component({
  selector: 'app-vehicle-procurement',
  templateUrl: './vehicle-procurement.component.html',
  styleUrls: ['./vehicle-procurement.component.scss']
})
export class VehicleProcurementComponent implements OnInit {

  public VehicleProcurementForm: FormGroup;
  
  constructor(private formBuilder: FormBuilder,
              private toastService: ToastService) { }

  ngOnInit(): void {
    this.buildVehicleProcurementForm();
  }

  /**
 * @method buildVehicleProcurementForm
 * @description Initialize the form with necessary form validations.
 */

  buildVehicleProcurementForm() {
    try {
      this.VehicleProcurementForm = this.formBuilder.group({
        vehicleProcurementDetails: this.formBuilder.group({
          companyName: [null, [GlobalValidator.nonEmpty, Validators.maxLength(100)]],
          DealershipName: [null, [GlobalValidator.nonEmpty, Validators.maxLength(100)]],
          CAName: [null, [GlobalValidator.nonEmpty, Validators.maxLength(100)]],
          CANameKana: [null, [GlobalValidator.nonEmpty, Validators.maxLength(100)]],
          EmailAddress: [null, [GlobalValidator.nonEmpty, Validators.maxLength(100)]],
          ConfirmEmailAddress: [null, [Validators.required, Validators.email]],
          DealerPhone: [null, [GlobalValidator.nonEmpty, Validators.maxLength(11)]]
        })
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

}

<header class="header_wrp ev-fleet-header" >
    <div  class="header-container-wrap">
        <div class="container">
            <div class="d-flex main_nav align-items-center flex-wrap">
                <div class="flex-auto nissan-n-logo">
                    <a href="javascript:void(0);" >
                        <img src="assets/images/Nissan-r-logo.svg" alt="Nissan" class="nissan_logo" />
                    </a>                   
                </div>
                <div class="mr-auto nissan-c-logo">
                    <a  href="javascript:void(0)">
                        <img src="assets/images/nissan_connect_logo.svg" class="d-block" alt="Nissan Connect"  />
                    </a>            
                </div>
                <div  class="ml-auto d-inline-flex header-dropdown-grp">
                    <div class="lang_drop_down" >
                        <select (change)="switchLang($event.target.value)">
                            <option value="en" [selected]="isENG">ENGLISH</option>
                            <option value="jp" [selected]="!isENG">日本語</option>
                        </select>
                    </div>
                   
                </div>
             </div>
        </div>
    </div>
    <ng-container *ngIf="!oldUrl">
        <app-breadcrumb-evfleet></app-breadcrumb-evfleet>
    </ng-container>
</header>
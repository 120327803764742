import { Component, Input, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [NgbCarouselConfig]
})

export class CarouselComponent implements OnInit {

  private defaultImage  = { name: 'Carousal Default', url: '/assets/images/PC1A.png' };
  @Input() images: any;

  constructor( config: NgbCarouselConfig) {
    config.interval = 4000;
   }

  ngOnInit(): void {
    if (this.images.length === 0) {
      this.images = [this.defaultImage];
    }
  }

}

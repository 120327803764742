import { Injectable } from '@angular/core';
import * as SockJs from 'sockjs-client';
import * as Stomp from 'stompjs';


@Injectable()
export class WebSocketService {
    // Open connection with the back-end socket
    public connect() {
        const socket = new SockJs(`https://kr13apodci.execute-api.ap-northeast-1.amazonaws.com/Dev/@connections`);
        const stompClient = Stomp.over(socket);
        return stompClient;
    }
}

import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoaderService } from '@shared/components/loader/service/loader.service';
import { SaveDealerInformation, SavePaymentTypes, SaveReasonTypesNoThanks,
         SaveReasonTypesPaper, SaveVehicleInformation, SaveNCIDReminderInfo, SaveNAVIIDReminderInfo, SaveDealerMasterDataInfo, SaveBizConnectPackagePlan } from 'app/store/dealer/actions/dealer.actions';
import { DealerState } from 'app/store/dealer/state/dealer.state';
import { DealerResolver } from 'app/core/services/resolvers/dealer.resolver';

@Component({
  selector: 'app-dealer-layout',
  templateUrl: './dealer-layout.component.html',
  styleUrls: ['./dealer-layout.component.scss']
})
export class DealerLayoutComponent implements OnInit, OnDestroy {
  constructor(private store: Store<DealerState>, private renderer: Renderer2, private route: ActivatedRoute,
              private loaderService: LoaderService,
              private dealerResolver: DealerResolver) { }

   ngOnInit() {
     try {
        this.renderer.addClass(document.body, 'dealer_body');
        const resolverData = this.route.snapshot.data;
        const data = resolverData.message[0].data;
        const ncidReminderSettingsData = resolverData.message[1];
        const naviidReminderSettingsData = resolverData.message[2];
        const companyAndDealerMaster = resolverData.message[3];
        const bizPackagePlan=resolverData.message[4]
        if (data) {
          this.store.dispatch(new SaveVehicleInformation(data.models));
          this.store.dispatch(new SaveReasonTypesPaper(data.reasonTypesPaper));
          this.store.dispatch(new SaveReasonTypesNoThanks(data.reasonTypesNoThanks));
          this.store.dispatch(new SavePaymentTypes(data.paymentTypes));
          data.dealerDetails.default10Format = '';
          data.dealerDetails.default11Format = '';
          if (data.dealerDetails && data.dealerDetails.phoneNumber) {
            let phoneSplit = data.dealerDetails.phoneNumber.split('-');
            phoneSplit = phoneSplit.map((val) => {
              return val.length;
            });

            data.dealerDetails.phoneNumber = (data.dealerDetails.phoneNumber).replace(/[^0-9]/g, '');

            if (data.dealerDetails.phoneNumber.length == 10) {
              data.dealerDetails.default10Format = phoneSplit;
            } else if (data.dealerDetails.phoneNumber.length == 11) {
              data.dealerDetails.default11Format = phoneSplit;
            }
          }
          data.dealerDetails.profitVersion = 'P5';
          this.store.dispatch(new SaveDealerInformation(data.dealerDetails));
          this.loaderService.removeloaderBody();
        }
        if (ncidReminderSettingsData) {
          this.store.dispatch(new SaveNCIDReminderInfo(ncidReminderSettingsData));
        }

        if (naviidReminderSettingsData) {
          this.store.dispatch(new SaveNAVIIDReminderInfo(naviidReminderSettingsData));
        }
        if (companyAndDealerMaster) {
          this.store.dispatch(new SaveDealerMasterDataInfo(companyAndDealerMaster));
        }
        if (bizPackagePlan) {
          this.store.dispatch(new SaveBizConnectPackagePlan(bizPackagePlan));
        }

     } catch (error) {
      console.error(error);
     }
     this.dealerResolver.unsubscribe();
  }

  ngOnDestroy() {
    this.dealerResolver.unsubscribe();
    this.renderer.removeClass(document.body, 'dealer_body');
  }

  compare( a, b ) {
    if ( a.displayName < b.displayName ){
      return -1;
    }
    if ( a.displayName > b.displayName ){
      return 1;
    }
    return 0;
  }

}
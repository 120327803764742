import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoaderService } from '@shared/components/loader/service/loader.service';
import { DealerState } from 'app/store/dealer/state/dealer.state';
import { forkJoin, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { SecuredService } from '../generic/secured.service';

@Injectable()
export class EvFleetResolver implements Resolve<Observable<string>> {

  constructor(private securedService: SecuredService, private router: Router,
              private loaderService: LoaderService, private store: Store<DealerState>) {}

  resolve() {
    try {
        const token = localStorage.getItem('d_token');
        const currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
        if ( token ) {
          const imageCarouselObservable$ = this.securedService.get(`${currentLang}/imageCarousel`, '', 'admission');
          const observableArray$ = [];
          if (!this.hasCarouselImages(this.store)) {
            observableArray$.push(imageCarouselObservable$);
          }
          return forkJoin(observableArray$);
        } else {
            this.router.navigate(['error/500'], { queryParams: { redirectURL: 'dealer' } });
            return of(null);
        }
    } catch (error) {
      this.loaderService.removeloaderBody();
      return of(null);
    }
  }

  hasCarouselImages(store: Store<DealerState>): boolean {
    let state: DealerState;
    store.pipe(take(1)).subscribe(s => state = s).unsubscribe();
    return state.carouselImages.length > 0;
  }
}

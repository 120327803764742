import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoaderService } from '@shared/components/loader/service/loader.service';
import { UtilService } from '@shared/utils/utils.service';
import { SaveUsedCarCustomerInformation, UpdateUsedCarStepCount } from 'app/store/customer-used-car/actions/customer-used-car.actions';
import { UsedCarCustomerState } from 'app/store/customer-used-car/state/customer-used-car.state';
import { SaveCustomerInformation, UpdateStepCount } from 'app/store/customer/actions/customer.actions';
import { CustomerState } from 'app/store/customer/state/customer.state';

@Component({
  selector: 'app-customer-layout',
  templateUrl: './customer-layout.component.html',
  styleUrls: ['./customer-layout.component.scss']
})
export class CustomerLayoutComponent implements OnInit {
  // @ts-ignore
  constructor(private store: Store<CustomerState>, private usedCarstore: Store<UsedCarCustomerState>,
              private route: ActivatedRoute, private loaderService: LoaderService,
              private utilService: UtilService, private router: Router) { }

  ngOnInit() {
    try {
      this.loaderService.removeloaderBody();
      const resolverData = this.route.snapshot.data;
      const routeData = resolverData.message ? resolverData.message.data : {};
      if (this.router.url.indexOf('customer/admission') > -1) {
        this.usedCarstore.dispatch(new SaveUsedCarCustomerInformation(routeData));
        this.usedCarstore.dispatch(new UpdateUsedCarStepCount({stepCount: routeData.stepCount}));
        if (!(routeData.stepCount == 0 && routeData.source == 'home')) {
          this.utilService.checkifUsedCarCustomerPageAccessibile(routeData.stepCount, routeData.source);
        }
      } else {
        this.store.select('customerInfo').subscribe((response) => {
          if (Object.keys(response).length === 0) {
            this.store.dispatch(new SaveCustomerInformation(routeData));
            this.store.dispatch(new UpdateStepCount({stepCount: routeData.stepCount}));
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

}

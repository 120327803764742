import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http.service';





/**
 * Name: SecuredService
 * params: HttpClient
 * basepath: nissan/secured/api/
 * desc: Secured services should be accessing this service
 */

@Injectable({
  providedIn: 'root'
})
export class SecuredService extends BaseHttpService {
  // @ts-ignore
  constructor(private http: HttpClient) {
    super(http, 'secured/api/v1');
  }
}

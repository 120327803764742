<ng-container
  *ngIf="currentLang == 'en'; then printTemplateEn; else printTemplateEnJp">
</ng-container>
<ng-template #printTemplateEn>
    <section class="popup_title_grey mb-0">
        <a href="javascript:void(0)" class="close_icon" (click)="summarPopupClose()">
            <svg class="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clip-rule="evenodd" />
            </svg>
        </a>
    </section>
    <app-loader *ngIf="showLoader"></app-loader>
    <section class="admission_form_wrp relative pb-0">

        <div class="container">
            <div class="row justify-content-center ">
                <div class="col-12 col-sm-12 p-4">
                    <div class="summary_popup">
                        <div class="summary_cnt pt-0 print-summary" id="printSection">
                            <div class="row">
                                <div class="col-xl-12 col-md-12 col-sm-12 notification mb-4">
                                    <div class="notification_menu_wrp message">
                                        <p>We have sent an instruction email on how to enter the information to the customer.
                                            <br/> By following the instructions application will be complete.
                                        </p>
                                        <ul>
                                            <li >The URL in the email is valid for 72 hours.<br/>
                                                (After the expiration date, you will need to resend the email.)</li>
                                            <li >You can also proceed to the customer information input screen from the URL at the bottom of the screen.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h3 class="pb-1 pl-3"> NCAS# </h3>
                                <div class="col-12 col-sm-6 pb-2">
                                    <label class="data no-sub-head"> {{ summaryData?.ncasNumber }}</label>
                                </div>
                            </div>
                            <hr class="border_line" />
                            <div class="row">
                                <h3 class="pb-1 pl-3"> Lease Contract No.</h3>
                                <div class="col-12 col-sm-6 pb-2">
                                    <label class="data no-sub-head"> {{ summaryData?.leaseContractNumber }}</label>
                                </div>
                            </div>
                            <hr class="border_line" />
                            <div class="section-customer">
                                <h3 class="pb-3"> Customer Information</h3>
                                <div class="row minus-margin">
                                    <div class="col-12 col-sm-6 pb-2" width="50%">
                                        <label class="label_custom"> Corporate Name </label>
                                        <label class="data"> {{ summaryData?.corporateName }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2" width="50%">
                                        <label class="label_custom"> Corporate name (Kana)</label>
                                        <label class="data"> {{ summaryData?.corporateNameKana
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> Office Position </label>
                                        <label class="data"> {{ summaryData?.officePosition }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2 d-none d-sm-block">

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> Representatives Name</label>
                                        <label class="data"> {{ summaryData?.representativesName }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom">  Representatives Name (Kana) </label>
                                        <label class="data"> {{ summaryData?.representativesNameKana }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> Email Address </label>
                                        <label class="data email"> {{ summaryData?.customerEmail }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2 d-none d-sm-block">

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom phone"
                                            [ngClass]="currentLang === 'en'? 'en-phone' : ''"> Customer Phone number
                                            <br />
                                            (Person Incharge Contact number)
                                        </label>
                                        <label class="data"> {{ summaryData?.customerPhone }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom phone"
                                            [ngClass]="currentLang === 'en'? 'en-phone' : ''"> Representatives Contact number</label>
                                        <label class="data"> {{ summaryData?.representativesPhoneNumber
                                            }}</label>
                                    </div>
                                </div>
                            </div>
                            <hr class="border_line" />
                            <div class="section-procurement">
                                <h3 class="pb-3"> Lease Contract Creator Information </h3>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> {{ summaryData?.contactPersonCategory == 'CA' ||
                                            summaryData?.contactPersonCategory == 'IB4' ||
                                            summaryData?.contactPersonCategory == 'JF0' ?
                                            'Dealer Company Name' :
                                            'Company Name' }}
                                        </label>
                                        <label class="data"> {{ summaryData?.dealerCompanyName }} </label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2"
                                        *ngIf="summaryData?.contactPersonCategory == 'CA' || summaryData?.contactPersonCategory == 'IB4' || summaryData?.contactPersonCategory == 'JF0'">
                                        <label class="label_custom"> Dealership Name</label>
                                        <label class="data"> {{ summaryData?.dealerShipName }} </label>
                                    </div>
                                    <ng-container
                                        *ngIf="summaryData?.contactPersonCategory == 'LC' || summaryData?.contactPersonCategory == 'NFS';then phoneNumerTemplate"></ng-container>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> {{ summaryData?.contactPersonCategory == 'CA' ||
                                            summaryData?.contactPersonCategory == 'IB4' ||
                                            summaryData?.contactPersonCategory == 'JF0' ?
                                            'C/A Name' :
                                            'Executive Name' }}
                                        </label>
                                        <label class="data"> {{ summaryData?.caName }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> {{ summaryData?.contactPersonCategory == 'CA' ||
                                            summaryData?.contactPersonCategory == 'IB4' ||
                                            summaryData?.contactPersonCategory == 'JF0' ?
                                            'C/A Name (Kana)' :
                                            'Executive Name (Kana)' }}</label>
                                        <label class="data"> {{ summaryData?.caNameKana
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> Email Address </label>
                                        <label class="data"> {{ summaryData?.dealerEmail }}</label>
                                    </div>
                                    <ng-container
                                        *ngIf="summaryData?.contactPersonCategory != 'LC' && summaryData?.contactPersonCategory != 'NFS';then phoneNumerTemplate"></ng-container>
                                </div>
                                <ng-template #phoneNumerTemplate>
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom">{{ summaryData?.contactPersonCategory == 'NFS' ?
                                            'NFS Phone #' :
                                            summaryData?.contactPersonCategory == 'LC' ?
                                            'Leasing Company  Phone#' :
                                            'Dealer Phone #' }} </label>
                                        <label class="data"> {{ summaryData?.dealerPhone }}</label>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-12 d-flex">
                                <p class="m-auto">
                                    <a href="{{qrString }}" target="_blank">{{ qrString }}</a>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 d-flex pb-2">
                            <div class="m-auto">
                                <span class="print_btn hover_red" id="print-details"
                                    [ngClass]="isPrintPdf ? 'disabled' : 'hover_red'" (click)="print()">
                                    <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                        viewBox="0 0 26 26">
                                        <defs>
                                            <style>
                                                .a {
                                                    fill: #6f84ac;
                                                }
                                            </style>
                                        </defs>
                                        <path class="a"
                                            d="M8.5,12.4a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,8.5,12.4ZM24.1,7.2H22.8V3.3A1.3,1.3,0,0,0,21.5,2H8.5A1.3,1.3,0,0,0,7.2,3.3V7.2H5.9A3.9,3.9,0,0,0,2,11.1v7.8a3.9,3.9,0,0,0,3.9,3.9H7.2v3.9A1.3,1.3,0,0,0,8.5,28h13a1.3,1.3,0,0,0,1.3-1.3V22.8h1.3A3.9,3.9,0,0,0,28,18.9V11.1A3.9,3.9,0,0,0,24.1,7.2ZM9.8,4.6H20.2V7.2H9.8ZM20.2,25.4H9.8V20.2H20.2Zm5.2-6.5a1.3,1.3,0,0,1-1.3,1.3H22.8V18.9a1.3,1.3,0,0,0-1.3-1.3H8.5a1.3,1.3,0,0,0-1.3,1.3v1.3H5.9a1.3,1.3,0,0,1-1.3-1.3V11.1A1.3,1.3,0,0,1,5.9,9.8H24.1a1.3,1.3,0,0,1,1.3,1.3Z"
                                            transform="translate(-2 -2)" />
                                    </svg>
                                    {{ 'dealer.admission.admissionType.summary.print' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>
<ng-template #printTemplateEnJp>
    <section class="popup_title_grey mb-0">
        <a href="javascript:void(0)" class="close_icon" (click)="summarPopupClose()">
            <svg class="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clip-rule="evenodd" />
            </svg>
        </a>
    </section>
    <app-loader *ngIf="showLoader"></app-loader>
    <section class="admission_form_wrp relative pb-0">

        <div class="container">
            <div class="row justify-content-center ">
                <div class="col-12 col-sm-12 p-4">
                    <div class="summary_popup">
                        <div class="summary_cnt pt-0 print-summary" id="printSection">
                            <div class="row">
                                <div class="col-xl-12 col-md-12 col-sm-12 notification mb-4">
                                    <div class="notification_menu_wrp message">
                                        <p>お客さま宛に入力案内メールを送付しました。<br/> 案内に従い手続きを進めることでお申込完了となります。</p>
                                        <ul>
                                            <li>メール内のURLは有効期限72時間です<br/>（期限を過ぎるとメールの再送手順が必要となります）</li>
                                            <li>画面下部のURLからもお客さま情報の入力画面に進むことが可能です</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <h3 class="pb-1 pl-3"> 入会番号  </h3>
                                <div class="col-12 col-sm-6 pb-2">
                                    <label class="data no-sub-head"> {{ summaryData?.ncasNumber }}</label>
                                </div>
                            </div>
                            <hr class="border_line" />
                            <div class="row">
                                <h3 class="pb-1 pl-3"> リース契約番号</h3>
                                <div class="col-12 col-sm-6 pb-2">
                                    <label class="data no-sub-head"> {{ summaryData?.leaseContractNumber }}</label>
                                </div>
                            </div>
                            <hr class="border_line" />
                            <div class="section-customer">
                                <h3 class="pb-3"> お客さま情報</h3>
                                <div class="row minus-margin">
                                    <div class="col-12 col-sm-6 pb-2" width="50%">
                                        <label class="label_custom">会社名 </label>
                                        <label class="data"> {{ summaryData?.corporateName }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2" width="50%">
                                        <label class="label_custom"> 会社名（カナ）</label>
                                        <label class="data"> {{ summaryData?.corporateNameKana
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> 部署名 </label>
                                        <label class="data"> {{ summaryData?.officePosition }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2 d-none d-sm-block">

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> お客さま氏名</label>
                                        <label class="data"> {{ summaryData?.representativesName }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom">お客さま氏名（カナ）</label>
                                        <label class="data"> {{ summaryData?.representativesNameKana }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> メールアドレス</label>
                                        <label class="data email"> {{ summaryData?.customerEmail }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2 d-none d-sm-block">

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom phone"
                                            [ngClass]="currentLang === 'en'? 'en-phone' : ''"> お客さま電話番号(担当者連絡先)
                                            <br />
                                        </label>
                                        <label class="data"> {{ summaryData?.customerPhone }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom phone"
                                            [ngClass]="currentLang === 'en'? 'en-phone' : ''">  代表連絡先
                                        </label>
                                        <label class="data"> {{ summaryData?.representativesPhoneNumber
                                            }}</label>
                                    </div>
                                </div>
                            </div>
                            <hr class="border_line" />
                            <div class="section-procurement">
                                <h3 class="pb-3"> リース契約窓口情報 </h3>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> {{ summaryData?.contactPersonCategory == 'CA' ||
                                            summaryData?.contactPersonCategory == 'IB4' ||
                                            summaryData?.contactPersonCategory == 'JF0' ?
                                            '販売会社名' :
                                            '会社名' }}
                                        </label>
                                        <label class="data"> {{ summaryData?.dealerCompanyName }} </label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2"
                                        *ngIf="summaryData?.contactPersonCategory == 'CA' || summaryData?.contactPersonCategory == 'IB4' || summaryData?.contactPersonCategory == 'JF0'">
                                        <label class="label_custom"> 店舗名</label>
                                        <label class="data"> {{ summaryData?.dealerShipName }} </label>
                                    </div>
                                    <ng-container
                                        *ngIf="summaryData?.contactPersonCategory == 'LC' || summaryData?.contactPersonCategory == 'NFS';then phoneNumerTemplate"></ng-container>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> {{ summaryData?.contactPersonCategory == 'CA' ||
                                            summaryData?.contactPersonCategory == 'IB4' ||
                                            summaryData?.contactPersonCategory == 'JF0' ?
                                            '担当者名':
                                            '担当者名' }}
                                        </label>
                                        <label class="data"> {{ summaryData?.caName }}</label>
                                    </div>
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom"> {{ summaryData?.contactPersonCategory == 'CA' ||
                                            summaryData?.contactPersonCategory == 'IB4' ||
                                            summaryData?.contactPersonCategory == 'JF0' ?
                                            '担当者名（かな）' :
                                            '担当者名（かな）' }}</label>
                                        <label class="data"> {{ summaryData?.caNameKana
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom">  メールアドレス  </label>
                                        <label class="data"> {{ summaryData?.dealerEmail }}</label>
                                    </div>
                                    <ng-container
                                        *ngIf="summaryData?.contactPersonCategory != 'LC' && summaryData?.contactPersonCategory != 'NFS';then phoneNumerTemplate"></ng-container>
                                </div>
                                <ng-template #phoneNumerTemplate>
                                    <div class="col-12 col-sm-6 pb-2">
                                        <label class="label_custom">{{ summaryData?.contactPersonCategory == 'NFS' ?
                                            '電話番号' :
                                            summaryData?.contactPersonCategory == 'LC' ?
                                            '電話番号' :
                                            '電話番号'  }} </label>
                                        <label class="data"> {{ summaryData?.dealerPhone }}</label>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-12 d-flex">
                                <p class="m-auto">
                                    <a href="{{qrString }}" target="_blank">{{ qrString }}</a>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 d-flex pb-2">
                            <div class="m-auto">
                                <span class="print_btn hover_red" id="print-details"
                                    [ngClass]="isPrintPdf ? 'disabled' : 'hover_red'" (click)="print()">
                                    <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                        viewBox="0 0 26 26">
                                        <defs>
                                            <style>
                                                .a {
                                                    fill: #6f84ac;
                                                }
                                            </style>
                                        </defs>
                                        <path class="a"
                                            d="M8.5,12.4a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,8.5,12.4ZM24.1,7.2H22.8V3.3A1.3,1.3,0,0,0,21.5,2H8.5A1.3,1.3,0,0,0,7.2,3.3V7.2H5.9A3.9,3.9,0,0,0,2,11.1v7.8a3.9,3.9,0,0,0,3.9,3.9H7.2v3.9A1.3,1.3,0,0,0,8.5,28h13a1.3,1.3,0,0,0,1.3-1.3V22.8h1.3A3.9,3.9,0,0,0,28,18.9V11.1A3.9,3.9,0,0,0,24.1,7.2ZM9.8,4.6H20.2V7.2H9.8ZM20.2,25.4H9.8V20.2H20.2Zm5.2-6.5a1.3,1.3,0,0,1-1.3,1.3H22.8V18.9a1.3,1.3,0,0,0-1.3-1.3H8.5a1.3,1.3,0,0,0-1.3,1.3v1.3H5.9a1.3,1.3,0,0,1-1.3-1.3V11.1A1.3,1.3,0,0,1,5.9,9.8H24.1a1.3,1.3,0,0,1,1.3,1.3Z"
                                            transform="translate(-2 -2)" />
                                    </svg>
                                    {{ 'dealer.admission.admissionType.summary.print' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-common-checksheet',
  templateUrl: './checksheet.component.html',
  styleUrls: ['./checksheet.component.scss']
})
export class ChecksheetComponent implements OnInit {
  @Input() checkSheetObj: any;
  @Input() formReset: boolean;
  @Output() selectCheckBox = new EventEmitter();
  @Output() validFormCheck = new EventEmitter();
  formCheckSheet: FormGroup;
  public checkboxesChecked: boolean = false;
  public validForm: boolean = false;
  public currentLang = localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp';

  constructor(
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.buildCheckSheetForm();
    this.processChecksheetContent(this.checkSheetObj)
    setTimeout(() => {
      this.onCheckBoxSelection();
    })
  }

  ngOnChanges() {
    if (this.formReset) {
      this.clearAll();
    }
  }

  /**
* @method buildCheckSheetForm
* @description function to intiate form
*/
  buildCheckSheetForm() {
    this.formCheckSheet = this.formBuilder.group({});
  }

  /**
* @method processChecksheetContent
* @description function to itertae through checsheet contet to set form
*/
  processChecksheetContent(response: any) {
    this.checkSheetObj = response.checkSheetObj;
    this.checkSheetObj.checkSheets.sort((a, b) => a.displayOrder - b.displayOrder);
    for (let i = 0; i <= this.checkSheetObj.checkSheets.length; i++) {
      if (response.checkSheetChecked && response.checkSheetChecked === true) {
        this.formCheckSheet.addControl(i.toString(), new FormControl(true, Validators.required))
      } else {
        this.formCheckSheet.addControl(i.toString(), new FormControl(false, Validators.required))
      }
    }

  }

  /**
 * @method clearAll
 * @description function to reset checkboxes
 */
  clearAll() {
    this.formCheckSheet.reset();
  }

  /**
 * @method onCheckBoxSelection
 * @description function to enable diable check based on checkbox check
 */
  onCheckBoxSelection() {
    let checkboxesCount = this.checkSheetObj.checkSheets.length;
    let checkedboxesCount = 0;
    for (let i = 0; i < this.checkSheetObj.checkSheets.length; i++) {
      if (this.formCheckSheet.get(i.toString()).value === true) {
        checkedboxesCount = checkedboxesCount + 1;
      }
    }
    if (checkboxesCount !== checkedboxesCount) {
      this.validForm = false;
    } else {
      this.validForm = true;
    }
    this.selectCheckBox.emit(this.validForm);
  }
}

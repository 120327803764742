import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '@core/services/generic/general.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastService } from '@shared/components/toaster/service/toaster.service';
import { GlobalValidator } from '@shared/validations/validators';
import { SaveUsedCarVehicleInfo } from 'app/store/dealer/actions/dealer.actions';
import { DealerState } from 'app/store/dealer/state/dealer.state';

@Component({
  selector: 'app-vin-checking-progress-bar',
  templateUrl: './vin-checking-progress-bar.component.html',
  styleUrls: ['./vin-checking-progress-bar.component.scss']
})
export class VinCheckingProgressBarComponent implements OnInit, OnChanges {
  @Input() uploadResponse: any;
  @Input() modal: any;
  @Input() redirectURL: any;
  @Input() mode: any;
  @Output() vinSearchCompleted = new EventEmitter();
  @ViewChild('dopPopup', {static : true}) dopPopupElementView: ElementRef;
  @ViewChild('showNaviMissmatchError', {static : true}) showNaviMissmatchErrorView: ElementRef;
  @ViewChild('showConfirmationMethodHelpDesc', {static : true}) showConfirmationMethodHelpDesc: ElementRef;
  @ViewChild('showVINDuplicateError', {static : true}) showVINDuplicateErrorView: ElementRef;

  public formNaviIdSearch: FormGroup;
  public currentLang: string;
  public formSubmitted;
  public skipNaviId;
  errorMsg: { error: { message: any; }; };
  data: any;
  constructor(private router: Router, private modalService: NgbModal,
              private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder,
              private store: Store<DealerState>, private generalService: GeneralService,
              private toastService: ToastService){}

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    try {
      if (changes.uploadResponse && this.uploadResponse.message >= 100 && this.uploadResponse.status !== '404') {
        const data = this.uploadResponse.data;
        if ( data && data.responseCode === '204') {
          this.buildNaviIdSearchForm();
          const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
          this.modalService.open(this.dopPopupElementView, initialState);
        } else if (this.checkResponseCode(data)) {
          const err = {
            error: {
              message: data.errorMessage
            }
          };
          this.vinSearchCompleted.emit((this.mode) ? true : false);
          setTimeout(() => {
            this.toastService.showModalResponse(err);
          });
        } else if (data && ( data.responseCode === '207')) {
          this.errorMsg = data.errorMessage;
          setTimeout(() => {
            const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
            this.modalService.open(this.showNaviMissmatchErrorView, initialState);
          });
        } else if (data && ( data.responseCode === '208')) {
          this.handleVINDuplicationError(data);
        } else {
          this.navigateURl();
        }
        this.cdRef.detectChanges();
      }
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }
  checkResponseCode(data): boolean {
    if (data && (data.responseCode === '205' || data.responseCode === '206'))
      return true
    else
      return false
  }

  ngOnInit(): void {
    if (this.mode) {
      this.currentLang = localStorage.getItem('cLang') ? localStorage.getItem('cLang') : 'jp';
    } else {
      this.currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
    }
  }

  buildNaviIdSearchForm() {
    try {
      this.formNaviIdSearch = this.formBuilder.group({
        naviId: [null, [ GlobalValidator.nonEmpty, Validators.maxLength(10),Validators.minLength(10),GlobalValidator.validNaviId]],
        skipNaviId: [false]
      });
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  skipNaviIdClick() {
    if (!this.controls.skipNaviId.value) {
      this.controls.naviId.setValue(null);
      this.controls.naviId.setValidators([]);
      this.controls.naviId.updateValueAndValidity();
    } else {
      this.controls.naviId.setValidators([GlobalValidator.nonEmpty, Validators.maxLength(10),Validators.minLength(10),GlobalValidator.validNaviId]);
      this.controls.naviId.updateValueAndValidity();
    }
  }

  /**
   * @method controls
   * @description Returns form controls.
   */

  get controls() {
    return this.formNaviIdSearch.controls;
  }

  dismiss() {
    try {
      this.vinSearchCompleted.emit((this.mode) ? true : false);
      this.modal.dismiss();
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  fetchVehicleInfoWithNaviId() {
    try {
      if (this.formNaviIdSearch.valid) {
        let vehicleInfo;
        let formValues = this.formDataDeclaration(vehicleInfo);
        let withdrawOrderValue = this.withdrawOrderValue();
        let modeCheck = (this.mode) ? true : false;
        const formData = formValues.formData;
        this.formSubmitted = true;
        this.generalService.post(formData, this.currentLang + '/vehicleinfo', {}, 'carwings').subscribe((res) => {
          this.formSubmitted = false;
          res.data.vin = formValues.vehicleInfo.vinNumber;
          res.data.vinNumber = formValues.vehicleInfo.vinNumber;
          res.data.vehicleType = formValues.vehicleInfo.vehicleType;
          res.data.preSelectNaviType = res.data.dopFlg;
          res.data.withdrawOrder = withdrawOrderValue ;
          this.data = res.data;
          if (res && res.data && (res.data.responseCode === '205' || res.data.responseCode === '206')) {
            const err = {
              error: {
                message: res.data.errorMessage
              }
            };
            this.vinSearchCompleted.emit(modeCheck);
            setTimeout(() => {
              this.toastService.showModalResponse(err);
            });
          } else if (res && res.data && (res.data.responseCode === '207')) {
            this.responseCode207();
            this.errorMsg = res.data.errorMessage;
            setTimeout(() => {
              const initialState: any = { centered: false, windowClass: 'align70', keyboard : false,  backdrop : 'static' };
              this.modalService.open(this.showNaviMissmatchErrorView, initialState);
            });
          } else if (res && res.data && ( res.data.responseCode === '208')) {
           this.handleVINDuplicationError(res.data);
          } else {
            this.responseCodeNot207();
          }
        }, err => {
          this.formSubmitted = false;
          this.errorCode400(err);  
        });
      }
    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  /**
   * @method showConfirmationHelp
   * @desc Show confirmation Help Popup
   */

  showConfirmationHelp() {
    const initialState = { size: 'xl', centered: false, windowClass: 'align70' };
    this.modalService.open(this.showConfirmationMethodHelpDesc, initialState);
  }

 
  /**
   * @method continueToVehicleInfoPage
   * @desc Show confirmation Help Popup
   */

  continueToVehicleInfoPage() {
    this.vinSearchCompleted.emit(false);
    if (this.redirectURL) {
      this.router.navigate([this.redirectURL]);
    }
    this.modalService.dismissAll();
  }

/**
 * @method navigateURl
 * @desc checking the condition based on response code
 */
 navigateURl(){
  if (this.redirectURL) {
    this.router.navigate([this.redirectURL]);
  } else {
    this.vinSearchCompleted.emit();
  }
  this.modalService.dismissAll();
 } 

 formDataDeclaration(vehicleInfo ){
  if (!this.mode) {
    vehicleInfo = JSON.parse(localStorage.getItem('vehicleInfo'));
  } else {
    vehicleInfo = JSON.parse(localStorage.getItem('cVehicleInfo'));
  }
  const formData = {
    vinNumber : vehicleInfo.vinNumber,
    registrationDate: (vehicleInfo.registrationDate) ? vehicleInfo.registrationDate : null,
    firstRegistrationDate: (vehicleInfo.firstRegistrationDate) ? vehicleInfo.firstRegistrationDate : null,
    expiryDate: (vehicleInfo.expiryDate) ? vehicleInfo.expiryDate : null,
    naviId: this.controls.naviId.value,
    vehicleType: vehicleInfo.vehicleType,
    skipNaviId: this.controls.skipNaviId.value,
    source : (this.mode) ? 'home' : 'dealer',
    vehicleNumber: vehicleInfo.vehicleNumber
  };
  let formVariables = { formData, vehicleInfo }
  return formVariables;
 }

 responseCode207(){
  if (!this.mode) {
    localStorage.setItem('vehicleInfo' , JSON.stringify(this.data));
    this.store.dispatch(new SaveUsedCarVehicleInfo(this.data));
    this.vinSearchCompleted.emit(false);
  } else {
    localStorage.setItem('cVehicleInfo' , JSON.stringify(this.data));
  }
 }

 responseCodeNot207(){
  if (!this.mode) {
    localStorage.setItem('vehicleInfo' , JSON.stringify(this.data));
  } else {
    localStorage.setItem('cVehicleInfo' , JSON.stringify(this.data));
  }
  this.store.dispatch(new SaveUsedCarVehicleInfo(this.data));
  this.vinSearchCompleted.emit(false);
  if (this.redirectURL) {
    this.router.navigate([this.redirectURL]);
  }
  this.modalService.dismissAll();
 }

 errorCode400(err){
  if (err.status == 400) {
    this.toastService.showModalResponse(err);
  } else {
    this.toastService.showUnexpectedError(err);
  }
 }
  handleVINDuplicationError(data) {
    this.errorMsg = data.errorMessage;
    this.vinSearchCompleted.emit((this.mode) ? true : false);
    setTimeout(() => {
      const initialState: any = {centered: false, backdrop : 'static', keyboard : false,  windowClass: 'alignTop'};
      this.modalService.open(this.showVINDuplicateErrorView, initialState);
    });
  }
  withdrawOrderValue(){
    if(localStorage.getItem('withdrawOrder')){
      return localStorage.getItem('withdrawOrder');
    }
    else{
      return null;
    } 
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-nissan-connect-layout',
  templateUrl: './nissan-connect-layout.component.html',
  styleUrls: ['./nissan-connect-layout.component.scss']
})
export class NissanConnectLayoutComponent{

}

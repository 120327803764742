import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private renderer: Renderer2;
  private loaderWrapper;
  private appRoot;
  private loadingHTML = `<div class="app-loading">
                            <div class="logo"></div>
                            <svg class="spinner" viewBox="25 25 50 50">
                                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
                            </svg>
                            </div>`
  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  showloaderBody() {
    const isExist = document.getElementById('loader_wrap');
    if (isExist) {
      this.removeloaderBody();
    }
    this.loaderWrapper = document.createElement('div');
    this.loaderWrapper.id = 'loader_wrap';
    this.loaderWrapper.innerHTML = this.loadingHTML;
    this.renderer.insertBefore(document.getElementsByTagName('body')[0], this.loaderWrapper, document.getElementsByTagName('body')[0].firstChild);

    this.appRoot = document.getElementsByTagName('app-root')[0] as HTMLElement;
    if(this.appRoot){
    this.appRoot.style.display = 'none';
    }
  }

  removeloaderBody() {
    const element = document.getElementById('loader_wrap');
    if (element && element.parentNode) {
      element.parentNode.removeChild(element);
      if (this.appRoot) {
        this.appRoot.style.display = 'block';
      }
    }
  }
}

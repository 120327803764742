import { Component } from '@angular/core';

@Component({
  selector: 'app-uxexpected-error-response',
  templateUrl: './uxexpected-error-response.component.html',
  styleUrls: ['./uxexpected-error-response.component.scss']
})
export class UxexpectedErrorResponseComponent  {
  public modalRef;
  public currentLang =localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';

  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}

import * as CustomerActions from '../actions/customer-used-car.actions';
import { UsedCarCustomerState } from '../state/customer-used-car.state';


export function customerInfoReducer(state = [], action) {
    switch (action.type) {

        case CustomerActions.SAVE_CUSTOMER_INFO:
            return {...state, ...action.payload };

        case CustomerActions.FETCH_CUSTOMER_INFO:
            return state;

        default:
            return state;
    }
}


export function customerFormDetailsReducer(state = [], action) {
    switch (action.type) {

        case CustomerActions.SAVE_CUSTOMER_FORM_DETAILS:
            return {...state, ...action.payload };

        case CustomerActions.FETCH_CUSTOMER_FORM_DETAILS:
            return state;

        default:
            return state;
    }
}

export function customerStepCountReducer(state = [], action) {
    switch (action.type) {

        case CustomerActions.UPDATE_STEP_COUNT:
            return {...state, ...action.payload };

        case CustomerActions.SAVE_STEP_COUNT:
            return state;

        default:
            return state;
    }
}

export const CustomerUsedCarReducers: UsedCarCustomerState = {
    usedCarcustomerInfo: customerInfoReducer,
    usedCarcustomerFormDetails: customerFormDetailsReducer,
    usedCarstepCount: customerStepCountReducer
};

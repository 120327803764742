import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-method-popup',
  templateUrl: './confirmation-method-popup.component.html',
  styleUrls: ['./confirmation-method-popup.component.scss']
})
export class ConfirmationMethodPopupComponent implements OnInit {

  @Input() mode: any;
  @Input() modal;
  public currentLang: string;

  ngOnInit(): void {
    this.currentLang = (this.mode) ? (localStorage.getItem('cLang') ? localStorage.getItem('cLang')  : 'jp') : (localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp');
  }

  dismiss() {
    this.modal.dismiss();
  }
}

import { Action } from '@ngrx/store';

export const SAVE_CUSTOMER_INFO = '[CUSTOMER] SAVE CUSTOMER INFORMATION';
export const FETCH_CUSTOMER_INFO = '[CUSTOMER] FETCH CUSTOMER INFORMATION';

export const SAVE_CUSTOMER_FORM_DETAILS = '[CUSTOMER] SAVE CUSTOMER FORM DETAILS';
export const FETCH_CUSTOMER_FORM_DETAILS = '[CUSTOMER] FETCH CUSTOMER FORM DETAILS';

export const SAVE_STEP_COUNT =  '[CUSTOMER] SAVE STEP COUNT';
export const UPDATE_STEP_COUNT = '[CUSTOMER] UPDATE STEP COUNT';


export class SaveUsedCarCustomerInformation implements Action {
    readonly type = SAVE_CUSTOMER_INFO;
    // @ts-ignore
    constructor(private payload: {}) {}
}

export class FetchUsedCarCustomerInformation implements Action {
    readonly type = FETCH_CUSTOMER_INFO;
    constructor() {}
}

export class SaveUsedCarCustomerFormDetails implements Action {
    readonly type = SAVE_CUSTOMER_FORM_DETAILS;
    // @ts-ignore
    constructor(private payload: {}) {
    }
}

export class FetchUsedCarCustomerFormDetails implements Action {
    readonly type = FETCH_CUSTOMER_FORM_DETAILS;
    constructor() {}
}

export class FetchUsedCarStepCount implements Action {
    readonly type = SAVE_STEP_COUNT;
    constructor() {}
}

export class UpdateUsedCarStepCount implements Action {
    readonly type = UPDATE_STEP_COUNT;
    // @ts-ignore
    constructor(private payload: {}) {}
}


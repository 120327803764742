<section class="admission_form_wrp relative">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-9 col-md-10 col-sm-12">
				<div class="new_form_box_wrap">
					<form [formGroup]="VehicleProcurementForm" autocomplete="off">

                        <!-- Vehicle Information -->
						<div class="form_box_wrp" formGroupName="vehicleProcurementDetails">
							<h1> {{ 'dealer.evFleet.admission.vehicleInfo.vehicleProcurement' | translate }} </h1>
							<div class="row" >
								<div class="col-xl-6 col-sm-6">
									<div class="floating_label_wrp" [class.error]="VehicleProcurementForm.get('vehicleProcurementDetails.companyName')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.companyName')?.errors">
										<input type="text" class="floating_label_field" id="companyName" placeholder="Dealer Company Name" formControlName="companyName">
										<label class="floating_label required" for="companyName"> {{ 'dealer.evFleet.admission.vehicleInfo.companyName' | translate }} </label>
										<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.companyName')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.companyName')?.errors" class="form-invalid">
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.companyName')?.errors?.required"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameReq' | translate}} </div>
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.companyName')?.errors?.maxlength"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameMaxLength' | translate}} </div>
										</div>
									</div>
								</div>
								<div class="col-xl-6 col-sm-6">
									<div class="floating_label_wrp" [class.error]="VehicleProcurementForm.get('vehicleProcurementDetails.DealershipName')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.DealershipName')?.errors">
										<input type="text" class="floating_label_field" id="DealershipName" placeholder="Dealer Company Name" formControlName="DealershipName">
										<label class="floating_label required" for="DealershipName"> {{ 'dealer.evFleet.admission.vehicleInfo.DealershipName' | translate }} </label>
										<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.DealershipName')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.DealershipName')?.errors" class="form-invalid">
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.DealershipName')?.errors?.required"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameReq' | translate}} </div>
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.DealershipName')?.errors?.maxlength"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameMaxLength' | translate}} </div>
										</div>
									</div>
								</div>
								<div class="col-xl-6 col-sm-6">
									<div class="floating_label_wrp" [class.error]="VehicleProcurementForm.get('vehicleProcurementDetails.CAName')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.CAName')?.errors">
										<input type="text" class="floating_label_field" id="CAName" placeholder="Dealer Company Name" formControlName="CAName">
										<label class="floating_label required" for="CAName"> {{ 'dealer.evFleet.admission.vehicleInfo.CAName' | translate }} </label>
										<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.CAName')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.CAName')?.errors" class="form-invalid">
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.CAName')?.errors?.required"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameReq' | translate}} </div>
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.CAName')?.errors?.maxlength"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameMaxLength' | translate}} </div>
										</div>
									</div>
								</div>
								<div class="col-xl-6 col-sm-6">
									<div class="floating_label_wrp" [class.error]="VehicleProcurementForm.get('vehicleProcurementDetails.CANameKana')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.CANameKana')?.errors">
										<input type="text" class="floating_label_field" id="CANameKana" placeholder="Dealer Company Name" formControlName="CANameKana">
										<label class="floating_label required" for="CANameKana"> {{ 'dealer.evFleet.admission.vehicleInfo.CANameKana' | translate }} </label>
										<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.CANameKana')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.CANameKana')?.errors" class="form-invalid">
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.CANameKana')?.errors?.required"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameReq' | translate}} </div>
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.CANameKana')?.errors?.maxlength"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameMaxLength' | translate}} </div>
										</div>
									</div>
								</div>
								<div class="col-xl-6 col-sm-6">
									<div class="floating_label_wrp" [class.error]="VehicleProcurementForm.get('vehicleProcurementDetails.EmailAddress')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.EmailAddress')?.errors">
										<input type="text" class="floating_label_field" id="EmailAddress" placeholder="Dealer Company Name" formControlName="EmailAddress">
										<label class="floating_label required" for="EmailAddress"> {{ 'dealer.evFleet.admission.vehicleInfo.EmailAddress' | translate }} </label>
										<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.EmailAddress')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.EmailAddress')?.errors" class="form-invalid">
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.EmailAddress')?.errors?.required"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameReq' | translate}} </div>
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.EmailAddress')?.errors?.maxlength"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameMaxLength' | translate}} </div>
										</div>
									</div>
								</div>
								<div class="col-xl-6 col-sm-6">
									<div class="floating_label_wrp" [class.error]="VehicleProcurementForm.get('vehicleProcurementDetails.ConfirmEmailAddress')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.ConfirmEmailAddress')?.errors">
										<input type="text" class="floating_label_field" id="ConfirmEmailAddress" placeholder="Dealer Company Name" formControlName="ConfirmEmailAddress">
										<label class="floating_label required" for="ConfirmEmailAddress"> {{ 'dealer.evFleet.admission.vehicleInfo.ConfirmEmailAddress' | translate }} </label>
										<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.ConfirmEmailAddress')?.touched && VehicleProcurementForm.get('vehicleProcurementDetails.ConfirmEmailAddress')?.errors" class="form-invalid">
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.ConfirmEmailAddress')?.errors?.required"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameReq' | translate}} </div>
											<div *ngIf="VehicleProcurementForm.get('vehicleProcurementDetails.ConfirmEmailAddress')?.errors?.maxlength"> {{'dealer.newCar.vMsg.dealerInformation.dealerCompanyNameMaxLength' | translate}} </div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						
					</form>
				</div>
            </div>
        </div>
    </div>
    <div class="footer_submit_wrp">
		<div class="container-lg">
			<div class="row justify-content-center"><div class="col-xl-9 col-md-10 col-sm-12">		
				</div>
			</div>
		</div>
		
	</div>
</section>
import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent{
  public pageNumber: number;
  public pageTitle: string;
  public showPreviousButton = false;
  public previousPageURL;
  public isENG = localStorage.getItem('nLang') === 'en' ? true : false;

  switchLang(flag) {
    if (localStorage.getItem('nLang') !== flag) {
      localStorage.setItem('nLang', flag);
      this.isENG = flag === 'en' ? true : false;
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  }

}

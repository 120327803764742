import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const {
  SERVER_API_URL,
 } = environment;

/**
 * Name: BaseHttpService
 * params: HttpClient
 * params: basePath
 * desc: All the generic services are hooked on to this service.
 */

export class BaseHttpService {

  constructor(
    private httpClient: HttpClient, private basePath: string ) { }

  /**
   * @method get Fetches data frpm the URL segment passed.
   * @param segment URL Segement
   * @param params Query params if needed
   */

    public get(segment?: string, params?: any, module?: string): Observable<any> {
      return this.httpClient.get(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}${segment ? '/' + segment : ''}`, {params});
    }

  /**
   * @method post POST data with the request doby passed.
   * @param item Request Body
   * @param segment URL Segement
   * @param options HTTP Params if needed
   */

    public post(item, segment?: string, options?: object, module?: string): Observable<any> {
      return this.httpClient.post(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}${segment ? '/' + segment : ''} `, item, (options ? options : {}));
    }

  /**
   * @method put Update data with the passed id in the format http://www.example.com/user/segment
   * @param item Request Body
   * @param segment URL Segement
   * @param options HTTP Params if needed
   */

    public put(item, segment?: string, options?: object, module?: string): Observable<any> {
      return this.httpClient.put(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}${segment ? '/' + segment : ''} `, item, options);
    }

  /**
   * @method update Update data with the passed id in the format http://www.example.com/user/10/update, http://www.example.com/user/10.
   * @param item Request Body
   * @param id Update ID
   * @param segment URL Segement
   */

    public update(item, id: number, segment?: string, module?: string) {
      this.httpClient.put(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}/${id}/${segment ? '/' + segment : ''}`, item);
    }

  /**
   * @method getByID Fetch data by ID.
   * @param id Fetch ID
   */

    public getByID(id: number, module?: string): Observable<any> {
      return this.httpClient.get(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}/${id}`);
    }

  /**
   * @method delete Delete functionality.
   * @param segment URL Segement
   */

    public delete(segment, module?: string): Observable<any> {
      return this.httpClient.delete(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}/${segment}`);
    }

    /**
   * @method delete Delete functionality.
   * @param segment URL Segement
   */

  public deleteWithBody(item, segment, module?: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: item,
    };
    return this.httpClient.delete(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}/${segment}`, options);
  }

  /**
   * @method downloadFile Download functionality with response type as BLOB.
   * @param segment URL Segement
   */

  downloadFile(segment, mimeType, module?: string): Observable<HttpResponse<string>> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', `${mimeType}; charset=utf-8`);
    return this.httpClient.get(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}${segment}`, {
      headers,
      observe: 'response',
      responseType: 'text'
    });
  }

  /**
   * @method getExternal Fetch an external request.
   * @param segment URL
   */

    public getExternal(url): Observable<any> {
      return this.httpClient.get(url);
    }

  /**
   * @method uploadFile Upload file.
   * @param segment URL
   */

  public upload(data, segment?: string, module?: string) {
    return this.httpClient.post<any>(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}${segment ? '/' + segment : ''} `, data, {
      headers: new HttpHeaders({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'multipart/form-data'
      }),
    });
  }

     /**
   * @method downloadFile Download File Functionality having Req body with response type as BLOB.
   * @param segment URL Segement
   */

  downloadFileWithBody(req,segment, mimeType, module?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', `${mimeType}; charset=utf-8`);
    return this.httpClient.post(`${SERVER_API_URL}${module ? '/' + module : ''}/${this.basePath}${segment}`,req, {
      headers,
      observe: 'response',
      responseType: 'text'
    });
  }


  }

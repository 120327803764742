import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CreditCardFormatterDirective } from './credit-card-formatter';
import { FocusnextDirective } from './focusnext.directive';
import { LoaderDirective } from './loader.directive';
import { MinMaxNumberValidator } from './number-validator';
import { PhoneNumberFormatterDirective } from './phone-formatter';
import { ZipCodeFormatterDirective } from './zipcode-formatter';


@NgModule({
  declarations: [FocusnextDirective, CreditCardFormatterDirective, PhoneNumberFormatterDirective, ZipCodeFormatterDirective, LoaderDirective, MinMaxNumberValidator],
  imports: [
    CommonModule
  ],
  exports: [FocusnextDirective, CreditCardFormatterDirective, PhoneNumberFormatterDirective, ZipCodeFormatterDirective, LoaderDirective, MinMaxNumberValidator]
})
export class DirectivesModule { }

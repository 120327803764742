import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, Subscription } from 'rxjs';
import { ToastService } from '../toaster/service/toaster.service';
import { Store } from '@ngrx/store';
import { SaveColorPaletteData } from 'app/store/dealer/actions/dealer.actions';
import { DealerState } from 'app/store/dealer/state/dealer.state';


@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss']
})
export class ColorPaletteComponent implements OnInit {
  @Input() currentLang: string;
  @Input() selectedColor: any;
  @Output() selectedColorObj = new EventEmitter();
  public colors
  public colorSelected: any;
  public showLoader: boolean = false;
  subscription1: Subscription;
  subscription2: Subscription;
  constructor(private toastService: ToastService,
    private httpClient: HttpClient,
    private dealerStore: Store<DealerState>) { }

  ngOnInit(): void {
    if (this.selectedColor && this.selectedColor !== undefined && this.selectedColor !== null && this.selectedColor !== '') {
      this.colorSelected = this.selectedColor;
    } else {
      this.colorSelected = null;
    }

    this.subscription1 = this.dealerStore.select('coloPalletteInfo').subscribe((response) => {
      if (response && response.length == 0) {
        this.fetchColorPalltte();
      } else {
        this.colors = response.colors;
      }
    });

  }

  ngOnChanges() {
    if (this.selectedColor && this.selectedColor !== undefined && this.selectedColor !== null && this.selectedColor !== '') {
      this.colorSelected = this.selectedColor;
    } else {
      this.colorSelected = null;
    }
  }

  getColorPalltte(): Observable<any> {
    return this.httpClient.get(`${environment.COLOR_PALETTE_URL}`)
  }

  fetchColorPalltte() {
    try {
      this.showLoader = true;
      this.subscription2 = this.getColorPalltte().subscribe((response) => {
        this.showLoader = false;
        if (response && response.data && response.data.length != 0) {
          this.colors = response.data.filter((color) => color.isActive === true)
          this.colors.sort((a, b) => a.order - b.order);
          this.dealerStore.dispatch(new SaveColorPaletteData({colors: this.colors}));
        }
      },
        (error) => {
          console.log(error)
          this.showLoader = false
        });

    } catch (error) {
      this.toastService.showUnexpectedError(error);
    }
  }

  colorSelection(selectedColor) {
    this.colorSelected = selectedColor;
    this.selectedColorObj.emit(this.colorSelected);
  }

  ngOnDestroy() {
    if(this.subscription1){
      this.subscription1.unsubscribe();
    }
    if(this.subscription2){
      this.subscription2.unsubscribe();
    }
  }
}

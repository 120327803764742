import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Resolve, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { LoaderService } from '@shared/components/loader/service/loader.service';
import { UtilService } from '@shared/utils/utils.service';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { SecuredService } from '../generic/secured.service';
import { eFlag } from '@shared/constants/dealer.constants';

@Injectable()
export class DealerResolver implements Resolve<Observable<string>> {
  private readonly destroy$: Subject<void>= new Subject<void>();
  constructor(private router: Router, private securedService: SecuredService,
    private utilService: UtilService, private loaderService: LoaderService,
    private sanitizer: DomSanitizer) { }

  resolve() {
    try {
      this.loaderService.showloaderBody();
      if (!this.checkIfDealerLoggedIn()) {
        return this.checkEncodedString();
      } else {
        return this.fetchAdmission();
      }
    } catch (error) {
      console.log(error);
      this.router.navigate(['error/500'], { queryParams: { redirectURL: 'dealer' } });
      return of(true);
    }
  }


  checkEncodedString() {
    const token = this.utilService.tokenFromUrlParams('token');
    this.saveRenewSubscription(token);
    this.storeInitialEndpoint(token)
    if (token && token != '') {
      localStorage.setItem('d_token', token);
        return this.fetchAdmission();
    } else {
      const dToken = localStorage.getItem('d_token');
      if (dToken) {
        const encodedString = btoa(`${dToken}`);
        let URL;
        if(eFlag.value == true){
          URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_LOGOUT_URL}?${eFlag.key}=${eFlag.value}&${encodedString}`);
        }else{
          URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_LOGOUT_URL}?token=${dToken}`);
        }
        window.location.href = URL;
      } else {
        const URL = this.sanitizer.sanitize(SecurityContext.URL, `${environment.GD_URL}`);
        window.location.href = URL;
      }
      return throwError(null);
    }
  }

  checkIfDealerLoggedIn() {
    if (localStorage.getItem('d_token')) {
      return true;
    }
    return false;
  }

  fetchAdmission(): Observable<any> {
    const currentLang = localStorage.getItem('dLang') ? localStorage.getItem('dLang') : 'jp';
    const admission$ = this.securedService.get(`${currentLang}/admission`, {}, 'admission');
    const ncReminderSettings$ = this.securedService.get(`${currentLang}/settings?settings_function=NC_EXPIRY_REMINDER`, {}, 'admission');
    const naviIdReminderSettings$ = this.securedService.get(`${currentLang}/settings?settings_function=NAVI_ID_REMINDER`, {}, 'admission');
    const companyAndDealerMaster$ = this.securedService.get(`${currentLang}/fleetAdmission/companyAndDealerMaster`, {}, 'admission');
    const packagePlanForSignUp$ = this.securedService.get(`${currentLang}/fleetAdmission/bizPackagePlan`, {}, 'admission');

    const joinedApis = [ncReminderSettings$, naviIdReminderSettings$, companyAndDealerMaster$]

    return admission$.pipe(
      takeUntil(this.destroy$),switchMap(result => {
        const dealerInfoObj = result?.data.dealerDetails;
        if(dealerInfoObj.dealerType !== 'LC'){
          joinedApis.push(packagePlanForSignUp$)
        }
        

        return forkJoin([of(result),...joinedApis]).pipe(catchError((error) => {
          if (error.status !== 403) {
            this.router.navigate(['error/500'], { queryParams: { redirectURL: 'dealer' } });
            return of(null);
          }
        }));
      }),
    ).pipe(catchError((error) => {
      if (error.status !== 403) {
        this.router.navigate(['error/500'], { queryParams: { redirectURL: 'dealer' } });
        return of(null);
      }
    }));
  }

  unsubscribe(){
    this.destroy$.next();
    this.destroy$.complete();
  }

  storeInitialEndpoint(token){
    const initialUrl = window.location.href;
    if(initialUrl.endsWith('#/dealer/ev-fleet')){
      sessionStorage.setItem('endpoint',initialUrl);
    }
    if(!initialUrl.endsWith('#/dealer/ev-fleet')&& (!token || token==='')){
      sessionStorage.removeItem('endpoint');
    }
  }

  saveRenewSubscription(token) {
    const keyValue=this.utilService.getUrlParameter(window.location.href, 'key');
    const expiryValue=this.utilService.getUrlParameter(window.location.href, 'expiry');
    let renewSubscription= {
      key:keyValue,
      expiry:expiryValue
    };
    if((keyValue && keyValue !=='')|| (expiryValue && expiryValue !=='')){
      sessionStorage.setItem('renewSubscription',JSON.stringify(renewSubscription));
    }

    if((keyValue ==='' || !keyValue || expiryValue === '' || !expiryValue) && (!token || token==='')){
      sessionStorage.removeItem('renewSubscription');
    }
  }
}

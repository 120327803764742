import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[creditCard]'
})
export class CreditCardFormatterDirective implements OnInit {

  constructor(private el: ElementRef, private render: Renderer2) { }

  ngOnInit() {
      const output = this.formatCreditCard(this.el.nativeElement.value);
      if (output) {
          this.render.setProperty(this.el.nativeElement, 'value', output);
      }
  }

  @HostListener('blur', ['$event']) onInputChange() {
    try {
      const value = this.el.nativeElement.value.replace(/[^0-9]/g, '') || '';
      if (!isNaN(value) && value) {
          const output = this.formatCreditCard(value);
          this.render.setProperty(this.el.nativeElement, 'value', output);
      }
    } catch (e) {
      console.log(e);
    }
  }

  @HostListener('click', ['$event']) onInputMouseChange() {
    try {
      const cursorPosition = this.el.nativeElement.selectionStart;
      const subString = this.el.nativeElement.value.substring(0, this.el.nativeElement.selectionStart);
      const value = parseInt((this.el.nativeElement.value).replace(/\D/g, ''), 10);
      const commaCount = subString.split(',').length - 1;
      if (!isNaN(value) && value) {
          this.render.setProperty(this.el.nativeElement, 'value', value.toString());
      }
      this.el.nativeElement.setSelectionRange(cursorPosition - commaCount, cursorPosition - commaCount, 'none');
    } catch (e) {
      console.log(e);
    }
  }

  formatCreditCard(value) {
    try {
      const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
      const matches = v.match(/\d{4,16}/g);
      const match = matches && matches[0] || '';
      const parts = [];
      for ( let i = 0, len = match.length; i < len; i += 4) {
          parts.push(match.substring(i, i + 4));
      }
      if (parts.length) {
          return parts.join('-');
      } else {
          return value;
      }
    } catch (e) {
      console.log(e);
    }
  }
}
